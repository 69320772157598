import { cn } from '@bem-react/classname';
import { Header, Socials } from 'components';
import React, { FC } from 'react';

import './PageLayout.scss';

const CnPageLayout = cn('pageLayout');

interface IPageLayout {
    children: any;
}

export const PageLayout: FC<IPageLayout> = ({ children }) => {
    return (
        <div>
            <div className={CnPageLayout('background')}></div>
            <Header />

            {children}

            <Socials />
        </div>
    );
};
