import axios, { AxiosResponse } from 'axios';
import { watchdogUrl } from 'consts';
import { Networks, TransactionStatus } from 'types';

interface FetchTransactionStatusResponse {
    status: TransactionStatus;
    relayTxHex?: string;
    timeWait?: number;
}

const networkTypeFromNetworks = {
    [Networks.Aptos]: 'aptos',
    [Networks.Arbitrum]: 'evm',
    [Networks.Bsc]: 'evm',
    [Networks.Optimism]: 'evm',
    [Networks.Polygon]: 'evm',
    [Networks.ZkSync]: 'evm',
    [Networks.ZkPolygon]: 'evm',
    [Networks.BobaEth]: 'evm',
    [Networks.BobaBnb]: 'evm',
    [Networks.Sui]: 'sui',
};

export const fechTransactionStatus = async (
    network: Networks,
    hash: string,
    fromChainId: string | number,
    toChaindId: string | number,
): Promise<FetchTransactionStatusResponse> => {
    return await axios
        .get<never, AxiosResponse<FetchTransactionStatusResponse>>(
            `${watchdogUrl}/public/deposit/status`,
            {
                params: {
                    fromNetworkType: networkTypeFromNetworks[network],
                    transactionHex: hash,
                    fromChainId,
                    toChaindId,
                },
            },
        )
        .then((res) => res.data);
};

interface FetchTransactionQueue {
    status: string;
    timeWait: number;
}

export const fetchTransactionQueue = async (
    network: Networks,
    fromChainId: string | number,
    toChainId: string | number,
) => {
    return await axios
        .get<never, AxiosResponse<FetchTransactionQueue>>(
            `${watchdogUrl}/public/deposit/queueStatus`,
            {
                params: {
                    fromNetworkType: networkTypeFromNetworks[network],
                    fromChainId,
                    toChainId,
                },
            },
        )
        .then((res) => res.data);
};
