import axios, { AxiosResponse } from 'axios';
import { watchdogUrl } from 'consts';
import { NetworkType } from 'types';

type IFetchLiquidityResponse = any;

export const fetchLiquidity = async (
    chainId: number,
    networkType: NetworkType,
) => {
    return await axios
        .get<never, AxiosResponse<IFetchLiquidityResponse>>(
            `${watchdogUrl}/public/pool/balance`,
            {
                params: {
                    chainId,
                    networkType,
                },
            },
        )
        .then((res) => res.data);
};
