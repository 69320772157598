import { Icons } from 'assets';
import { AptosTokens, Tokens } from 'types';

export const tokenList = [
    {
        name: 'usdc',
        icon: Icons.Usdc,
        value: Tokens.USDC,
    },
    {
        name: 'usdt',
        icon: Icons.Usdt,
        value: Tokens.USDT,
    },
];

export const tokenMap = {
    [Tokens.USDC]: {
        name: 'usdc',
        icon: Icons.Usdc,
        value: Tokens.USDC,
    },
    [Tokens.USDT]: {
        name: 'usdt',
        icon: Icons.Usdt,
        value: Tokens.USDT,
    },
    [AptosTokens.USDC]: {
        name: 'USDC',
        icon: Icons.Usdc,
        value: Tokens.USDC,
    },
    [AptosTokens.USDT]: {
        name: 'USDT',
        icon: Icons.Usdt,
        value: Tokens.USDT,
    },
};
