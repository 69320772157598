import { cn } from '@bem-react/classname';
import { FC, useCallback, useMemo } from 'react';
import { Button } from 'components';
import { fromDecimals } from 'utils';
import { stakeConfig } from 'config';
import moment from 'moment';

import './StakingInfo.scss';
import { useAptosWalletContext } from 'hooks';

const CnStakingInfo = cn('stakingInfo');

export const StakingInfo: FC<{
    amount?: number | null;
    end?: number | null;
    veMover?: number | null;
}> = ({ amount, end, veMover }) => {
    const { withdraw } = useAptosWalletContext();

    const withdrawClickCallback = useCallback(() => {
        if (withdraw) {
            withdraw();
        }
    }, [withdraw]);

    const withdrawButton = useMemo(() => {
        const isButtonDisabled = end
            ? moment().isBefore(moment.unix(end))
            : true;

        return (
            <Button
                onClick={withdrawClickCallback}
                disabled={isButtonDisabled}
                size="s"
                view="white"
            >
                Withdraw
            </Button>
        );
    }, [end, withdrawClickCallback]);

    return (
        <div className={CnStakingInfo()}>
            {amount && end ? (
                <>
                    <div className={CnStakingInfo('item', { flex: true })}>
                        <div className={CnStakingInfo('title')}>
                            You are staking now
                        </div>

                        {withdrawButton}
                    </div>
                    <div className={CnStakingInfo('item')}>
                        <div className={CnStakingInfo('info')}>
                            <div className={CnStakingInfo('info-label')}>
                                Locked Mover
                            </div>
                            <div className={CnStakingInfo('info-value')}>
                                {fromDecimals(amount, stakeConfig.decimals)}
                            </div>
                        </div>
                        <div className={CnStakingInfo('info')}>
                            <div className={CnStakingInfo('info-label')}>
                                My veMover
                            </div>
                            <div className={CnStakingInfo('info-value')}>
                                {fromDecimals(
                                    veMover ?? 0,
                                    stakeConfig.decimals,
                                ).toFixed(2)}
                            </div>
                        </div>
                        <div className={CnStakingInfo('info')}>
                            <div className={CnStakingInfo('info-label')}>
                                End of period
                            </div>
                            <div className={CnStakingInfo('info-value')}>
                                {moment.unix(end).format('DD MMMM YYYY')}
                            </div>
                        </div>
                    </div>
                </>
            ) : null}
            <div className={CnStakingInfo('item')}>
                <div className={CnStakingInfo('content')}>
                    <div className={CnStakingInfo('title')}>How it works?</div>

                    <div className={CnStakingInfo('text')}>
                        Stake your $MOVER tokens to receive veMOVER, which
                        unlocks a plethora of opportunities:
                    </div>

                    <div className={CnStakingInfo('list')}>
                        <div className={CnStakingInfo('list-item')}>
                            <div>1.</div> Pay zero fees for your bridging txs.
                        </div>

                        <div className={CnStakingInfo('list-item')}>
                            <div>2.</div> Earn a percentage of the bridging fees
                            generated by all the transactions in Mover.
                        </div>

                        <div className={CnStakingInfo('list-item')}>
                            <div>3.</div> Earn more by providing liquidity to
                            Mover pools.
                        </div>

                        <div className={CnStakingInfo('list-item')}>
                            <div>4.</div> Create and vote for governance
                            proposals in Mover DAO.
                        </div>
                    </div>
                </div>

                <div
                    onClick={() =>
                        window.open(
                            'https://www.bitget.com/spot/MOVERUSDT?type=spot',
                            '_blank',
                        )
                    }
                    className={CnStakingInfo('action')}
                >
                    <div className={CnStakingInfo('action-text')}>
                        Buy $MOVER
                    </div>{' '}
                    <svg
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M9.33331 16H22.6666"
                            stroke="url(#paint0_linear_5365_8747)"
                            stroke-width="1.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <path
                            d="M17.3333 10.6667L22.6666 16.0001L17.3333 21.3334"
                            stroke="url(#paint1_linear_5365_8747)"
                            stroke-width="1.75"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                        <defs>
                            <linearGradient
                                id="paint0_linear_5365_8747"
                                x1="9.33331"
                                y1="16.5"
                                x2="22.6666"
                                y2="16.5"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#59FFEB" />
                                <stop offset="1" stop-color="#14C7FF" />
                            </linearGradient>
                            <linearGradient
                                id="paint1_linear_5365_8747"
                                x1="17.3333"
                                y1="16.0001"
                                x2="22.6666"
                                y2="16.0001"
                                gradientUnits="userSpaceOnUse"
                            >
                                <stop stop-color="#59FFEB" />
                                <stop offset="1" stop-color="#14C7FF" />
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
            </div>
        </div>
    );
};
