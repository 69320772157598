import { AptosClient } from 'aptos';
import { config } from 'config';
import { chainTypeFromNetwork } from 'consts';
import { useEffect, useState } from 'react';
import { Networks } from 'types';

export const useAptosReduceFee = (
    aptosClient: AptosClient,
    to: Networks,
    address?: string,
) => {
    const [percent, setPercent] = useState<any>(1);
    const bridge = (config.aptos as any).bridge;

    useEffect(() => {
        if (address && to !== Networks.Aptos) {
            aptosClient
                .view({
                    function: `${bridge}::bridge::fee_perc`,
                    arguments: [address],
                    type_arguments: [
                        `${bridge}::chains::${chainTypeFromNetwork[to]}`,
                    ],
                })
                .then((response) => {
                    const [percent] = response;

                    setPercent(Number(percent) / 10 ** 6);
                });
        }
    }, [address, to]);

    return percent;
};
