import { cn } from '@bem-react/classname';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { fetchTransactionsHistory } from 'store';
import {
    TransactionHistory as ITransactionHistory,
    FetchStatus,
    AptosChains,
    EvmChains,
    Tokens,
    TransactionStatus,
    NetworkType,
} from 'types';
import { formatAddress, sliceDecimals } from 'utils';
import {
    iconFromNetwork,
    networkFromChain,
    iconByToken,
    linkFromNetwork,
} from 'consts';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'hooks';
import { Icons } from 'assets';

import './TransactionHistory.scss';

const CnTransactionHistory = cn('transactionHistory');

export const TransactionHistory: React.FC<{
    address: string;
    networkType: NetworkType;
}> = memo(({ address, networkType }) => {
    const dispatch = useAppDispatch();
    const { aptos, evm, sui, fetchStatus } = useAppSelector(
        (store) => store.history,
    );
    const [isShowAll, setIsShowAll] = useState(false);

    const isShowAllChangeCallback = useCallback(() => {
        setIsShowAll((prev) => !prev);
    }, []);

    const history = useMemo(() => {
        if (networkType === NetworkType.Aptos) {
            return aptos;
        } else if (networkType === NetworkType.Evm) {
            return evm;
        } else {
            return sui;
        }
    }, [networkType, aptos, evm, sui]);

    useEffect(() => {
        if (address && networkType) {
            if (networkType === NetworkType.Aptos) {
                dispatch(
                    fetchTransactionsHistory({
                        address,
                        key: NetworkType.Aptos,
                    }),
                );
            } else if (networkType === NetworkType.Evm) {
                dispatch(
                    fetchTransactionsHistory({
                        address,
                        key: NetworkType.Evm,
                    }),
                );
            } else {
                dispatch(
                    fetchTransactionsHistory({
                        address,
                        key: NetworkType.Sui,
                    }),
                );
            }
        }
    }, [address, networkType, dispatch]);

    const historyContent = useMemo(() => {
        if (fetchStatus === FetchStatus.FETCHING) return null;

        if (!history) return null;

        return history
            .slice(0, isShowAll ? history.length : 2)
            .map((history) => (
                <TransactionHistoryItem key={history.eventNonce} {...history} />
            ));
    }, [history, fetchStatus, isShowAll]);

    const angleContent = useMemo(() => {
        if (!history) return null;

        if (history.length <= 2) return null;

        if (!isShowAll) {
            return (
                <div
                    onClick={isShowAllChangeCallback}
                    className={CnTransactionHistory('angle')}
                >
                    Show all history <Icons.AngleDown />
                </div>
            );
        }

        return (
            <div
                onClick={isShowAllChangeCallback}
                className={CnTransactionHistory('angle', { rotate: true })}
            >
                Hide <Icons.AngleDown />
            </div>
        );
    }, [history, isShowAll, isShowAllChangeCallback]);

    return (
        <div className={CnTransactionHistory()}>
            {historyContent}

            {angleContent}
        </div>
    );
});

const CnTransactionHistoryItem = cn('transactionHistoryItem');

const TransactionHistoryItem: React.FC<ITransactionHistory> = memo(
    ({
        eventAmount,
        finalAmount,
        from,
        to,
        txHex,
        created,
        tokenEnum,
        fromChainId,
        toChainId,
        status,
        relayTxHex,
    }) => {
        const dateContent = useMemo(
            () => moment(created).format('DD MMM YYYY'),
            [created],
        );

        const addressContent = useMemo(() => formatAddress(from), [from]);

        const token = useMemo(
            () => tokenEnum.toLowerCase() as Tokens,
            [tokenEnum],
        );

        const fromNetwork = useMemo(
            () => networkFromChain[fromChainId as EvmChains | AptosChains],
            [fromChainId],
        );

        const toNetwork = useMemo(
            () => networkFromChain[toChainId as EvmChains | AptosChains],
            [toChainId],
        );

        const fromLink = useMemo(() => {
            if (!fromNetwork || !txHex) return null;

            return linkFromNetwork[fromNetwork](txHex);
        }, [txHex, fromNetwork]);

        const toLink = useMemo(() => {
            if (!toNetwork || !relayTxHex) return null;

            return linkFromNetwork[toNetwork](relayTxHex);
        }, [relayTxHex, toNetwork]);

        const openLink = useCallback((link: string | null) => {
            if (link) {
                window.open(link, '_blank');
            }
        }, []);

        const fromAmount = useMemo(() => {
            return Number(eventAmount).toFixed(2);
        }, [eventAmount]);

        const toAmount = useMemo(() => {
            return sliceDecimals(finalAmount, 2);
        }, [finalAmount]);

        const fromNetworkIcon = useMemo(
            () => iconFromNetwork[fromNetwork],
            [fromNetwork],
        );

        const fromContent = useMemo(() => {
            return (
                <div
                    onClick={() => openLink(fromLink)}
                    className={CnTransactionHistoryItem('token', {
                        hover: Boolean(fromLink),
                    })}
                >
                    <div className={CnTransactionHistoryItem('icon')}>
                        {iconByToken[token]}
                        <div className={CnTransactionHistoryItem('network')}>
                            {fromNetworkIcon}
                        </div>
                    </div>
                    <div className={CnTransactionHistoryItem('amount')}>
                        {fromAmount} {token.toUpperCase()}
                    </div>
                </div>
            );
        }, [token, fromAmount, fromNetworkIcon, fromLink, openLink]);

        const toNetworkIcon = useMemo(
            () => iconFromNetwork[toNetwork],
            [toNetwork],
        );

        const toContent = useMemo(() => {
            return (
                <div
                    onClick={() => openLink(toLink)}
                    className={CnTransactionHistoryItem('token', {
                        hover: Boolean(toLink),
                    })}
                >
                    <div className={CnTransactionHistoryItem('icon')}>
                        {iconByToken[token]}
                        <div className={CnTransactionHistoryItem('network')}>
                            {toNetworkIcon}
                        </div>
                    </div>
                    <div className={CnTransactionHistoryItem('amount')}>
                        {toAmount} {token.toUpperCase()}
                    </div>
                </div>
            );
        }, [token, toAmount, toNetworkIcon, toLink, openLink]);

        const statusContent = useMemo(() => {
            if (status === TransactionStatus.COMPLETE) {
                return <Icons.ArrowRight />;
            }

            if (status === TransactionStatus.FAIL) {
                return <Icons.CloseBlack />;
            }

            return <Icons.Loader />;
        }, [status]);

        return (
            <div className={CnTransactionHistoryItem()}>
                <div className={CnTransactionHistoryItem('header')}>
                    {fromContent}

                    <div
                        className={CnTransactionHistoryItem('status', {
                            status,
                        })}
                    >
                        {statusContent}
                    </div>

                    {toContent}
                </div>
                <div className={CnTransactionHistoryItem('description')}>
                    <div className={CnTransactionHistoryItem('address')}>
                        {addressContent}
                    </div>

                    <div className={CnTransactionHistoryItem('date')}>
                        {dateContent}
                    </div>
                </div>
            </div>
        );
    },
);
