import { combineReducers } from '@reduxjs/toolkit';
import { userReducer } from './user/user.slice';
import { historyReducer } from './history/history.slice';
import { transferFormReducer } from './transferForm/transferForm.slice';
import { transferLiquidityReducer } from './transferLiquidity/transferLiquidity.slice';
import { transferStatusReducer } from './transferStatus/transferStatus.slice';

export const rootReducer = combineReducers({
    user: userReducer,
    history: historyReducer,
    transferForm: transferFormReducer,
    transferLiquidity: transferLiquidityReducer,
    transferStatus: transferStatusReducer,
});
