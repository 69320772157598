import { cn } from '@bem-react/classname';
import { FC } from 'react';

import './VeMoverBalance.scss';
import { useAptosWalletContext } from 'hooks';
import { useVeMoverBalance } from 'hooks/useVeMoverBalance';
import { fromDecimals } from 'utils';
import { stakeConfig } from 'config';

const CnVeMoverBalance = cn('veMoverBalance');

export const VeMoverBalance: FC = () => {
    const { address, veMoverBalance } = useAptosWalletContext();

    if (!veMoverBalance) return null;

    return (
        <div className={CnVeMoverBalance()}>
            <div className={CnVeMoverBalance('balance')}>
                {fromDecimals(veMoverBalance, stakeConfig.decimals).toFixed(2)}
            </div>
            <div className={CnVeMoverBalance('logo')}>
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.2967 4C13.2417 4 13.1868 4 13.1209 4C13.0879 4 13.0659 4 13.033 4H8.67032C8.57142 4 8.48351 4.01075 8.3956 4.03224C7.86812 4.15044 7.47252 4.62326 7.47252 5.1713V5.25727C7.47252 5.7086 7.73626 6.10619 8.12087 6.29962C8.28571 6.38559 8.47252 6.42857 8.67032 6.42857H13.3077C13.967 6.42857 14.5055 6.95512 14.5055 7.59987V7.68584C14.5055 8.33059 13.967 8.85714 13.3077 8.85714H2.1978C1.53846 8.85714 1 9.38369 1 10.0284V10.1144C1 10.7592 1.53846 11.2857 2.1978 11.2857H8.24175C8.90109 11.2857 9.43955 11.8123 9.43955 12.457V12.543C9.43955 13.1877 8.90109 13.7143 8.24175 13.7143H5C4.34066 13.7143 3.80219 14.2408 3.80219 14.8856V14.9716C3.80219 15.6163 4.34066 16.1429 5 16.1429H13.3077C13.967 16.1429 14.5055 16.6694 14.5055 17.3142V17.4001C14.5055 18.0449 13.967 18.5714 13.3077 18.5714H7.62637C6.96703 18.5714 6.42856 19.098 6.42856 19.7427V19.8287C6.42856 20.4734 6.96703 21 7.62637 21H13.7472C13.8461 21 13.9341 20.9893 14.022 20.9678C18.4945 20.6024 22 16.9488 22 12.4785C22.011 7.80405 18.1099 4 13.2967 4ZM10.8681 12.543C10.8681 11.8875 11.4176 11.3502 12.0879 11.3502C12.7582 11.3502 13.3077 11.8875 13.3077 12.543C13.3077 13.1985 12.7582 13.7358 12.0879 13.7358C11.4066 13.725 10.8681 13.1985 10.8681 12.543Z"
                        fill="white"
                    />
                </svg>
                ve
            </div>
        </div>
    );
};
