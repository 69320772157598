export const sliceDecimals = (str: string | number, count: number) => {
    let result = String(str);
    const splitted = result.split('.');
    const isDecimalsExist = splitted.length > 1;

    if (!isDecimalsExist) return result;

    let [base, decimals = ''] = splitted;

    const decimalsCount = decimals.length;

    if (decimalsCount <= count) return result;

    if (count === 0) {
        return `${base}`;
    }

    decimals = decimals.slice(0, count);

    return `${base}.${decimals}`;
};
