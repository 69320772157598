import React, { memo, useMemo } from 'react';

import {
    AptosWalletAdapter,
    MartianWalletAdapter,
    PontemWalletAdapter,
    RiseWalletAdapter,
    WalletProvider,
} from '@mov3r/aptos-wallet-adapter';
import { AptosWalletProviderContext } from './AptosWalletContext';

interface IAptosWalletProviderProps {
    children: React.ReactNode;
}

export const AptosWalletProvider: React.FC<IAptosWalletProviderProps> = memo(
    ({ children }) => {
        const wallets = useMemo(
            () => [
                new MartianWalletAdapter(),
                new PontemWalletAdapter(),
                new RiseWalletAdapter(),
                new AptosWalletAdapter(),
            ],
            [],
        );

        return (
            <WalletProvider
                wallets={wallets}
                autoConnect
                onError={(error: Error) => {
                    let text = 'Unknow error';
                    if (error.name === 'WalletNotReadyError') {
                        text = 'Wallet not ready';
                    }
                }}
            >
                <AptosWalletProviderContext>
                    {children}
                </AptosWalletProviderContext>
            </WalletProvider>
        );
    },
);
