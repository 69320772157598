import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { cn } from '@bem-react/classname';
import {
    useEvmWalletContext,
    useAptosWalletContext,
    useSuiWalletContext,
} from 'hooks';
import { Icons } from 'assets';
import { useNavigate } from 'react-router';
import { Button, TransactionHistory } from 'components';
import toast from 'react-hot-toast';
import { iconByAtposWalletName, iconByEvmWalletName } from 'consts';
import { NetworkType, WalletTypes } from 'types';

import './Wallet.scss';

const CnWallet = cn('wallet');

var walletDropdownTimeout: null | NodeJS.Timer = null;

export const Wallet: React.FC = () => {
    const navigate = useNavigate();
    const evmWallet = useEvmWalletContext();
    const aptosWallet = useAptosWalletContext();
    const suiWallet = useSuiWalletContext();

    const [isDropdownShow, setIsDropdownShow] = useState(false);
    const dropdownRef = useRef<any>(null);
    const baseRef = useRef<any>(null);

    const isDropdownShowChangeCallback = useCallback(() => {
        setIsDropdownShow((prev) => {
            if (walletDropdownTimeout) {
                clearTimeout(walletDropdownTimeout);
            }

            return !prev;
        });
    }, []);

    const hideDropdownCallback = useCallback(() => {
        setIsDropdownShow(false);
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                baseRef.current &&
                !baseRef.current.contains(event.target)
            ) {
                hideDropdownCallback();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, baseRef]);

    const onMouseEnter = useCallback(() => {
        if (walletDropdownTimeout) {
            clearTimeout(walletDropdownTimeout);
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        walletDropdownTimeout = setTimeout(() => {
            hideDropdownCallback();
        }, 1000);
    }, [hideDropdownCallback]);

    const connectWalletClickCallback = useCallback(() => {
        navigate('?modal=connectWallet', {
            state: {
                walletType: WalletTypes.All,
            },
        });
    }, [navigate]);

    const walletButton = useMemo(() => {
        return (
            <div
                onClick={connectWalletClickCallback}
                className={CnWallet('button')}
            >
                Connect wallet
            </div>
        );
    }, [connectWalletClickCallback]);

    const walletIconContent = useMemo(() => {
        const evmIcon =
            evmWallet.address && evmWallet.connector ? (
                <div className={CnWallet('icon-item')}>
                    {iconByEvmWalletName[evmWallet.connector?.name]}
                </div>
            ) : null;

        const aptosIcon =
            aptosWallet.adapter && aptosWallet.address ? (
                <div className={CnWallet('icon-item')}>
                    {iconByAtposWalletName[aptosWallet.adapter.name]}
                </div>
            ) : null;

        const suiIcon =
            suiWallet.adapter && suiWallet.address ? (
                <div className={CnWallet('icon-item')}>
                    <img
                        src={suiWallet.adapter.icon}
                        alt={suiWallet.adapter.name}
                    />
                </div>
            ) : null;

        return (
            <div
                className={CnWallet('icon', {
                    full: !!evmWallet.address && !!aptosWallet.address,
                })}
            >
                {suiIcon}
                {aptosIcon}
                {evmIcon}
            </div>
        );
    }, [
        aptosWallet.adapter,
        evmWallet.address,
        aptosWallet.address,
        suiWallet.address,
        suiWallet.adapter,
        evmWallet.connector,
    ]);

    const addWalletContent = useMemo(() => {
        return (
            <div className={CnWallet('addWallet')}>
                <Button onClick={connectWalletClickCallback} size="s">
                    Add wallet <Icons.Plus />
                </Button>
            </div>
        );
    }, [connectWalletClickCallback]);

    const dropdownContent = useMemo(() => {
        return (
            <div
                ref={dropdownRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={CnWallet('dropdown', { show: isDropdownShow })}
            >
                {aptosWallet.address && <AptosDropdownItem />}

                {aptosWallet.address && (
                    <TransactionHistory
                        address={aptosWallet.address as string}
                        networkType={NetworkType.Aptos}
                    />
                )}

                {evmWallet.address && <EvmDropdownItem />}

                {evmWallet.address && (
                    <TransactionHistory
                        address={evmWallet.address as string}
                        networkType={NetworkType.Evm}
                    />
                )}

                {suiWallet.address && <SuiDropdownItem />}

                {suiWallet.address && (
                    <TransactionHistory
                        address={suiWallet.address as string}
                        networkType={NetworkType.Sui}
                    />
                )}

                {!aptosWallet.address ||
                !evmWallet.address ||
                !suiWallet.address
                    ? addWalletContent
                    : null}
            </div>
        );
    }, [
        aptosWallet.address,
        evmWallet.address,
        suiWallet.address,
        addWalletContent,
        isDropdownShow,
        onMouseEnter,
        onMouseLeave,
    ]);

    const isDesktop = useMemo(() => window.screen.width > 900, []);

    const walletContent = useMemo(() => {
        if (!evmWallet.address && !aptosWallet.address && !suiWallet.address) {
            return walletButton;
        }

        return (
            <div ref={baseRef} className={CnWallet('wrapper')}>
                <div
                    onClick={isDropdownShowChangeCallback}
                    className={CnWallet()}
                >
                    <div className={CnWallet('left')}>
                        {walletIconContent}
                        <div className={CnWallet('text')}>
                            {isDesktop ? 'Connected wallets' : 'Wallets'}
                        </div>
                    </div>

                    <Icons.Chevron
                        className={CnWallet('angle', { show: isDropdownShow })}
                    />
                </div>
                {dropdownContent}
            </div>
        );
    }, [
        evmWallet.address,
        aptosWallet.address,
        suiWallet.address,
        walletButton,
        walletIconContent,
        dropdownContent,
        isDropdownShowChangeCallback,
        isDropdownShow,
        isDesktop,
    ]);

    return walletContent;
};

const CnDropdownItem = cn('dropdownItem');

const AptosDropdownItem: React.FC = () => {
    const { address, formattedAddress, adapter, disconnect } =
        useAptosWalletContext();

    const disconnectClickCallback = useCallback(() => {
        if (disconnect) {
            disconnect();
        }
    }, [disconnect]);

    const copyClickCallback = useCallback(() => {
        if (address) {
            try {
                navigator.clipboard.writeText(address);

                toast.success('Copied!');
            } catch {}
        }
    }, [address]);

    return (
        <div className={CnDropdownItem()}>
            <div className={CnDropdownItem('row')}>
                <div className={CnDropdownItem('icon')}>
                    {adapter?.name ? iconByAtposWalletName[adapter.name] : null}
                </div>
                <span className={CnDropdownItem('address')}>
                    {formattedAddress}
                </span>
            </div>

            <div
                onClick={copyClickCallback}
                className={CnDropdownItem('action')}
            >
                <Icons.Copy />
            </div>
            <div
                onClick={disconnectClickCallback}
                className={CnDropdownItem('action')}
            >
                <Icons.TurnOff />
            </div>
        </div>
    );
};

const EvmDropdownItem: React.FC = () => {
    const { address, formattedAddress, disconnect, connector } =
        useEvmWalletContext();

    const disconnectClickCallback = useCallback(() => {
        if (disconnect) {
            disconnect();
        }
    }, [disconnect]);

    const copyClickCallback = useCallback(() => {
        if (address) {
            navigator.clipboard.writeText(address);

            toast.success('Copied!');
        }
    }, [address]);

    return (
        <div className={CnDropdownItem()}>
            <div className={CnDropdownItem('row')}>
                <div className={CnDropdownItem('icon')}>
                    {connector ? (
                        iconByEvmWalletName[connector?.name]
                    ) : (
                        <Icons.Metamask />
                    )}
                </div>
                <span className={CnDropdownItem('address')}>
                    {formattedAddress}
                </span>
            </div>

            <div
                onClick={copyClickCallback}
                className={CnDropdownItem('action')}
            >
                <Icons.Copy />
            </div>
            <div
                onClick={disconnectClickCallback}
                className={CnDropdownItem('action')}
            >
                <Icons.TurnOff />
            </div>
        </div>
    );
};

const SuiDropdownItem: React.FC = () => {
    const { address, formattedAddress, adapter, disconnect } =
        useSuiWalletContext();

    const disconnectClickCallback = useCallback(() => {
        if (disconnect) {
            disconnect();
        }
    }, [disconnect]);

    const copyClickCallback = useCallback(() => {
        if (address) {
            navigator.clipboard.writeText(address);

            toast.success('Copied!');
        }
    }, [address]);

    return (
        <div className={CnDropdownItem()}>
            <div className={CnDropdownItem('row')}>
                <div className={CnDropdownItem('icon')}>
                    {adapter ? (
                        <img src={adapter.icon} alt={adapter.name} />
                    ) : null}
                </div>
                <span className={CnDropdownItem('address')}>
                    {formattedAddress}
                </span>
            </div>

            <div
                onClick={copyClickCallback}
                className={CnDropdownItem('action')}
            >
                <Icons.Copy />
            </div>
            <div
                onClick={disconnectClickCallback}
                className={CnDropdownItem('action')}
            >
                <Icons.TurnOff />
            </div>
        </div>
    );
};
