import { cn } from '@bem-react/classname';
import { Button } from 'components';
import { config } from 'config';
import {
    supportedAptosNetworks,
    supportedEvmNetworks,
    supportedSuiNetworks,
} from 'consts';
import {
    useAppDispatch,
    useAppSelector,
    useAptosWalletContext,
    useEvmWalletContext,
    useSuiWalletContext,
} from 'hooks';
import { setTransferFetchStatus } from 'store';
import { FetchStatus, Networks, SuiDestCoin, Tokens, WalletTypes } from 'types';
import React, { useCallback, useDeferredValue, useMemo } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router';

const CnTransferForm = cn('transferForm');

export const TransferFormButton: React.FC = () => {
    const { amount, from, to, amountToReceive } = useAppSelector(
        (store) => store.transferForm,
    );
    const deferredAmount = useDeferredValue(amount);
    const selectedToken = useAppSelector((store) => store.user.selectedToken);
    const navigate = useNavigate();

    const fromConfig = useMemo(
        () => config[from][selectedToken],
        [from, selectedToken],
    );

    const toConfig = useMemo(
        () => config[to][selectedToken],
        [to, selectedToken],
    );

    const liquidity = useAppSelector(
        (store) => store.transferLiquidity.liquidity,
    );

    const receiveLiquidity = useMemo(() => {
        if (liquidity.tokens) {
            const liqItem = liquidity.tokens.find(
                (item: any) =>
                    item.name.toLowerCase() === toConfig.symbol.toLowerCase(),
            );

            if (liqItem) {
                return liqItem.balance;
            }

            return null;
        }

        return null;
    }, [liquidity, toConfig.symbol]);

    const isLiquidityError = useMemo(
        () =>
            receiveLiquidity ? Number(deferredAmount) > receiveLiquidity : null,
        [receiveLiquidity, deferredAmount],
    );

    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const suiWallet = useSuiWalletContext();

    const currWallet = useMemo(() => {
        if (supportedEvmNetworks.includes(from)) {
            return evmWallet;
        } else if (supportedAptosNetworks.includes(from)) {
            return aptosWallet;
        } else {
            return suiWallet;
        }
    }, [from, aptosWallet, evmWallet, suiWallet]);

    const balanceMap = useAppSelector((store) => store.user.balance);

    const balance = useMemo(
        () => Number(balanceMap[currWallet.uniqueKey]),
        [currWallet.uniqueKey, balanceMap],
    );

    const isBalanceError = useMemo(
        () => Number(deferredAmount) > balance,
        [deferredAmount, balance],
    );

    const receiveWallet = useMemo(() => {
        if (supportedEvmNetworks.includes(to)) {
            return evmWallet;
        } else if (supportedAptosNetworks.includes(to)) {
            return aptosWallet;
        } else {
            return suiWallet;
        }
    }, [to, aptosWallet, evmWallet, suiWallet]);

    const dispatch = useAppDispatch();

    const submitClickCallback = useCallback(async () => {
        try {
            navigate('?modal=transferLoader');
            dispatch(setTransferFetchStatus(FetchStatus.FETCHING));

            if (supportedEvmNetworks.includes(from)) {
                if (
                    evmWallet.isTokenApproved &&
                    evmWallet.approveToken &&
                    evmWallet.transfer &&
                    aptosWallet.isTokenApproved &&
                    aptosWallet.approveToken
                ) {
                    const isEvmTokenApproved = await evmWallet.isTokenApproved(
                        amount,
                    );

                    if (!isEvmTokenApproved) {
                        await evmWallet.approveToken(amount);
                    }

                    if (to === Networks.Aptos) {
                        const isAptosTokenApproved =
                            await aptosWallet.isTokenApproved();

                        if (!isAptosTokenApproved) {
                            await aptosWallet.approveToken();
                        }
                    }

                    await evmWallet.transfer(
                        from,
                        to,
                        amount,
                        receiveWallet.address ?? '',
                        toConfig.chain,
                        fromConfig.chain,
                    );
                }
            } else if (supportedAptosNetworks.includes(from)) {
                if (
                    aptosWallet.transfer &&
                    aptosWallet.isTokenApproved &&
                    aptosWallet.approveToken
                ) {
                    const isAptosTokenApproved =
                        await aptosWallet.isTokenApproved();

                    if (!isAptosTokenApproved) {
                        await aptosWallet.approveToken();
                    }

                    await aptosWallet.transfer(
                        from,
                        to,
                        amount,
                        receiveWallet.address ?? '',
                        toConfig.chain,
                        fromConfig.chain,
                    );
                }
            } else {
                if (suiWallet.transfer) {
                    if (
                        supportedAptosNetworks.includes(to) &&
                        aptosWallet.isTokenApproved &&
                        aptosWallet.approveToken
                    ) {
                        const isAptosTokenApproved =
                            await aptosWallet.isTokenApproved();

                        if (!isAptosTokenApproved) {
                            await aptosWallet.approveToken();
                        }
                    }

                    const destCoin =
                        selectedToken === Tokens.USDT
                            ? SuiDestCoin.USDT
                            : SuiDestCoin.USDC;

                    await suiWallet.transfer(
                        from,
                        to,
                        amount,
                        receiveWallet.address ?? '',
                        toConfig.chain,
                        destCoin,
                        fromConfig.chain,
                    );
                }
            }
        } catch (err: any) {
            navigate('/');

            toast.error(err.message);
        } finally {
            dispatch(setTransferFetchStatus(FetchStatus.FETCHED));
        }
    }, [
        evmWallet,
        aptosWallet,
        suiWallet,
        receiveWallet,
        amount,
        toConfig.chain,
        fromConfig.chain,
        from,
        to,
        selectedToken,
        dispatch,
        navigate,
    ]);

    const connectWalletCallback = useCallback(
        (walletType: WalletTypes) => {
            return () => {
                navigate('?modal=connectWallet', {
                    state: {
                        walletType,
                    },
                });
            };
        },
        [navigate],
    );

    const content = useMemo(() => {
        if (
            (supportedEvmNetworks.includes(from) ||
                supportedEvmNetworks.includes(to)) &&
            !evmWallet.address
        ) {
            return (
                <Button onClick={connectWalletCallback(WalletTypes.Evm)}>
                    Connect EVM wallet
                </Button>
            );
        }

        if (
            (supportedAptosNetworks.includes(from) ||
                supportedAptosNetworks.includes(to)) &&
            !aptosWallet.address
        ) {
            return (
                <Button onClick={connectWalletCallback(WalletTypes.Aptos)}>
                    Connect Aptos wallet
                </Button>
            );
        }

        if (
            (supportedSuiNetworks.includes(from) ||
                supportedSuiNetworks.includes(to)) &&
            !suiWallet.address
        ) {
            return (
                <Button onClick={connectWalletCallback(WalletTypes.Sui)}>
                    Connect Sui wallet
                </Button>
            );
        }

        if (isBalanceError) {
            return <Button disabled>Not enough balance</Button>;
        }

        if (isLiquidityError) {
            return <Button disabled>Not enough liquidity</Button>;
        }

        if (!amount) {
            return <Button disabled>Enter amount</Button>;
        }

        if (Number(amount) && !Number(amountToReceive)) {
            return <Button disabled>Transaction value is too low</Button>;
        }

        return (
            <Button
                onClick={submitClickCallback}
                disabled={!Number(amount) || !Number(amountToReceive)}
            >
                Send
            </Button>
        );
    }, [
        aptosWallet.address,
        evmWallet.address,
        suiWallet.address,
        amount,
        isBalanceError,
        submitClickCallback,
        connectWalletCallback,
        amountToReceive,
        isLiquidityError,
        from,
        to,
    ]);

    return <div className={CnTransferForm('action')}>{content}</div>;
};
