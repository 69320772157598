import { createSelector } from '@reduxjs/toolkit';
import { State } from '../store';

const userSelector = (state: State) => state.user;

const balance = createSelector(userSelector, (userState) => userState.balance);

const selectedToken = createSelector(
    userSelector,
    (userState) => userState.selectedToken,
);

export const userSelectors = {
    balance,
    selectedToken,
};
