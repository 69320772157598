import { Icons } from 'assets';
import { Networks } from 'types';

export const networkMap = {
    [Networks.Aptos]: {
        icon: <Icons.Aptos />,
        title: 'Aptos',
        network: Networks.Aptos,
    },
    [Networks.Arbitrum]: {
        icon: <Icons.Arbitrum />,
        title: 'Arbitrum',
        network: Networks.Arbitrum,
    },
    [Networks.Bsc]: {
        icon: <Icons.BscNetwork />,
        title: 'BNB',
        network: Networks.Bsc,
    },
    [Networks.Optimism]: {
        icon: <Icons.OptimismNetwork />,
        title: 'Optimism',
        network: Networks.Optimism,
    },
    [Networks.Polygon]: {
        icon: <Icons.PolygonNetwork />,
        title: 'Polygon',
        network: Networks.Polygon,
    },
    [Networks.ZkSync]: {
        icon: <Icons.ZkSync />,
        title: 'zkSync Era',
        network: Networks.ZkSync,
    },
    [Networks.ZkPolygon]: {
        icon: <Icons.PolygonZkEvm />,
        title: 'Polygon zkEVM',
        network: Networks.ZkPolygon,
    },
    [Networks.BobaEth]: {
        icon: <Icons.BobaEth />,
        title: 'Boba Ethereum',
        network: Networks.BobaEth,
    },
    [Networks.BobaBnb]: {
        icon: <Icons.BobaBnb />,
        title: 'Boba BNB',
        network: Networks.BobaBnb,
    },
    [Networks.Sui]: {
        icon: <Icons.Sui />,
        title: 'Sui',
        network: Networks.Sui,
    },
};
