import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { networkList } from './InputTokenSelect.constants';
import { Networks } from 'types';
import { useDispatch } from 'react-redux';
import { setTransferFrom, setTransferTo } from 'store';
import { useAppSelector, useEvmWalletContext } from 'hooks';
import { chainFromNetwork, supportedEvmNetworks } from 'consts';

import './InputTokenSelect.scss';

const CnInputTokenSelect = cn('inputTokenSelect');

interface IInputTokenSelect {
    selectedItem: {
        icon: any;
        title: string;
        network: Networks;
    };
    disabled?: boolean;
    type?: 'from' | 'to';
}

var inputTokenSelectDropdownTimeout: null | NodeJS.Timer = null;

export const InputTokenSelect: React.FC<IInputTokenSelect> = ({
    selectedItem,
    disabled = false,
    type = 'from',
}) => {
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    const dropdownRef = useRef<any>(null);
    const baseRef = useRef<any>(null);

    const isDropdownShowChangeCallback = useCallback(() => {
        if (!disabled) {
            setIsDropdownShow((prev) => {
                if (inputTokenSelectDropdownTimeout) {
                    clearTimeout(inputTokenSelectDropdownTimeout);
                }

                return !prev;
            });
        }
    }, [disabled]);

    const hideDropdownCallback = useCallback(() => {
        setIsDropdownShow(false);
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                baseRef.current &&
                !baseRef.current.contains(event.target)
            ) {
                hideDropdownCallback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, baseRef]);

    const onMouseEnter = useCallback(() => {
        if (inputTokenSelectDropdownTimeout) {
            clearTimeout(inputTokenSelectDropdownTimeout);
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        inputTokenSelectDropdownTimeout = setTimeout(() => {
            hideDropdownCallback();
        }, 1000);
    }, [hideDropdownCallback]);

    return (
        <div className={CnInputTokenSelect({ show: isDropdownShow })}>
            <div
                ref={baseRef}
                onClick={isDropdownShowChangeCallback}
                className={CnInputTokenSelect('item')}
            >
                <div className={CnInputTokenSelect('itemLeftWrapper')}>
                    <div className={CnInputTokenSelect('itemIcon')}>
                        {selectedItem.icon}
                    </div>
                    <div className={CnInputTokenSelect('itemTitle')}>
                        {selectedItem.title}
                    </div>
                </div>

                {!disabled && (
                    <Icons.AngleBlack
                        className={CnInputTokenSelect('itemAngle')}
                    />
                )}
            </div>

            {!disabled && (
                <div
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    ref={dropdownRef}
                    className={CnInputTokenSelect('dropdown')}
                >
                    <InputTokenSelectDropdown
                        type={type}
                        selectedNetwork={selectedItem.network}
                        hideDropdown={isDropdownShowChangeCallback}
                    />
                </div>
            )}
        </div>
    );
};

const CnInputTokenSelectDropdown = cn('inputTokenSelectDropdown');

const InputTokenSelectDropdown: React.FC<{
    selectedNetwork: Networks;
    type: 'from' | 'to';
    hideDropdown: any;
}> = ({ selectedNetwork, type, hideDropdown }) => {
    const networksContent = useMemo(() => {
        return networkList
            .filter((network) => network.network !== selectedNetwork)
            .map((network) => (
                <InputTokenSelectDropdownItem
                    type={type}
                    key={network.name}
                    hideDropdown={hideDropdown}
                    {...network}
                />
            ));
    }, [selectedNetwork, type, hideDropdown]);

    return (
        <div className={CnInputTokenSelectDropdown()}>{networksContent}</div>
    );
};

const CnInputTokenSelectDropdownItem = cn('inputTokenSelectDropdownItem');

interface IInputTokenSelectDropdownItem {
    icon: any;
    name: string;
    network: Networks;
    type: 'from' | 'to';
    hideDropdown: any;
}

const InputTokenSelectDropdownItem: React.FC<IInputTokenSelectDropdownItem> = ({
    icon,
    name,
    type,
    network,
    hideDropdown,
}) => {
    const dispatch = useDispatch();
    const evmWallet = useEvmWalletContext();
    const to = useAppSelector((store) => store.transferForm.to);
    const from = useAppSelector((store) => store.transferForm.from);

    const switchNetwork = useCallback(
        async (network: Networks) => {
            const chainId = chainFromNetwork[network];
            if (
                supportedEvmNetworks.includes(network) &&
                evmWallet.switchNetwork &&
                chainId !== evmWallet.chainId
            ) {
                try {
                    await evmWallet.switchNetwork(chainId);
                } catch (err) {
                    throw err;
                }
            }
        },
        [evmWallet],
    );

    const networkClickCallback = useCallback(async () => {
        if (type === 'from') {
            await switchNetwork(network).then(() =>
                dispatch(setTransferFrom(network)),
            );
        } else {
            if (network === from) {
                await switchNetwork(to).then(() => {
                    dispatch(setTransferFrom(to));
                });
            }

            dispatch(setTransferTo(network));
        }
        hideDropdown();
    }, [type, from, to, network, dispatch, switchNetwork, hideDropdown]);

    return (
        <div
            onClick={networkClickCallback}
            className={CnInputTokenSelectDropdownItem()}
        >
            <div className={CnInputTokenSelectDropdownItem('icon')}>{icon}</div>
            <div className={CnInputTokenSelectDropdownItem('name')}>{name}</div>
        </div>
    );
};
