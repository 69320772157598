import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { useDispatch } from 'react-redux';
import {
    useAppSelector,
    useAptosWalletContext,
    useEvmWalletContext,
} from 'hooks';
import { setTransferFrom, setTransferTo } from 'store';
import { Networks } from 'types';
import { mixpanelEvents } from 'analytic';
import { chainFromNetwork, supportedEvmNetworks } from 'consts';

import './Swap.scss';

const CnSwap = cn('swap');

export const Swap: React.FC = () => {
    const dispatch = useDispatch();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();

    const from = useAppSelector((store) => store.transferForm.from);
    const to = useAppSelector((store) => store.transferForm.to);

    const switchNetwork = useCallback(
        async (network: Networks) => {
            if (
                supportedEvmNetworks.includes(network) &&
                evmWallet.chainId !== chainFromNetwork[network] &&
                evmWallet.switchNetwork
            ) {
                try {
                    await evmWallet.switchNetwork(chainFromNetwork[network]);
                } catch (err) {
                    throw err;
                }
            }
        },
        [evmWallet],
    );

    const angleClickCallback = useCallback(() => {
        if (aptosWallet.fetchBalance) {
            aptosWallet.fetchBalance();
        }

        if (evmWallet.fetchBalance) {
            evmWallet.fetchBalance();
        }

        const newFrom = to;
        const newTo = from;

        switchNetwork(to).then(() => {
            mixpanelEvents.networkSwitch(newTo, newFrom);

            dispatch(setTransferFrom(newFrom));
            dispatch(setTransferTo(newTo));
        });
    }, [from, dispatch, aptosWallet, evmWallet, to, switchNetwork]);

    return (
        <div className={CnSwap()}>
            <div
                onClick={angleClickCallback}
                className={CnSwap('angle', { rotate: true })}
            >
                <Icons.Angle />
            </div>

            <div onClick={angleClickCallback} className={CnSwap('angle')}>
                <Icons.Angle />
            </div>
        </div>
    );
};
