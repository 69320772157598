import {
    useCallback,
    useMemo,
    useEffect,
    useState,
    useDeferredValue,
} from 'react';
import { cn } from '@bem-react/classname';
import { useLocation, useNavigate } from 'react-router';
import { Connector } from 'wagmi';
import { WalletName } from '@mov3r/aptos-wallet-adapter';
import { WalletAdapter } from '@mysten/wallet-adapter-base/dist';
import { WalletTypes } from 'types';

import './ConnectWalletModal.css';
import {
    useAptosWalletContext,
    useEvmWalletContext,
    useSuiWalletContext,
} from 'hooks';
import { iconByEvmWalletName } from 'consts';
import {
    aptosWalletsList,
    badgeColorFromWallet,
    walletTypes,
} from './ConnectWalletModal.constants';

const CnConnectWalletModal = cn('connectWalletModal');

export const ConnectWalletModal = () => {
    const { search, state } = useLocation();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const isShow = useMemo(() => search.includes('connectWallet'), [search]);

    const stateWalletType = useMemo(
        () => (state.walletType as WalletTypes) ?? WalletTypes.All,
        [state.walletType],
    );

    useEffect(() => {
        if (!state || !stateWalletType) {
            navigate(pathname);
        }
    }, []);

    const [selectedWalletType, setSelectedWalletType] =
        useState(stateWalletType);

    const selectedWalletTypeChangeCallback = useCallback(
        (walletType: WalletTypes) => {
            setSelectedWalletType(walletType);
        },
        [],
    );

    const [walletSearch, setWalletSearch] = useState('');
    const deferredWalletSearch = useDeferredValue(
        walletSearch.toLowerCase().trim(),
    );

    const walletSearchChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setWalletSearch(e.target.value);
        },
        [],
    );

    useEffect(() => {
        if (isShow) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isShow]);

    const evmWallet = useEvmWalletContext();

    const searchContent = useMemo(() => {
        return (
            <div className={CnConnectWalletModal('search')}>
                <input
                    value={walletSearch}
                    onChange={walletSearchChangeCallback}
                    placeholder="Wallet name"
                />
                <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10 17C13.866 17 17 13.866 17 10C17 6.13401 13.866 3 10 3C6.13401 3 3 6.13401 3 10C3 13.866 6.13401 17 10 17Z"
                        stroke="#17171A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M15 15L21 21"
                        stroke="#17171A"
                        strokeOpacity="0.2"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </div>
        );
    }, [walletSearch, walletSearchChangeCallback]);

    const connectEvmWalletClickCallback = useCallback(
        (connector: Connector) => {
            return async () => {
                try {
                    if (connector.name === 'WalletConnect') {
                        navigate(pathname);
                    }
                    if (evmWallet.connect) {
                        await evmWallet.connect(connector);
                    }
                } catch (err) {
                    console.log('ERROR WHILE CONNECTING', err);
                } finally {
                    navigate(pathname);
                }
            };
        },
        [evmWallet, navigate, pathname],
    );

    const evmWallets = useMemo(
        () =>
            evmWallet.connectors.map((connector) => ({
                key: connector.id,
                name: connector.name,
                icon: iconByEvmWalletName[connector.name],
                onClick: connectEvmWalletClickCallback(connector),
                badge: badgeColorFromWallet[connector.name] ?? '#fff',
                type: WalletTypes.Evm,
            })),
        [evmWallet.connectors, connectEvmWalletClickCallback],
    );

    console.log(evmWallet.connectors);

    const aptosWalletContext = useAptosWalletContext();

    const connectAptosWalletClickCallback = useCallback(
        (walletName: WalletName<string>) => {
            return async () => {
                try {
                    if (aptosWalletContext.connect) {
                        await aptosWalletContext.connect(walletName);
                    }
                } catch (err) {
                    console.log('ERROR WHILE CONNECTING', err);
                } finally {
                    navigate(pathname);
                }
            };
        },
        [aptosWalletContext, navigate, pathname],
    );

    const aptosWallets = useMemo(
        () =>
            aptosWalletsList.map((wallet) => ({
                key: wallet.name,
                name: wallet.title,
                icon: wallet.icon,
                onClick: connectAptosWalletClickCallback(wallet.name),
                badge: badgeColorFromWallet[wallet.name] ?? '#fff',
                type: WalletTypes.Aptos,
            })),
        [connectAptosWalletClickCallback],
    );

    const suiWalletContext = useSuiWalletContext();

    const connectSuiWalletClickCallback = useCallback(
        (wallet: WalletAdapter) => {
            return async () => {
                try {
                    if (suiWalletContext.connect) {
                        await suiWalletContext.connect(wallet.name);
                    }
                } catch (err) {
                    console.log('ERROR WHILE CONNECTING', err);
                } finally {
                    navigate(pathname);
                }
            };
        },
        [suiWalletContext, navigate, pathname],
    );

    const suiWallets = useMemo(
        () =>
            suiWalletContext.connectors.map((connector) => ({
                key: connector.name,
                name: connector.name,
                icon: <img src={connector.icon} alt={connector.name} />,
                onClick: connectSuiWalletClickCallback(connector),
                badge: badgeColorFromWallet[connector.name] ?? '#fff',
                type: WalletTypes.Sui,
            })),
        [suiWalletContext.connectors, connectSuiWalletClickCallback],
    );

    const walletTypesContent = useMemo(
        () =>
            walletTypes.map((type) => (
                <div
                    key={type.type}
                    onClick={() => selectedWalletTypeChangeCallback(type.type)}
                    className={CnConnectWalletModal('type', {
                        selected: type.type === selectedWalletType,
                    })}
                >
                    {type.icon ? (
                        <div className={CnConnectWalletModal('type-icon')}>
                            {type.icon}
                        </div>
                    ) : null}
                    <div className={CnConnectWalletModal('type-title')}>
                        {type.title}
                    </div>
                </div>
            )),
        [selectedWalletType, selectedWalletTypeChangeCallback],
    );

    const wallets = useMemo(
        () => [...evmWallets, ...aptosWallets, ...suiWallets],
        [evmWallets, suiWallets, aptosWallets],
    );

    const walletsContent = useMemo(() => {
        return wallets
            .filter((wallet) => {
                let result = true;

                if (selectedWalletType !== WalletTypes.All) {
                    if (wallet.type !== selectedWalletType) {
                        result = false;
                    }
                }

                if (deferredWalletSearch) {
                    const lCaseWalletName = wallet.name.toLowerCase();

                    if (!lCaseWalletName.includes(deferredWalletSearch)) {
                        result = false;
                    }
                }

                return result;
            })
            .map((wallet) => (
                <div
                    onClick={wallet.onClick}
                    key={wallet.name}
                    className={CnConnectWalletModal('wallet')}
                >
                    <div
                        className={CnConnectWalletModal('wallet-icon')}
                        style={{ backgroundColor: wallet.badge }}
                    >
                        {wallet.icon}
                    </div>
                    <div className={CnConnectWalletModal('wallet-title')}>
                        {wallet.name}
                    </div>
                </div>
            ));
    }, [wallets, deferredWalletSearch, selectedWalletType]);

    return (
        <div className={CnConnectWalletModal()}>
            <div className={CnConnectWalletModal('title')}>Connect wallet</div>
            {isShow ? (
                <div className={CnConnectWalletModal('wallets')}>
                    {searchContent}
                    <div className={CnConnectWalletModal('subtitle')}>
                        Choose type
                    </div>
                    <div className={CnConnectWalletModal('type-grid')}>
                        {walletTypesContent}
                    </div>
                    <div className={CnConnectWalletModal('subtitle')}>
                        Choose wallet
                    </div>
                    <div className={CnConnectWalletModal('wallets-grid')}>
                        {walletsContent}
                    </div>
                </div>
            ) : null}
        </div>
    );
};
