import { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector, useSuiWalletContext } from 'hooks';
import { transferFormSelectors } from 'store';
import { config } from 'config';

import './ChangeSuiNetworkModal.css';

const CnChangeNetworkModal = cn('changeNetworkModal');

export const ChangeSuiNetworkModal: React.FC = () => {
    const { chain } = useSuiWalletContext();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const from = useAppSelector(transferFormSelectors.from);

    useEffect(() => {
        if (chain) {
            if (chain.includes(config.sui.suiChain)) {
                navigate(pathname);
            }
        }
    }, [chain, navigate, from]);

    return (
        <div className={CnChangeNetworkModal()}>
            <div className={CnChangeNetworkModal('title')}>
                Change Network to Sui Mainnet
            </div>

            <div className={CnChangeNetworkModal('text')}>
                Right now we only support the Mainnet network of the Sui
                blockchain. Please change it using your wallet and refresh page.
            </div>
        </div>
    );
};
