import { cn } from '@bem-react/classname';
import { StakingForm, StakingInfo } from './components';
import { FC } from 'react';
import { useAptosWalletContext } from 'hooks';

import './Staking.scss';

const CnStaking = cn('staking');

export const Staking: FC = () => {
    const { veMoverBalance, stakingData } = useAptosWalletContext();

    return (
        <div className={CnStaking()}>
            <div>
                <StakingInfo
                    amount={stakingData?.amount}
                    end={stakingData?.end}
                    veMover={veMoverBalance}
                />
            </div>
            <div>
                <StakingForm
                    amount={stakingData?.amount}
                    end={stakingData?.end}
                />
            </div>
        </div>
    );
};
