import React from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './Logo.scss';

const CnLogo = cn('logo');

export const Logo: React.FC = () => {
    return <Icons.Logo className={CnLogo()} />;
};
