import React, {
    memo,
    useCallback,
    useDeferredValue,
    useEffect,
    useMemo,
} from 'react';
import { cn } from '@bem-react/classname';
import { config } from 'config';
import { Networks } from 'types';
import BigNumber from 'bignumber.js';
import { commify } from 'ethers/lib/utils.js';
import {
    useAppDispatch,
    useAppSelector,
    useAptosWalletContext,
    useEvmWalletContext,
    useSuiWalletContext,
} from 'hooks';
import {
    iconByAtposWalletName,
    supportedAptosNetworks,
    supportedEvmNetworks,
    supportedSuiNetworks,
} from 'consts';
import { setTransferAmount, setTransferFees } from 'store';
import { Icons } from 'assets';
import { InputTokenSelect, TransferInput } from 'components';
import { networkMap } from '../TransferForm.constants';

const CnTransferForm = cn('transferForm');

const calculateFees = (
    amount: string,
    tokenDecimals: number,
    relayerFee: number | null,
    from: Networks,
    aptosReducedFee: number,
) => {
    if (
        amount.trim().length === 0 ||
        Number(amount) === 0 ||
        isNaN(Number(amount))
    ) {
        return {
            fees: '',
            feesToShow: '',
            amountToReceive: '',
            baseFee: '',
            baseFeeToShow: '',
        };
    }

    const amountBn = new BigNumber(amount);
    const relayerFeeBn = new BigNumber(String(relayerFee));

    const calculatedFee = amountBn
        .multipliedBy(from === Networks.Aptos ? aptosReducedFee : 0.13)
        .dividedBy(100);

    const baseFee = amountBn
        .multipliedBy(from === Networks.Aptos ? 1 : 0.13)
        .dividedBy(100);

    const baseFeeToShow = () => {
        const isLess =
            BigNumber(baseFee).isGreaterThan('0') &&
            BigNumber(baseFee).isLessThan('0.0001');
        const value = commify(baseFee.toFixed(4)).toString();
        return `${isLess ? '<' : ''}${value}`;
    };

    const feeToShow = () => {
        const isLess =
            BigNumber(calculatedFee).isGreaterThan('0') &&
            BigNumber(calculatedFee).isLessThan('0.0001');
        const value = commify(calculatedFee.toFixed(4)).toString();
        return `${isLess ? '<' : ''}${value}`;
    };

    const amountToReceive = amountBn
        .minus(calculatedFee)
        .minus(relayerFeeBn)
        .toFixed(tokenDecimals);

    return {
        fees: calculatedFee.toString(),
        feesToShow: feeToShow(),
        amountToReceive,
        baseFee: baseFee.toString(),
        baseFeeToShow: baseFeeToShow(),
    };
};

export const TransferFormFrom: React.FC = memo(() => {
    const dispatch = useAppDispatch();
    const selectedToken = useAppSelector((store) => store.user.selectedToken);
    const from = useAppSelector((store) => store.transferForm.from);
    const to = useAppSelector((store) => store.transferForm.to);
    const evmWallet = useEvmWalletContext();
    const aptosWallet = useAptosWalletContext();
    const suiWallet = useSuiWalletContext();
    const balance = useAppSelector((store) => store.user.balance);
    const amount = useAppSelector((store) => store.transferForm.amount);
    const deferredAmount = useDeferredValue(amount);

    const senderConfig = useMemo(
        () => (config as any)[from][selectedToken],
        [from, selectedToken],
    );

    const currWallet = useMemo(() => {
        if (supportedEvmNetworks.includes(from)) {
            return evmWallet;
        } else if (supportedAptosNetworks.includes(from)) {
            return aptosWallet;
        } else {
            return suiWallet;
        }
    }, [evmWallet, aptosWallet, suiWallet, from]);

    const receiveWallet = useMemo(() => {
        if (supportedAptosNetworks.includes(to)) {
            return aptosWallet;
        } else if (supportedEvmNetworks.includes(to)) {
            return evmWallet;
        } else {
            return suiWallet;
        }
    }, [evmWallet, aptosWallet, suiWallet, to]);

    const receiverConfig = useMemo(
        () => config[to][selectedToken],
        [to, selectedToken],
    );

    useEffect(() => {
        if (from === Networks.Aptos) {
            const calculatedFees = calculateFees(
                deferredAmount,
                receiverConfig.decimals,
                receiveWallet.relayerFee,
                from,
                (currWallet as any).reduceFeePercent,
            );

            if (Number(calculatedFees.amountToReceive) > 0) {
                dispatch(setTransferFees(calculatedFees));
            } else {
                dispatch(
                    setTransferFees({
                        fees: '',
                        feesToShow: '',
                        amountToReceive: '',
                        baseFee: '',
                        baseFeeToShow: '',
                    }),
                );
            }
        } else {
            const calculatedFees = calculateFees(
                deferredAmount,
                receiverConfig.decimals,
                receiveWallet.relayerFee,
                from,
                1,
            );

            if (Number(calculatedFees.amountToReceive) > 0) {
                dispatch(setTransferFees(calculatedFees));
            } else {
                dispatch(
                    setTransferFees({
                        fees: '',
                        feesToShow: '',
                        amountToReceive: '',
                        baseFee: '',
                        baseFeeToShow: '',
                    }),
                );
            }
        }
    }, [
        deferredAmount,
        from,
        dispatch,
        receiverConfig,
        receiveWallet.relayerFee,
    ]);

    const currTokenBalance = useMemo(
        () => balance[currWallet.uniqueKey]?.slice(0, 7),
        [currWallet.uniqueKey, balance],
    );

    const currTokenSymbol = useMemo(() => senderConfig.symbol, [senderConfig]);

    const fromAddress = useMemo(() => {
        if (supportedAptosNetworks.includes(from) && !aptosWallet.address)
            return null;

        if (supportedEvmNetworks.includes(from) && !evmWallet.address)
            return null;

        if (supportedSuiNetworks.includes(from) && !suiWallet.address)
            return null;

        if (supportedEvmNetworks.includes(from)) {
            return evmWallet.formattedAddress;
        } else if (supportedAptosNetworks.includes(from)) {
            return aptosWallet.formattedAddress;
        } else {
            return suiWallet.formattedAddress;
        }
    }, [from, evmWallet, aptosWallet, suiWallet]);

    const fromIcon = useMemo(() => {
        if (supportedAptosNetworks.includes(from) && !aptosWallet.address)
            return null;

        if (supportedEvmNetworks.includes(from) && !evmWallet.address)
            return null;

        if (supportedSuiNetworks.includes(from) && !suiWallet.address)
            return null;

        if (supportedEvmNetworks.includes(from)) {
            return <Icons.Metamask />;
        } else if (supportedAptosNetworks.includes(from)) {
            return aptosWallet?.adapter?.name ? (
                iconByAtposWalletName[aptosWallet?.adapter?.name]
            ) : (
                <Icons.Aptos />
            );
        } else {
            if (suiWallet.adapter) {
                return (
                    <img
                        src={suiWallet.adapter.icon}
                        alt={suiWallet.adapter.name}
                    />
                );
            } else {
                return <Icons.Sui />;
            }
        }
    }, [from, aptosWallet, evmWallet, suiWallet]);

    const maxClickCallback = useCallback(() => {
        dispatch(setTransferAmount(currTokenBalance));
    }, [dispatch, currTokenBalance]);

    const amountChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = e.target.value;

            if (!isNaN(Number(value))) {
                const isDec = value.includes('.');
                const splitted = value.split('.');
                const dec = splitted[splitted.length - 1];

                if (!isDec || dec.length <= senderConfig.decimals)
                    dispatch(setTransferAmount(value));
            }
        },
        [dispatch, senderConfig],
    );

    const balanceContent = useMemo(() => {
        if (supportedAptosNetworks.includes(from) && !aptosWallet.address)
            return null;

        if (supportedEvmNetworks.includes(from) && !evmWallet.address)
            return null;

        if (supportedSuiNetworks.includes(from) && !suiWallet.address)
            return null;

        return (
            <div className={CnTransferForm('balance')}>
                Balance: {currTokenBalance} {senderConfig.symbol}
                <div
                    onClick={maxClickCallback}
                    className={CnTransferForm('max')}
                >
                    MAX
                </div>
            </div>
        );
    }, [
        currTokenBalance,
        senderConfig,
        aptosWallet.address,
        evmWallet.address,
        suiWallet.address,
        from,
        maxClickCallback,
    ]);

    const transferInputLeftContent = useMemo(
        () => <InputTokenSelect type="from" selectedItem={networkMap[from]} />,
        [from],
    );

    const transferFormFrom = useMemo(
        () => (
            <div className={CnTransferForm('from')}>
                <div>
                    <div className={CnTransferForm('label')}>From</div>
                    <div className={CnTransferForm('wallet')}>
                        {fromIcon}
                        {fromAddress}
                    </div>
                </div>
                {balanceContent}
            </div>
        ),
        [balanceContent, fromIcon, fromAddress],
    );

    return (
        <div className={CnTransferForm('field')}>
            {transferFormFrom}
            <TransferInput
                leftContent={transferInputLeftContent}
                token={currTokenSymbol}
                placeholder="0.0"
                value={amount}
                onChange={amountChangeCallback}
            />
        </div>
    );
});
