import React, {
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { tokenList, tokenMap } from './SelectToken.constants';
import { setSelectedToken } from 'store';
import { Tokens } from 'types';
import { useAppDispatch, useAppSelector } from 'hooks';
import { mixpanelEvents } from 'analytic';

import './SelectToken.scss';

const CnSelectToken = cn('selectToken');

var selectTokenSelectDropdownTimeout: null | NodeJS.Timer = null;

export const SelectToken: React.FC = () => {
    const [isDropdownShow, setIsDropdownShow] = useState(false);
    const dropdownRef = useRef<any>(null);
    const baseRef = useRef<any>(null);

    const isDropdownShowChangeCallback = useCallback(() => {
        setIsDropdownShow((prev) => {
            if (selectTokenSelectDropdownTimeout) {
                clearTimeout(selectTokenSelectDropdownTimeout);
            }
            return !prev;
        });
    }, []);

    const hideDropdownCallback = useCallback(() => {
        setIsDropdownShow(false);
    }, []);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target) &&
                baseRef.current &&
                !baseRef.current.contains(event.target)
            ) {
                hideDropdownCallback();
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef, baseRef]);

    const selectedToken = useAppSelector((store) => store.user.selectedToken);

    const currToken = useMemo(() => tokenMap[selectedToken], [selectedToken]);
    const Icon = useMemo(() => currToken.icon, [currToken]);

    const onMouseEnter = useCallback(() => {
        if (selectTokenSelectDropdownTimeout) {
            clearTimeout(selectTokenSelectDropdownTimeout);
        }
    }, []);

    const onMouseLeave = useCallback(() => {
        selectTokenSelectDropdownTimeout = setTimeout(() => {
            hideDropdownCallback();
        }, 1000);
    }, [hideDropdownCallback]);

    return (
        <div ref={baseRef} className={CnSelectToken({ show: isDropdownShow })}>
            <div className={CnSelectToken('label')}>Send</div>
            <div
                onClick={isDropdownShowChangeCallback}
                className={CnSelectToken('token')}
            >
                <div className={CnSelectToken('tokenIcon')}>{<Icon />}</div>
                <div className={CnSelectToken('tokenName')}>
                    {currToken.name}
                </div>

                <div className={CnSelectToken('angle')}>
                    <Icons.Angle />
                </div>
            </div>
            <SelectTokenDropdown
                dropdownRef={dropdownRef}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                isDropdownShowChangeCallback={isDropdownShowChangeCallback}
            />
        </div>
    );
};

const CnSelectTokenDropdown = cn('selectTokenDropdown');

const SelectTokenDropdown: React.FC<{
    isDropdownShowChangeCallback: () => void;
    onMouseEnter: any;
    onMouseLeave: any;
    dropdownRef: any;
}> = ({
    isDropdownShowChangeCallback,
    onMouseEnter,
    onMouseLeave,
    dropdownRef,
}) => {
    return (
        <div
            ref={dropdownRef}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            className={CnSelectTokenDropdown()}
        >
            {tokenList.map((token) => (
                <SelectTokenDropdownItem
                    isDropdownShowChangeCallback={isDropdownShowChangeCallback}
                    key={token.value}
                    {...token}
                />
            ))}
        </div>
    );
};

interface ISelectTokenDropdownItem {
    name: string;
    icon: any;
    value: Tokens;
    isDropdownShowChangeCallback: () => void;
}

const CnSelectTokenDropdownItem = cn('selectTokenDropdownItem');

const SelectTokenDropdownItem: React.FC<ISelectTokenDropdownItem> = ({
    name,
    icon: Icon,
    value,
    isDropdownShowChangeCallback,
}) => {
    const dispatch = useAppDispatch();
    const selectedToken = useAppSelector((store) => store.user.selectedToken);

    const tokenClickCallback = useCallback(() => {
        isDropdownShowChangeCallback();

        mixpanelEvents.tokenSelected(value);

        dispatch(setSelectedToken(value));
    }, [value, dispatch, isDropdownShowChangeCallback]);

    return (
        <div
            onClick={tokenClickCallback}
            className={CnSelectTokenDropdownItem()}
        >
            <Icon className={CnSelectTokenDropdownItem('icon')} />

            <div className={CnSelectTokenDropdownItem('name')}>{name}</div>

            {selectedToken === value && (
                <Icons.Check className={CnSelectTokenDropdownItem('check')} />
            )}
        </div>
    );
};
