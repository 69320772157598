import React from 'react';
import { cn } from '@bem-react/classname';
import { TransferStatus, Wallet } from 'components';

import './Settings.scss';

const CnSettings = cn('settings');

export const Settings: React.FC = () => {
    return (
        <div className={CnSettings()}>
            {/* <Icons.Gear /> */}
            <Wallet />
        </div>
    );
};
