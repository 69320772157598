import { cn } from '@bem-react/classname';
import { mixpanelEvents } from 'analytic';
import { fechTransactionStatus } from 'api';
import { Icons } from 'assets';
import { Progress } from 'components';
import { labelFromNetwork, linkFromNetwork } from 'consts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import {
    useAppSelector,
    useAptosWalletContext,
    useEvmWalletContext,
    useSuiWalletContext,
} from 'hooks';
import { transferStatusUpdateItem } from 'store';
import { Transfer, Networks, TransactionStatus } from 'types';
import { timerFromTimestamp } from 'utils';

import './TransferStatus.scss';

const CnTransferStatus = cn('transferStatus');

var transferStatusCloseDropdownTimer: null | NodeJS.Timer = null;

export const TransferStatus: React.FC = () => {
    const { transfers } = useAppSelector((store) => store.transferStatus);
    const [isDropdownShow, setIsDropdownShow] = useState(false);

    const isDropdownShowChangeCallback = useCallback(() => {
        setIsDropdownShow((prev) => {
            if (transferStatusCloseDropdownTimer) {
                clearTimeout(transferStatusCloseDropdownTimer);
            }

            return !prev;
        });
    }, []);

    const transfersCount = useMemo(() => {
        const transfersArr = Object.keys(transfers);

        if (!transfersArr.length) {
            return null;
        }

        const loadingTransfersCount = transfersArr.reduce((acc, key) => {
            const transfer = transfers[key];

            if (transfer.toTransactionStatus !== TransactionStatus.COMPLETE) {
                return acc + 1;
            }

            return acc;
        }, 0);

        const completeTransfersCount = transfersArr.reduce((acc, key) => {
            const transfer = transfers[key];

            if (transfer.toTransactionStatus === TransactionStatus.COMPLETE) {
                return acc + 1;
            }

            return acc;
        }, 0);

        const failedTransfersCount = transfersArr.reduce((acc, key) => {
            const transfer = transfers[key];

            if (transfer.toTransactionStatus === TransactionStatus.FAIL) {
                return acc + 1;
            }

            return acc;
        }, 0);

        if (!loadingTransfersCount && completeTransfersCount) {
            return `${completeTransfersCount} completed`;
        }

        if (
            !loadingTransfersCount &&
            !completeTransfersCount &&
            failedTransfersCount
        ) {
            return `${failedTransfersCount} failed`;
        }

        return `${loadingTransfersCount} pending...`;
    }, [transfers]);

    const transfersContent = useMemo(
        () =>
            Object.keys(transfers).map((tranferKey) => {
                const transfer = transfers[tranferKey];

                return <TransferStatusItem key={tranferKey} {...transfer} />;
            }),
        [transfers],
    );

    const onMouseEnter = () => {
        if (transferStatusCloseDropdownTimer) {
            clearTimeout(transferStatusCloseDropdownTimer);
        }
    };

    const onMouseLeave = () => {
        transferStatusCloseDropdownTimer = setTimeout(() => {
            if (isDropdownShow) {
                isDropdownShowChangeCallback();
            }
        }, 2000);
    };

    if (!transfersCount) {
        return null;
    }

    return (
        <div className={CnTransferStatus('container')}>
            <div
                onClick={isDropdownShowChangeCallback}
                className={CnTransferStatus()}
            >
                <div className={CnTransferStatus('count')}>
                    {transfersCount}
                </div>
                <Icons.Loader
                    style={{
                        opacity: transfersCount.includes('completed') ? 0 : 1,
                    }}
                />
            </div>

            <div
                className={CnTransferStatus('dropdown', {
                    show: isDropdownShow,
                })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {transfersContent}
            </div>
        </div>
    );
};

var transferStatusIntervals: Record<string, any> = {};
var transferTimerIntervals: Record<string, any> = {};

const CnTransferStatusItem = cn('transferStatusItem');

interface ITransferStatusItemProps extends Transfer {}

export const TransferStatusItem: React.FC<ITransferStatusItemProps> = ({
    token,
    amount,
    from,
    to,
    toChaindId,
    fromHash,
    toHash,
    fromChainId,
    fromTransactionStatus,
    toTransactionStatus,
    deadline,
    createdAt,
    timeWait,
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const suiWallet = useSuiWalletContext();

    const [timer, setTimer] = useState<string | null>(null);
    const isTimerExpired = useMemo(() => timer?.includes('0m 0s'), [timer]);

    const [secondsLeft, setSecondsLeft] = useState(
        new Date().getTime() - Number(createdAt),
    );

    const isFailed = useMemo(
        () => toTransactionStatus === TransactionStatus.FAIL,
        [toTransactionStatus],
    );

    const isAwaitingLiquidity = useMemo(
        () => toTransactionStatus === TransactionStatus.AWAITING_LIQUIDITY,
        [toTransactionStatus],
    );

    const isComplete = useMemo(
        () => toTransactionStatus === TransactionStatus.COMPLETE,
        [toTransactionStatus],
    );

    useEffect(() => {
        if (isFailed) {
            mixpanelEvents.tokenBridgedError({
                from: from as Networks,
                to: to as Networks,
                tokenEnum: token as string,
                amount: amount as string,
                expectedTime: timeWait as unknown as string,
                actualTime: (new Date().getTime() -
                    Number(createdAt)) as unknown as string,
                error: '',
            });
        }

        if (isAwaitingLiquidity) {
            mixpanelEvents.tokenBridgedError({
                from: from as Networks,
                to: to as Networks,
                tokenEnum: token as string,
                amount: amount as string,
                expectedTime: timeWait as unknown as string,
                actualTime: (new Date().getTime() -
                    Number(createdAt)) as unknown as string,
                error: '{ error: AWAITING_LIQUIDITY, message: "Do not enough liquidity" }',
            });
        }
    }, [isFailed, isAwaitingLiquidity]);

    useEffect(() => {
        if (isComplete && fromHash) {
            if (transferTimerIntervals[fromHash]) {
                clearInterval(transferTimerIntervals[fromHash]);
                transferTimerIntervals[fromHash] = null;
            }
        }

        if (!isComplete && deadline && fromHash && createdAt) {
            if (transferTimerIntervals[fromHash]) {
                clearInterval(transferTimerIntervals[fromHash]);
                transferTimerIntervals[fromHash] = null;
            }

            transferTimerIntervals[fromHash] = setInterval(() => {
                const { s, m } = timerFromTimestamp(deadline);

                setSecondsLeft((new Date().getTime() - createdAt) / 1000);
                setTimer(`${m}m ${s}s`);
            }, 1000);

            return () => {
                if (transferTimerIntervals[fromHash]) {
                    clearInterval(transferTimerIntervals[fromHash]);
                    transferTimerIntervals[fromHash] = null;
                }
            };
        }
    }, [deadline, fromHash, isComplete, createdAt]);

    const progressContent = useMemo(() => {
        if (isComplete) {
            return <Progress percent={`100%`} />;
        }

        if (isFailed) {
            return <Progress percent="100%" />;
        }

        const percent = timeWait ? (secondsLeft / timeWait) * 100 : 0;

        return (
            <Progress
                key={fromHash}
                percent={`${percent > 100 ? 100 : percent}%`}
            />
        );
    }, [timeWait, secondsLeft, isComplete, fromHash, isFailed]);

    useEffect(() => {
        if (fromHash && toChaindId && fromChainId && from && !isComplete) {
            if (transferStatusIntervals[fromHash]) {
                clearInterval(transferStatusIntervals[fromHash]);
                transferStatusIntervals[fromHash] = null;
            }

            transferStatusIntervals[fromHash] = setInterval(async () => {
                try {
                    const response = await fechTransactionStatus(
                        from,
                        fromHash,
                        fromChainId,
                        toChaindId,
                    );

                    const isComplete =
                        response.status === TransactionStatus.COMPLETE;

                    dispatch(
                        transferStatusUpdateItem({
                            hash: fromHash,
                            payload: {
                                toTransactionStatus: response.status,
                            },
                        }),
                    );

                    if (response?.relayTxHex) {
                        dispatch(
                            transferStatusUpdateItem({
                                hash: fromHash,
                                payload: {
                                    toHash: response?.relayTxHex,
                                },
                            }),
                        );
                    }

                    if (isComplete) {
                        mixpanelEvents.tokenBridgedSuccess({
                            from,
                            to: to as Networks,
                            tokenEnum: token as string,
                            amount: amount as string,
                            expectedTime: timeWait as unknown as string,
                            actualTime: ((new Date().getTime() -
                                Number(createdAt)) /
                                60 /
                                1000) as unknown as string,
                        });

                        if (aptosWallet.poolBalance) {
                            aptosWallet.poolBalance();
                        }

                        if (evmWallet.poolBalance) {
                            evmWallet.poolBalance();
                        }

                        if (suiWallet.poolBalance) {
                            suiWallet.poolBalance();
                        }

                        clearInterval(transferStatusIntervals[fromHash]);
                    }
                } catch {}
            }, 10000);

            return () => {
                if (transferStatusIntervals[fromHash]) {
                    clearInterval(transferStatusIntervals[fromHash]);
                    transferStatusIntervals[fromHash] = null;
                }
            };
        }
    }, []);

    const transferClickCallback = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();

            navigate('?modal=transferStatus', {
                state: {
                    hash: fromHash,
                },
            });
        },
        [fromHash, navigate],
    );

    const fromLink = useMemo(
        () =>
            fromHash ? (from ? linkFromNetwork[from](fromHash) : null) : null,
        [fromHash, from],
    );

    const toLink = useMemo(
        () => (toHash ? (to ? linkFromNetwork[to](toHash) : null) : null),
        [toHash, to],
    );

    const linkClickCallback = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            e.stopPropagation();

            if (fromLink) {
                window.open(fromLink, '_blank');
            }

            if (toLink) {
                window.open(toLink, '_blank');
            }
        },
        [toLink, fromLink],
    );

    const transferStatusTimerContent = useMemo(() => {
        if (isComplete) {
            return 'Successful';
        }

        if (isFailed) {
            return 'Pending...';
        }

        if (isTimerExpired) {
            return 'Completing...';
        }

        return timer;
    }, [isComplete, timer, isTimerExpired, isFailed]);

    return (
        <div
            onClick={transferClickCallback}
            className={CnTransferStatusItem({
                complete: isComplete,
            })}
        >
            <div className={CnTransferStatusItem('header')}>
                <div className={CnTransferStatusItem('title')}>
                    Transferring{' '}
                    <b>
                        {amount} {token}
                    </b>
                    <br /> from{' '}
                    <b className={CnTransferStatusItem('network')}>
                        {labelFromNetwork[from as Networks]}
                    </b>{' '}
                    to{' '}
                    <b className={CnTransferStatusItem('network')}>
                        {labelFromNetwork[to as Networks]}
                    </b>
                </div>

                <div className={CnTransferStatusItem('descr')}>
                    <div
                        onClick={linkClickCallback}
                        className={CnTransferStatusItem('link')}
                    >
                        View Transaction <Icons.Link />
                    </div>

                    <div className={CnTransferStatusItem('timer')}>
                        {transferStatusTimerContent}
                    </div>
                </div>
            </div>

            {progressContent}
        </div>
    );
};
