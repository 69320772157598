import { cn } from '@bem-react/classname';
import React from 'react';
import { navItems } from './Navigation.constants';

import './Navigation.scss';
import { Link } from 'react-router-dom';

const CnNavigation = cn('navigation');

export const Navigation: React.FC = () => {
    return (
        <div className={CnNavigation()}>
            {navItems.map((item, index) => (
                <NavigationItem {...item} key={index} />
            ))}
        </div>
    );
};

interface INavigationItem {
    title: string;
    disabled: boolean;
    path: string;
}

export const NavigationItem: React.FC<INavigationItem> = ({
    title,
    disabled,
    path,
}) => {
    return (
        <Link
            to={disabled ? '#' : path}
            className={CnNavigation('item', {
                disabled,
            })}
        >
            {title}
        </Link>
    );
};
