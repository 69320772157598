export const stageConfig = {
    aptos: {
        chain: 1,
        bridge: '0xaca43f72e040cd702a30f041790e2664bcc7d2b29b7c13b21ed2d103fdfe7bfb',
        usdc: {
            bridge: '0xaca43f72e040cd702a30f041790e2664bcc7d2b29b7c13b21ed2d103fdfe7bfb',
            get unique() {
                return `${this.token}:USDC`;
            },
            get token() {
                return `0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC`;
            },
            get aptosType() {
                return `0x1::coin::CoinStore<${this.token}>`;
            },
            symbol: 'USDC',
            decimals: 6,
            chain: 1,
        },
        usdt: {
            bridge: '0xaca43f72e040cd702a30f041790e2664bcc7d2b29b7c13b21ed2d103fdfe7bfb',
            get unique() {
                return `${this.token}:USDT`;
            },
            get token() {
                return `0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT`;
            },
            get aptosType() {
                return `0x1::coin::CoinStore<${this.token}>`;
            },
            symbol: 'USDT',
            decimals: 6,
            chain: 1,
        },
    },
    sui: {
        chain: 11,
        suiChain: 'sui:mainnet',
        chain_types: {
            aptos: '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::Aptos',
            arbitrum:
                '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::Arbitrum',
            bsc: '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::BNB',
            polygon:
                '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::Polygon',
            optimism:
                '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::Optimism',
            zkpolygon:
                '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::ZkPolygon',
            zksync: '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::ZkSync',
            bobaeth:
                '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::BobaETH',
            bobabnb:
                '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b::chains::BobaBNB',
        },
        settings: {
            arbitrum:
                '0xde37e6834179b3295c879bbac373f0488c19b4b061b103e6ee9a353e6f7ceb77',
            bsc: '0x967e14f8c88ca4251e9f3be14c5a8aa6b9448cb43df77a6293f16650e1184656',
            polygon:
                '0x811f8bf79ccc8cedaa46544515d8575822093aeb3938709a8b53f0ac8bd1127b',
            optimism:
                '0xc2e46fa95825e715f819b32a1905007bc4464b0c793ef3386913fd6b52bde05b',
            zkpolygon:
                '0xafc7572385736d5d1b2a2f8dacf4a82f9336d1ab33b5cd864b70fa7767dc9003',
            aptos: '0x47bf0905fb667aa0a7941ab596539473e8cfba3bcb3113235410966f9adc0b84',
            zksync: '0x75a8a73115ab57ee0e1fa5d7ef9da8244af2ed5446395b57d6ad160fb70128fc',
            bobaeth:
                '0xc474eb5f33092de7a776cab7f30e3c5bd5f11b0bef2648889ff6ebb07aaf7688',
            bobabnb:
                '0x067d098884574bce2ea799775c836aaeb87264c7ed13aa784a2370980715fc95',
        },
        usdc: {
            bridge: '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b',
            get unique() {
                return `${this.token}:USDC`;
            },
            get token() {
                return `0x32d5056aaac1be1f9ae44c1d0728792255a05f1475d458287ee463cc26806ca0::mover_usd::MOVER_USD`;
            },
            liquidityPool:
                '0x171e72e0118b7c4e23955d77cb003c0cdad467b0a097a6024d417482540b37ad',
            metadata:
                '0xe3a61dee328b1cbbd2495c073bdde683728715167510c283b0bcfd3d070045b8',
            symbol: 'moverUSD',
            decimals: 6,
            chain: 11,
            suiChain: 'sui:mainnet',
            function: 'swap_out_native',
            class: 'bridge',
        },
        usdt: {
            bridge: '0x34bfc0533f41e3412c55c7fe9947bb8097106b9cf2d58bfe95b114c028d7df2b',
            get unique() {
                return `${this.token}:USDT`;
            },
            get token() {
                return `0x32d5056aaac1be1f9ae44c1d0728792255a05f1475d458287ee463cc26806ca0::mover_usd::MOVER_USD`;
            },
            liquidityPool:
                '0x171e72e0118b7c4e23955d77cb003c0cdad467b0a097a6024d417482540b37ad',
            metadata:
                '0xe3a61dee328b1cbbd2495c073bdde683728715167510c283b0bcfd3d070045b8',
            symbol: 'moverUSD',
            decimals: 6,
            chain: 11,
            suiChain: 'sui:mainnet',
            function: 'swap_out_native',
            class: 'bridge',
        },
    },
    arbitrum: {
        chain: 42161,
        usdc: {
            token: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
            unique: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8:usdc',
            bridge: '0xAb35c61673DAF80fE82dcf0792128ae68C2B121d',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 42161,
        },
        usdt: {
            token: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
            unique: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9:usdt',
            bridge: '0xAb35c61673DAF80fE82dcf0792128ae68C2B121d',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 42161,
        },
    },
    bsc: {
        chain: 56,
        usdc: {
            token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            unique: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d:usdc',
            bridge: '0xc4f0381D1B1a7C0e8B18caC258d3860Fc3f5a60B',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 56,
        },
        usdt: {
            token: '0x55d398326f99059fF775485246999027B3197955',
            unique: '0x55d398326f99059fF775485246999027B3197955:usdt',
            bridge: '0xc4f0381D1B1a7C0e8B18caC258d3860Fc3f5a60B',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 56,
        },
    },
    polygon: {
        chain: 137,
        usdc: {
            token: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            unique: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 137,
        },
        usdt: {
            token: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
            unique: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 137,
        },
    },
    optimism: {
        chain: 10,
        usdc: {
            token: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
            unique: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 10,
        },
        usdt: {
            token: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
            unique: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 10,
        },
    },
    zksync: {
        chain: 324,
        usdc: {
            token: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
            unique: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4:usdc',
            bridge: '0x084e8866A1b3F8AFeb8957FEB452d25d42374C7A',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 324,
        },
        usdt: {
            token: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
            unique: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C:usdt',
            bridge: '0x084e8866A1b3F8AFeb8957FEB452d25d42374C7A',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 324,
        },
    },
    zkpolygon: {
        chain: 1101,
        usdc: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdc',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 1101,
        },
        usdt: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdt',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 1101,
        },
    },
    bobaeth: {
        chain: 288,
        usdc: {
            token: '0xfE3c9C5376A79fA4a016614eED8AbCd159655FEC',
            unique: '0xfE3c9C5376A79fA4a016614eED8AbCd159655FEC:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 288,
        },
        usdt: {
            token: '0xb3A28bf91218dBeaee6b1Cd915Cb7438733F8643',
            unique: '0xb3A28bf91218dBeaee6b1Cd915Cb7438733F8643:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 288,
        },
    },
    bobabnb: {
        chain: 56288,
        usdc: {
            token: '0x9767Fd19212Fae714b1d14a98C4F084b0f264042',
            unique: '0x9767Fd19212Fae714b1d14a98C4F084b0f264042:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 56288,
        },
        usdt: {
            token: '0x7cb101ed05f57Ca2008575dD829E97f4F0Ba1e5c',
            unique: '0x7cb101ed05f57Ca2008575dD829E97f4F0Ba1e5c:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 56288,
        },
    },
};
