import { ReactComponent as LogoIcon } from './icons/logo.svg';
import { ReactComponent as GearIcon } from './icons/gear.svg';
import { ReactComponent as AngleIcon } from './icons/angle.svg';
import { ReactComponent as AngleBlackIcon } from './icons/angle-black.svg';
import { ReactComponent as CheckIcon } from './icons/check.svg';
import { ReactComponent as CheckGreenIcon } from './icons/check-green.svg';
import { ReactComponent as InfoIcon } from './icons/info.svg';
import { ReactComponent as ErrorIcon } from './icons/error.svg';
import { ReactComponent as WarningIcon } from './icons/warning.svg';
import { ReactComponent as CloseBlackIcon } from './icons/close-black.svg';
import { ReactComponent as ChevronIcon } from './icons/chevron.svg';
import { ReactComponent as CopyIcon } from './icons/copy.svg';
import { ReactComponent as TurnOffIcon } from './icons/turn-off.svg';
import { ReactComponent as PlusIcon } from './icons/plus.svg';
import { ReactComponent as SuccessIcon } from './icons/success.svg';
import { ReactComponent as MenuIcon } from './icons/menu.svg';
import { ReactComponent as ArrowRightLongIcon } from './icons/arrow-right-long.svg';
import { ReactComponent as LoaderIcon } from './icons/loader.svg';
import { ReactComponent as LoaderFixedIcon } from './icons/loader-fixed.svg';
import { ReactComponent as LinkIcon } from './icons/link.svg';
import { ReactComponent as LimitWarningIcon } from './icons/limit-warning.svg';
import { ReactComponent as AngleRightIcon } from './icons/angle-right.svg';
import { ReactComponent as ArrowRightIcon } from './icons/arrow-right.svg';
import { ReactComponent as AngleDownIcon } from './icons/angle-down.svg';
import { ReactComponent as MoverIcon } from './icons/mover.svg';

import { ReactComponent as AptosIcon } from './networks/aptos.svg';
import { ReactComponent as EthereumNetworkIcon } from './networks/ethereum.svg';
import { ReactComponent as PolygonNetworkIcon } from './networks/polygon.svg';
import { ReactComponent as BscNetworkIcon } from './networks/bsc.svg';
import { ReactComponent as OptimismNetworkIcon } from './networks/optimism.svg';
import { ReactComponent as PolygonZkEvmIcon } from './networks/polygonzk.svg';
import { ReactComponent as BobaEthIcon } from './networks/boba-eth.svg';
import { ReactComponent as BobaBnbIcon } from './networks/boba-bnb.svg';
import { ReactComponent as SuiIcon } from './networks/sui.svg';
import { ReactComponent as ArbitrumIcon } from './networks/arbitrum.svg';

import zkSync from './networks/zksync.png';

import { ReactComponent as UsdcIcon } from './tokens/usdc.svg';
import { ReactComponent as UsdtIcon } from './tokens/usdt.svg';
import { ReactComponent as EthIcon } from './tokens/eth.svg';
import { ReactComponent as BtcIcon } from './tokens/btc.svg';
import { ReactComponent as WbtcIcon } from './tokens/wbtc.svg';

import { ReactComponent as MetamaskIcon } from './wallets/metamask.svg';
import { ReactComponent as CoinbaseIcon } from './wallets/coinbase.svg';
import { ReactComponent as FewchaIcon } from './wallets/fewcha.svg';
import { ReactComponent as MartianAltIcon } from './wallets/martian-alt.svg';
import { ReactComponent as MartianIcon } from './wallets/martian.svg';
import { ReactComponent as NightlyIcon } from './wallets/nightly.svg';
import { ReactComponent as PontemIcon } from './wallets/pontem.svg';
import { ReactComponent as RiseIcon } from './wallets/rise.svg';
import { ReactComponent as RiseBlackIcon } from './wallets/rise-black.svg';
import { ReactComponent as WalletConnectIcon } from './wallets/wallet-connect.svg';
import { ReactComponent as TrustWalletIcon } from './wallets/trustwallet.svg';

import petra from './wallets/petra.png';
import martian from './wallets/martian.jpg';
import okx from './wallets/okx.png';

import moverMaintenance from './maintenance.png';

import { ReactComponent as TwitterIcon } from './socials/twitter.svg';
import { ReactComponent as GithubIcon } from './socials/github.svg';
import { ReactComponent as MediumIcon } from './socials/medium.svg';
import { ReactComponent as DiscordIcon } from './socials/discord.svg';

const ZkSyncIcon: React.FC = () => {
    return <img src={zkSync} alt="zkSync" />;
};

const MoverMaintenance: React.FC = () => {
    return <img src={moverMaintenance} alt="moverMaintenance" />;
};

const PetraIcon: React.FC = () => {
    return <img src={petra} alt="petra" />;
};

const MartianCircleIcon: React.FC = () => {
    return <img src={martian} alt="martian" />;
};

const OkxIcon: React.FC = () => {
    return <img src={okx} alt="OKX" />;
};

export const Icons = {
    Logo: LogoIcon,
    Gear: GearIcon,
    Usdc: UsdcIcon,
    Usdt: UsdtIcon,
    Eth: EthIcon,
    Btc: BtcIcon,
    Wbtc: WbtcIcon,
    Angle: AngleIcon,
    Check: CheckIcon,
    Info: InfoIcon,
    AngleBlack: AngleBlackIcon,
    Aptos: AptosIcon,
    EthereumNetwork: EthereumNetworkIcon,
    Error: ErrorIcon,
    Warning: WarningIcon,
    CloseBlack: CloseBlackIcon,
    Metamask: MetamaskIcon,
    Coinbase: CoinbaseIcon,
    Fewcha: FewchaIcon,
    MartianAlt: MartianAltIcon,
    Martian: MartianIcon,
    Nightly: NightlyIcon,
    Pontem: PontemIcon,
    Rise: RiseIcon,
    Petra: PetraIcon,
    Chevron: ChevronIcon,
    Copy: CopyIcon,
    TurnOff: TurnOffIcon,
    Plus: PlusIcon,
    CheckGreen: CheckGreenIcon,
    Success: SuccessIcon,
    Menu: MenuIcon,
    Twitter: TwitterIcon,
    Discord: DiscordIcon,
    Github: GithubIcon,
    Medium: MediumIcon,
    MartianCircle: MartianCircleIcon,
    RiseBlack: RiseBlackIcon,
    Loader: LoaderIcon,
    ArrowRightLong: ArrowRightLongIcon,
    LoaderFixed: LoaderFixedIcon,
    Arbitrum: ArbitrumIcon,
    Link: LinkIcon,
    LimitWarning: LimitWarningIcon,
    MoverMaintenance: MoverMaintenance,
    PolygonNetwork: PolygonNetworkIcon,
    BscNetwork: BscNetworkIcon,
    OptimismNetwork: OptimismNetworkIcon,
    AngleRight: AngleRightIcon,
    ArrowRight: ArrowRightIcon,
    AngleDown: AngleDownIcon,
    ZkSync: ZkSyncIcon,
    PolygonZkEvm: PolygonZkEvmIcon,
    BobaEth: BobaEthIcon,
    BobaBnb: BobaBnbIcon,
    WalletConnect: WalletConnectIcon,
    Sui: SuiIcon,
    Okx: OkxIcon,
    TrustWallet: TrustWalletIcon,
    Mover: MoverIcon,
};
