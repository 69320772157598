import React, { useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { cn } from '@bem-react/classname';
import { Networks } from 'types';

import './NetworkItem.css';
import { useEvmWalletContext } from 'hooks';

const CnNetworkItem = cn('networkItem');

export const NetworkItem: React.FC<{
    icon: any;
    name: string;
    selected?: boolean;
    network: Networks;
    chainId: number;
}> = ({ icon, name, chainId }) => {
    const { switchNetwork, chainId: currChainId } = useEvmWalletContext();
    const selected = useMemo(
        () => chainId === currChainId,
        [chainId, currChainId],
    );

    const networkClickCallback = useCallback(async () => {
        if (switchNetwork) {
            await switchNetwork(chainId);
        }
    }, [switchNetwork, chainId]);

    return (
        <div
            onClick={networkClickCallback}
            className={CnNetworkItem({ selected })}
        >
            <div className={CnNetworkItem('icon')}>{icon}</div>

            <div className={CnNetworkItem('text')}>{name}</div>
        </div>
    );
};
