import { EvmChains, Networks } from 'types';
import { Icons } from 'assets';

export const evmNetworks = [
    {
        chainId: EvmChains.Arbitrum,
        name: 'Arbitrum',
        network: Networks.Arbitrum,
        icon: <Icons.Arbitrum />,
    },
    {
        chainId: EvmChains.Bsc,
        name: 'BNB',
        network: Networks.Bsc,
        icon: <Icons.BscNetwork />,
    },
    {
        chainId: EvmChains.Optimism,
        name: 'Optimism',
        network: Networks.Optimism,
        icon: <Icons.OptimismNetwork />,
    },
    {
        chainId: EvmChains.Polygon,
        name: 'Polygon',
        network: Networks.Polygon,
        icon: <Icons.PolygonNetwork />,
    },
    {
        chainId: EvmChains.ZkSync,
        name: 'zkSync Era',
        network: Networks.ZkSync,
        icon: <Icons.ZkSync />,
    },
    {
        chainId: EvmChains.ZkPolygon,
        name: 'Polygon zkEVM',
        network: Networks.ZkPolygon,
        icon: <Icons.PolygonZkEvm />,
    },
    // {
    //     chainId: EvmChains.BobaEth,
    //     name: 'Boba Ethereum',
    //     network: Networks.BobaEth,
    //     icon: <Icons.BobaEth />,
    // },
    // {
    //     chainId: EvmChains.BobaBnb,
    //     name: 'Boba BNB',
    //     network: Networks.BobaBnb,
    //     icon: <Icons.BobaBnb />,
    // },
];
