import { createAction } from '@reduxjs/toolkit';
import { FetchStatus, Networks } from 'types';

export const setTransferFrom = createAction<Networks>(
    '@transferForm/setTransferFrom',
);

export const setTransferTo = createAction<Networks>(
    '@transferForm/setTransferTo',
);

export const setTransferAmount = createAction<string>(
    '@transferForm/setTransferAmount',
);

export const setTransferFees = createAction<{
    fees: string;
    feesToShow: string;
    amountToReceive: string;
    baseFee: string;
    baseFeeToShow: string;
}>('@transferForm/setFees');

export const setTransferFetchStatus = createAction<FetchStatus>(
    '@transferForm/setTransferFetchStatus',
);

export const clearTransferForm = createAction('@transferForm/clear');
