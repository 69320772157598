import React from 'react';

import { WagmiConfig } from 'wagmi';
import { EvmWalletProviderContext } from './EvmWalletContext';
import { evmClient } from './EvmWalletProvider.constants';

interface IEvmWalletProviderProps {
    children: React.ReactNode;
}

export const EvmWalletProvider: React.FC<IEvmWalletProviderProps> = ({
    children,
}) => {
    return (
        <WagmiConfig client={evmClient}>
            <EvmWalletProviderContext>{children}</EvmWalletProviderContext>
        </WagmiConfig>
    );
};
