export const prodConfig = {
    aptos: {
        chain: 1,
        bridge: '0xb3db6a8618db6e0eb9f2a3c98f693a7e622f986377c3153e6dd602ca74984ef1',
        usdc: {
            bridge: '0xb3db6a8618db6e0eb9f2a3c98f693a7e622f986377c3153e6dd602ca74984ef1',
            get unique() {
                return `${this.token}:USDC`;
            },
            get token() {
                return `0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDC`;
            },
            get aptosType() {
                return `0x1::coin::CoinStore<${this.token}>`;
            },
            symbol: 'USDC',
            decimals: 6,
            chain: 1,
        },
        usdt: {
            bridge: '0xb3db6a8618db6e0eb9f2a3c98f693a7e622f986377c3153e6dd602ca74984ef1',
            get unique() {
                return `${this.token}:USDT`;
            },
            get token() {
                return `0xf22bede237a07e121b56d91a491eb7bcdfd1f5907926a9e58338f964a01b17fa::asset::USDT`;
            },
            get aptosType() {
                return `0x1::coin::CoinStore<${this.token}>`;
            },
            symbol: 'USDT',
            decimals: 6,
            chain: 1,
        },
    },
    sui: {
        chain: 11,
        suiChain: 'sui:mainnet',
        chain_types: {
            aptos: '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::Aptos',
            arbitrum:
                '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::Arbitrum',
            bsc: '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::BNB',
            polygon:
                '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::Polygon',
            optimism:
                '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::Optimism',
            zkpolygon:
                '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::ZkPolygon',
            zksync: '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::ZkSync',
            bobaeth:
                '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348::chains::Boba',
        },
        settings: {
            arbitrum:
                '0x562e67adb7adbe813b8853fc5edcc80eecea0801d62d018d2c079eb35da0317c',
            bsc: '0x8b8f88d68a329aec265761c46ca0507ec98ddb45375831423f1b6f39dff4e9e2',
            polygon:
                '0xf71ba117ad735920e3c7d57c2d416077f9ad6ab76e2c97dcea5a71264ac48f3b',
            optimism:
                '0x7b7843ece2c3fbec528ce7199bb9d8895915dd40fd02bfafbe6636c6969be902',
            zkpolygon:
                '0x47e9340679002ad7bed0badf66782d5c6fd82597c6a3aa0153cb02f791d2cbec',
            aptos: '0x62c9128cdb9798f5ff62f405771219fdae72084aad07cfbe2c27d88ae0f14b62',
            zksync: '0x230693666c8fc7064ab05471632971aa838803b30f809801f97464cb11649a1e',
            bobaeth:
                '0x677340cb6171a91f057fb57cfc8c5babcc58c7cb53062547cd57f1bbee219a97',
            bobabnb:
                '0x0979215c7e67b1d20c1bd389f3b2fa1ce687f0b68bb2c10077db5fada75c5353',
        },
        usdc: {
            bridge: '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348',
            get unique() {
                return `${this.token}:moverUSD`;
            },
            get token() {
                return `0x32d5056aaac1be1f9ae44c1d0728792255a05f1475d458287ee463cc26806ca0::mover_usd::MOVER_USD`;
            },
            liquidityPool:
                '0x45183f4c452adbfd5c10b91e961164aa6cea9a109e0e6252c8e5c777dce4f273',
            metadata:
                '0xe3a61dee328b1cbbd2495c073bdde683728715167510c283b0bcfd3d070045b8',
            symbol: 'moverUSD',
            decimals: 6,
            chain: 11,
            suiChain: 'sui:mainnet',
            function: 'swap_out_native',
            class: 'bridge',
        },
        usdt: {
            bridge: '0x6d9f9a7be0c37eda7542706bd0c950fd073b3c8b18b01c6414597a9df4f73348',
            get unique() {
                return `${this.token}:moverUSD`;
            },
            get token() {
                return `0x32d5056aaac1be1f9ae44c1d0728792255a05f1475d458287ee463cc26806ca0::mover_usd::MOVER_USD`;
            },
            liquidityPool:
                '0x45183f4c452adbfd5c10b91e961164aa6cea9a109e0e6252c8e5c777dce4f273',
            metadata:
                '0xe3a61dee328b1cbbd2495c073bdde683728715167510c283b0bcfd3d070045b8',
            symbol: 'moverUSD',
            decimals: 6,
            chain: 11,
            suiChain: 'sui:mainnet',
            function: 'swap_out_native',
            class: 'bridge',
        },
    },
    arbitrum: {
        chain: 42161,
        usdc: {
            token: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8',
            unique: '0xFF970A61A04b1cA14834A43f5dE4533eBDDB5CC8:usdc',
            bridge: '0x2Dd4451F91d8B0b7DE7a7eE4b2f2827776E9FDd8',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 42161,
        },
        usdt: {
            token: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9',
            unique: '0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9:usdt',
            bridge: '0x2Dd4451F91d8B0b7DE7a7eE4b2f2827776E9FDd8',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 42161,
        },
    },
    bsc: {
        chain: 56,
        usdc: {
            token: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
            unique: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d:usdc',
            bridge: '0xd0F3F26F4B10a1FFA670c8EAb36261E143f1C337',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 56,
        },
        usdt: {
            token: '0x55d398326f99059fF775485246999027B3197955',
            unique: '0x55d398326f99059fF775485246999027B3197955:usdt',
            bridge: '0xd0F3F26F4B10a1FFA670c8EAb36261E143f1C337',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 56,
        },
    },
    polygon: {
        chain: 137,
        usdc: {
            token: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174',
            unique: '0x2791Bca1f2de4661ED88A30C99A7a9449Aa84174:usdc',
            bridge: '0x558901fBe000821DFfb91Bb6678bA1E14a7a2aCD',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 137,
        },
        usdt: {
            token: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F',
            unique: '0xc2132D05D31c914a87C6611C10748AEb04B58e8F:usdt',
            bridge: '0x558901fBe000821DFfb91Bb6678bA1E14a7a2aCD',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 137,
        },
    },
    optimism: {
        chain: 10,
        usdc: {
            token: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607',
            unique: '0x7F5c764cBc14f9669B88837ca1490cCa17c31607:usdc',
            bridge: '0x3c9b7ED55Af8634E160893282D4CBeaf86930F28',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 10,
        },
        usdt: {
            token: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58',
            unique: '0x94b008aA00579c1307B0EF2c499aD98a8ce58e58:usdt',
            bridge: '0x3c9b7ED55Af8634E160893282D4CBeaf86930F28',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 10,
        },
    },
    zksync: {
        chain: 324,
        usdc: {
            token: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4',
            unique: '0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4:usdc',
            bridge: '0x94bdB89Fd28c346700F058bE2035dd53369F1Fe6',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 324,
        },
        usdt: {
            token: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C',
            unique: '0x493257fD37EDB34451f62EDf8D2a0C418852bA4C:usdt',
            bridge: '0x94bdB89Fd28c346700F058bE2035dd53369F1Fe6',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 324,
        },
    },
    zkpolygon: {
        chain: 1101,
        usdc: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdc',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 1101,
        },
        usdt: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdt',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 1101,
        },
    },
    bobaeth: {
        chain: 288,
        usdc: {
            token: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61',
            unique: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61:usdc',
            bridge: '0xb9DB5174b661d2310Dc9458AC2146eC2074DAa97',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 288,
        },
        usdt: {
            token: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61',
            unique: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61:usdt',
            bridge: '0xb9DB5174b661d2310Dc9458AC2146eC2074DAa97',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 288,
        },
    },
    bobabnb: {
        chain: 56288,
        usdc: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 56288,
        },
        usdt: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 56288,
        },
    },
};
