import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { FC, useCallback } from 'react';

import './SpecialOffer.scss';
import { useNavigate } from 'react-router';

const CnSpecialOffer = cn('specialOffer');

export const SpecialOffer: FC = () => {
    const navigate = useNavigate();

    const specialOfferClickCallback = useCallback(() => {
        navigate('?modal=specialOffer');
    }, [navigate]);

    return (
        <div onClick={specialOfferClickCallback} className={CnSpecialOffer()}>
            <div className={CnSpecialOffer('text')}>
                Pay less by staking $MOVER
            </div>{' '}
            <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M9.33331 16H22.6666"
                    stroke="url(#paint0_linear_5365_8747)"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M17.3333 10.6667L22.6666 16.0001L17.3333 21.3334"
                    stroke="url(#paint1_linear_5365_8747)"
                    stroke-width="1.75"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_5365_8747"
                        x1="9.33331"
                        y1="16.5"
                        x2="22.6666"
                        y2="16.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#59FFEB" />
                        <stop offset="1" stop-color="#14C7FF" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_5365_8747"
                        x1="17.3333"
                        y1="16.0001"
                        x2="22.6666"
                        y2="16.0001"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#59FFEB" />
                        <stop offset="1" stop-color="#14C7FF" />
                    </linearGradient>
                </defs>
            </svg>
        </div>
    );
};
