import { cn } from '@bem-react/classname';
import { FC, useCallback, useState } from 'react';
import { PoolForm, PoolList } from './components';
import { pools } from './Pool.constants';

import './Pool.scss';

const CnPool = cn('pool');

export interface IPool {
    apy: number;
}

export const Pool: FC = () => {
    const [selectedPool, setSelectedPool] = useState<any>(null);

    const changeSelectedPool = useCallback(
        (pool: any) => setSelectedPool(pool),
        [],
    );

    return (
        <div className={CnPool()}>
            {!selectedPool ? (
                <PoolList poolClickHandler={changeSelectedPool} pools={pools} />
            ) : (
                <div className={CnPool('form')}>
                    <div
                        onClick={() => setSelectedPool(null)}
                        className={CnPool('back')}
                    >
                        <svg
                            width="24"
                            height="25"
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M14 16.5L10 12.5L14 8.5"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                        Back
                    </div>
                    <PoolForm pool={selectedPool} />
                </div>
            )}
        </div>
    );
};
