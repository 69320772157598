export const devConfig = {
    aptos: {
        chain: 2,
        bridge: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77',
        usdc: {
            bridge: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77',
            get unique() {
                return `${this.token}:USDC`;
            },
            get token() {
                return `0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDC`;
            },
            get aptosType() {
                return `0x1::coin::CoinStore<${this.token}>`;
            },
            symbol: 'USDC',
            decimals: 6,
            chain: 2,
        },
        usdt: {
            bridge: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77',
            get unique() {
                return `${this.token}:USDT`;
            },
            get token() {
                return `0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDT`;
            },
            get aptosType() {
                return `0x1::coin::CoinStore<${this.token}>`;
            },
            symbol: 'USDT',
            decimals: 6,
            chain: 2,
        },
    },
    sui: {
        chain: 22,
        suiChain: 'sui:testnet',
        chain_types: {
            aptos: '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::Aptos',
            arbitrum:
                '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::Arbitrum',
            bsc: '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::BNB',
            polygon:
                '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::Polygon',
            optimism:
                '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::Optimism',
            zkpolygon:
                '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::ZkPolygon',
            zksync: '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::ZkSync',
            bobaeth:
                '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6::chains::Boba',
        },
        settings: {
            arbitrum:
                '0xe3201136c136b8dafb0622ff579d67c47d04f3b1d584c09442992247ab063ebc',
            bsc: '0x14033afd200436c3396a9517b6058ee43c8fbc260440b4c7aef1a915c21978a2',
            polygon:
                '0x3b7a1c078459fbb524c12d8a257910a8538aa3a9b9dad33158ee6cf5dcdc33ab',
            optimism:
                '0x0d1fb44c4a671c4b05b25ea98417a86dade0f5066fcd16a06f8389cdca9972e5',
            zkpolygon:
                '0x5482ad6e0f520c1120c06cd08540e44c2e02d9a9e560492a4e299e1940693633',
            aptos: '0x57ddba9afa4fabe26a2b4e0dcc26f116270414af1b1624e6b075245aacc5c797',
            zksync: '0x7007aa372fb07cc1760cb0627199b5bc757e2f3bfccb2ebf1e55599b95cc6e12',
            bobaeth:
                '0xeb79c9fe7d62ca044d08459a0db948953c600085e691289d9a1c3b11b4ef0ddb',
            bobabnb:
                '0x6b2b7bddb977a279a640c686aa70bf89b868ff5fa958801b4146fa838001f077',
        },
        usdc: {
            bridge: '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6',
            get unique() {
                return `${this.token}:USDC`;
            },
            get token() {
                return `0x4e094bd9e3a80ce6c14f720654b81477d8356b175626150a134edb1ee9fb1c22::mover_usd::MOVER_USD`;
            },
            liquidityPool:
                '0xc6e34d34d8fdd8f370b5adbc5231bd4e25aefb1c6efd808adb7eec34dc55bf81',
            metadata:
                '0xc2a9e8f0f5c91afe30666a616c6deb7fbccc0d4d59eaf6de1ffdac37f81f70f1',
            symbol: 'moverUSD',
            decimals: 6,
            suiChain: 'sui:testnet',
            chain: 22,
            function: 'swap_out_native',
            class: 'bridge',
        },
        usdt: {
            bridge: '0xd69f1cf17db53c8aa72426be97954f9dbca7fbd574dc096b09c87134371b85e6',
            get unique() {
                return `${this.token}:USDT`;
            },
            get token() {
                return `0x4e094bd9e3a80ce6c14f720654b81477d8356b175626150a134edb1ee9fb1c22::mover_usd::MOVER_USD`;
            },
            liquidityPool:
                '0xc6e34d34d8fdd8f370b5adbc5231bd4e25aefb1c6efd808adb7eec34dc55bf81',
            metadata:
                '0xc2a9e8f0f5c91afe30666a616c6deb7fbccc0d4d59eaf6de1ffdac37f81f70f1',
            symbol: 'moverUSD',
            decimals: 6,
            suiChain: 'sui:testnet',
            chain: 22,
            function: 'swap_out_native',
            class: 'bridge',
        },
    },
    arbitrum: {
        chain: 421613,
        usdc: {
            token: '0x8FB1E3fC51F3b789dED7557E680551d93Ea9d892',
            unique: '0x8FB1E3fC51F3b789dED7557E680551d93Ea9d892:usdc',
            bridge: '0x56772eeb23cfc10d117fd082940da316167189ef',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 421613,
        },
        usdt: {
            token: '0x879DBebD8FbC9794dD6c60f7EEcE89aF77Be58eb',
            unique: '0x879DBebD8FbC9794dD6c60f7EEcE89aF77Be58eb:usdt',
            bridge: '0x56772eeb23cfc10d117fd082940da316167189ef',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 421613,
        },
    },
    bsc: {
        chain: 97,
        usdc: {
            token: '0x64544969ed7EBf5f083679233325356EbE738930',
            unique: '0x64544969ed7EBf5f083679233325356EbE738930:usdc',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 97,
        },
        usdt: {
            token: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd',
            unique: '0x337610d27c682E347C9cD60BD4b3b107C9d34dDd:usdt',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 97,
        },
    },
    polygon: {
        chain: 80001,
        usdc: {
            token: '0xE097d6B3100777DC31B34dC2c58fB524C2e76921',
            unique: '0xE097d6B3100777DC31B34dC2c58fB524C2e76921:usdc',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 80001,
        },
        usdt: {
            token: '0xda83a1471ed96392ef03211afd4e24b9852684f3',
            unique: '0xda83a1471ed96392ef03211afd4e24b9852684f3:usdt',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 80001,
        },
    },
    optimism: {
        chain: 420,
        usdc: {
            token: '0x683d9714283661e6B87BfCFF792845C92999a974',
            unique: '0x683d9714283661e6B87BfCFF792845C92999a974:usdc',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 420,
        },
        usdt: {
            token: '0x66469d9c9137b52D4efA75A6122DacAF35922136',
            unique: '0x66469d9c9137b52D4efA75A6122DacAF35922136:usdt',
            bridge: '0x725C269c8303C2AA48C66041691eA5c3a542b0D2',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 420,
        },
    },
    zksync: {
        chain: 280,
        usdc: {
            token: '0x0faf6df7054946141266420b43783387a78d82a9',
            unique: '0x0faf6df7054946141266420b43783387a78d82a9:usdc',
            bridge: '0xBe7809F97667e6F0246321072C07ef72e80281F7',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 280,
        },
        usdt: {
            token: '0x3e7676937a7e96cfb7616f255b9ad9ff47363d4b',
            unique: '0x3e7676937a7e96cfb7616f255b9ad9ff47363d4b:usdt',
            bridge: '0xBe7809F97667e6F0246321072C07ef72e80281F7',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 18,
            chain: 280,
        },
    },
    zkpolygon: {
        chain: 1442,
        usdc: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 1442,
        },
        usdt: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 1442,
        },
    },
    bobaeth: {
        chain: 2888,
        usdc: {
            token: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61',
            unique: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 2888,
        },
        usdt: {
            token: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61',
            unique: '0x49E2e8709b1460ec667F9F81e6F822BBA68E4A61:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 2888,
        },
    },
    bobabnb: {
        chain: 9728,
        usdc: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdc',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDC',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 9728,
        },
        usdt: {
            token: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f',
            unique: '0xd8840e704E8CbD83FADF7FcCCff61DC4B5C3660f:usdt',
            bridge: '0xc5E00B4d75550f867b58e36f67a369f9EC9A77Ec',
            symbol: 'USDT',
            function: 'swapOutERC20',
            decimals: 6,
            chain: 9728,
        },
    },
};
