import React, { memo, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Input } from 'components';
import { supportedAptosNetworks, supportedEvmNetworks } from 'consts';
import {
    useAppSelector,
    useAptosWalletContext,
    useEvmWalletContext,
    useSuiWalletContext,
} from 'hooks';

const CnTransferForm = cn('transferForm');

export const TransferFormRecipient: React.FC = memo(() => {
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const suiWallet = useSuiWalletContext();

    const to = useAppSelector((store) => store.transferForm.to);

    const recipient = useMemo(() => {
        if (supportedEvmNetworks.includes(to)) {
            return evmWallet.address;
        } else if (supportedAptosNetworks.includes(to)) {
            return aptosWallet.address;
        } else {
            return suiWallet.address;
        }
    }, [to, aptosWallet, evmWallet, suiWallet]);

    return (
        <div style={{ marginTop: 24 }} className={CnTransferForm('field')}>
            <div className={CnTransferForm('label', { recipient: true })}>
                Recipient
            </div>

            <Input
                disabled
                value={recipient ?? ''}
                placeholder="Wallet address"
            />
        </div>
    );
});
