import React, { memo, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router';
import { cn } from '@bem-react/classname';
import { FetchStatus, AptosTokens, Networks } from 'types';
import { config } from 'config';
import { Icons } from 'assets';
import { useAppSelector } from 'hooks';
import { labelFromNetwork } from 'consts';

import './TransferLoaderModal.scss';

const CnTransferLoaderModal = cn('transferLoaderModal');

export const TransferLoaderModal: React.FC = memo(() => {
    const navigate = useNavigate();
    const from = useAppSelector((store) => store.transferForm.from);
    const fetchStatus = useAppSelector(
        (store) => store.transferForm.fetchStatus,
    );
    const to = useAppSelector((store) => store.transferForm.to);
    const amount = useAppSelector((store) => store.transferForm.amount);
    const selectedToken = useAppSelector((store) => store.user.selectedToken);

    const currConfig = useMemo(
        () => config[from][selectedToken],
        [from, selectedToken],
    );

    useEffect(() => {
        if (fetchStatus !== FetchStatus.FETCHING) {
            navigate('/');
        }
    }, [fetchStatus, navigate]);

    return (
        <div className={CnTransferLoaderModal()}>
            <div className={CnTransferLoaderModal('icon')}>
                <Icons.Loader />
            </div>
            <div className={CnTransferLoaderModal('title')}>
                Please sign the transaction
            </div>
            <div className={CnTransferLoaderModal('text')}>
                Transferring{' '}
                <b>
                    {amount} {currConfig.symbol}
                </b>{' '}
                from{' '}
                <b className={CnTransferLoaderModal('camel')}>
                    {labelFromNetwork[from]}
                </b>{' '}
                to{' '}
                <b className={CnTransferLoaderModal('camel')}>
                    {labelFromNetwork[to]}
                </b>
            </div>
        </div>
    );
});
