import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { Button, InputTokenSelect, TransferInput } from 'components';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Networks, Tokens, WalletTypes } from 'types';

import './PoolForm.scss';
import { useAppSelector, useAptosWalletContext, useThrottle } from 'hooks';
import { fetchAptosBalanceRequest } from 'api';
import { fetchCustomAptosBalanceRequest } from 'api/user';
import { poolConfig } from 'config';
import { useNavigate } from 'react-router';

const useBalance = ({
    address,
    token,
}: {
    address: string | null;
    token: Tokens;
}) => {
    const [balance, setBalance] = useState<null | string>(null);

    const fetchBalance = useCallback((address: string, token: Tokens) => {
        fetchAptosBalanceRequest(address, token, '').then((response) =>
            setBalance(response.value),
        );
    }, []);

    const debouncedFetchBalance = useThrottle(fetchBalance, 1000);

    useEffect(() => {
        if (address && token) {
            debouncedFetchBalance(address, token);
        }
    }, [address, token, debouncedFetchBalance]);

    return { balance, refetch: debouncedFetchBalance };
};

const useLpBalance = ({
    address,
    token,
}: {
    address: string | null;
    token: Tokens;
}) => {
    const [balance, setBalance] = useState<null | string>(null);

    const fetchBalance = useCallback((address: string, token: Tokens) => {
        fetchCustomAptosBalanceRequest(
            address,
            poolConfig.lpTokensMap[token],
        ).then((response) => setBalance(response));
    }, []);

    const debouncedFetchBalance = useThrottle(fetchBalance, 1000);

    useEffect(() => {
        if (address && token) {
            debouncedFetchBalance(address, token);
        }
    }, [address, token, debouncedFetchBalance]);

    return { balance, refetch: debouncedFetchBalance };
};

enum PoolType {
    Add = 'ADD',
    Remove = 'REMOVE',
}

const CnPoolForm = cn('poolForm');

export const PoolForm: FC<{ pool: any }> = ({ pool }) => {
    const navigate = useNavigate();
    const [poolType, setPoolType] = useState(PoolType.Add);
    const [amount, setAmount] = useState('');

    const { address, provideLiquidity, removeLiquidity } =
        useAptosWalletContext();
    const { balance, refetch } = useBalance({ address, token: pool?.token });
    const { balance: lpBalance, refetch: lpRefetch } = useLpBalance({
        address,
        token: pool?.token,
    });

    const balanceContent = useMemo(() => {
        if (!address) return null;

        return (
            <div className={CnPoolForm('label-balance')}>
                Balance: {poolType === PoolType.Add ? balance : lpBalance}{' '}
                {pool?.token.toUpperCase()}
            </div>
        );
    }, [poolType, balance, lpBalance, pool, address]);

    const amountChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const nValue = Number(e.target.value);

            if (!isNaN(nValue)) {
                if (
                    nValue >
                    Number(poolType === PoolType.Add ? balance : lpBalance)
                ) {
                    setAmount(
                        String(poolType === PoolType.Add ? balance : lpBalance),
                    );
                } else {
                    setAmount(e.target.value);
                }
            }
        },
        [balance, poolType, lpBalance],
    );

    const submit = useCallback(async () => {
        if (poolType === PoolType.Add) {
            if (provideLiquidity) {
                await provideLiquidity(amount, pool?.token);

                refetch(address, pool?.token);
                lpRefetch(address, pool?.token);
            }
        } else {
            if (removeLiquidity) {
                await removeLiquidity(amount, pool?.token);

                refetch(address, pool?.token);
                lpRefetch(address, pool?.token);
            }
        }
    }, [
        address,
        amount,
        refetch,
        lpRefetch,
        pool,
        provideLiquidity,
        removeLiquidity,
        poolType,
    ]);

    const poolFormActionContent = useMemo(() => {
        if (!address) {
            return (
                <Button
                    onClick={() =>
                        navigate('?modal=connectWallet', {
                            state: { walletType: WalletTypes.Aptos },
                        })
                    }
                    size="m"
                >
                    Connect Aptos wallet
                </Button>
            );
        }

        if (!Number(amount)) {
            return (
                <Button disabled size="m">
                    Enter amount
                </Button>
            );
        }

        return (
            <Button onClick={submit} size="m">
                {poolType === PoolType.Add ? 'Add' : 'Remove'}
            </Button>
        );
    }, [address, amount, poolType, submit, navigate]);

    return (
        <div className={CnPoolForm()}>
            <div className={CnPoolForm('head')}>
                <div className={CnPoolForm('title')}>
                    Pool {pool?.token?.toUpperCase()}
                </div>

                <div className={CnPoolForm('chain')}>
                    <div className={CnPoolForm('chain-icon')}>
                        {pool?.networkIcon}
                    </div>
                    Aptos
                </div>
            </div>
            <div className={CnPoolForm('content')}>
                <div className={CnPoolForm('tabs')}>
                    <div
                        onClick={() => setPoolType(PoolType.Add)}
                        className={CnPoolForm('tabs-item', {
                            selected: poolType === PoolType.Add,
                        })}
                    >
                        Add
                    </div>
                    <div
                        onClick={() => setPoolType(PoolType.Remove)}
                        className={CnPoolForm('tabs-item', {
                            selected: poolType === PoolType.Remove,
                        })}
                    >
                        Remove
                    </div>
                </div>
                <div className={CnPoolForm('field')}>
                    <div className={CnPoolForm('label')}>
                        <div className={CnPoolForm('label-title')}>
                            Select amount:
                        </div>
                        {balanceContent}
                    </div>

                    <TransferInput
                        leftContent={
                            <InputTokenSelect
                                selectedItem={{
                                    icon: pool?.icon,
                                    title: pool?.token.toUpperCase(),
                                    network: Networks.Aptos,
                                }}
                                disabled
                            />
                        }
                        token={'USDC'}
                        placeholder="0.0"
                        value={amount}
                        onChange={amountChangeCallback}
                    />
                </div>

                <div className={CnPoolForm('info')}>
                    <div className={CnPoolForm('info-item')}>
                        <div className={CnPoolForm('info-label')}>
                            Pooled tokens:
                        </div>
                        <div className={CnPoolForm('info-value')}>
                            {pool?.icon}
                            {lpBalance}
                        </div>
                    </div>
                    <div className={CnPoolForm('info-item')}>
                        <div className={CnPoolForm('info-label')}>
                            Expected APY
                            <Icons.Info />
                        </div>
                        <div className={CnPoolForm('info-value')}>
                            ≈ {(pool?.apy * 100).toFixed(2)}%
                        </div>
                    </div>
                </div>

                <div className={CnPoolForm('action')}>
                    {poolFormActionContent}
                </div>
            </div>
        </div>
    );
};
