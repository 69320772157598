import { JsonRpcProvider, testnetConnection, Connection } from '@mysten/sui.js';
import { environment } from 'consts';

const mainnetConnection = new Connection({
    fullnode: 'https://fullnode.mainnet.sui.io',
});

export const suiProvider = new JsonRpcProvider(
    environment === 'dev' ? testnetConnection : mainnetConnection,
);
