import React from 'react';
import { WalletKitProvider } from '@mysten/wallet-kit';
import { SuiWalletProviderContext } from './SuiWalletContext';

interface ISuiWalletProviderProps {
    children: React.ReactNode;
}

export const SuiWalletProvider: React.FC<ISuiWalletProviderProps> = ({
    children,
}) => {
    return (
        <WalletKitProvider>
            <SuiWalletProviderContext>{children}</SuiWalletProviderContext>
        </WalletKitProvider>
    );
};
