import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './StakingSuccessModal.css';

const CnStakingSuccessModal = cn('stakingSuccessModal');

export const StakingSuccessModal: React.FC = () => {
    return (
        <div className={CnStakingSuccessModal()}>
            <Icons.Success className={CnStakingSuccessModal('icon')} />

            <div className={CnStakingSuccessModal('title')}>Success!</div>
        </div>
    );
};
