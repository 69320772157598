import { fetchAptosFees } from 'api';
import { Networks, Tokens } from 'types';
import { useCallback, useEffect, useState } from 'react';
import { useThrottle } from './useThrottle';

export const useAptosRelayerFee = (
    selectedToken: Tokens,
    network: Networks,
) => {
    const [relayerFee, setRelayerFee] = useState<number | null>(null);

    const fetchAptosFeesCallback = useCallback((selectedToken: Tokens) => {
        fetchAptosFees(selectedToken).then((response) => {
            setRelayerFee(response);
        });
    }, []);

    const fetchAptosFeesThrottled = useThrottle(fetchAptosFeesCallback, 5000);

    useEffect(() => {
        if (selectedToken) {
            if (network === Networks.Aptos) {
                fetchAptosFeesThrottled(selectedToken);
            }
        }
    }, [selectedToken, network, fetchAptosFeesThrottled]);

    return relayerFee;
};
