import { config } from 'config';

export enum NetworkType {
    Sui = 'sui',
    Aptos = 'aptos',
    Evm = 'evm',
}

export enum Networks {
    Aptos = 'aptos',
    Arbitrum = 'arbitrum',
    Bsc = 'bsc',
    Polygon = 'polygon',
    Optimism = 'optimism',
    ZkSync = 'zksync',
    ZkPolygon = 'zkpolygon',
    BobaEth = 'bobaeth',
    BobaBnb = 'bobabnb',
    Sui = 'sui',
}

export enum Tokens {
    USDC = 'usdc',
    USDT = 'usdt',
}

export enum AptosTokens {
    USDC = 'usdc',
    USDT = 'usdt',
}

export enum Allowance {
    Allowed = 'Allowed',
    NotAllowed = 'NotAllowed',
}

export enum Currency {
    USD = 'usd',
}

export enum TransactionStatus {
    NEW = 'NEW',
    IN_PROGRESS = 'IN_PROGRESS',
    FAIL = 'FAIL',
    COMPLETE = 'COMPLETE',
    AWAITING_LIQUIDITY = 'AWAITING_LIQUIDITY',
}

export enum EvmChains {
    Arbitrum = config.arbitrum.chain,
    Bsc = config.bsc.chain,
    Polygon = config.polygon.chain,
    Optimism = config.optimism.chain,
    ZkSync = config.zksync.chain,
    ZkPolygon = config.zkpolygon.chain,
    BobaEth = config.bobaeth.chain,
    BobaBnb = config.bobabnb.chain,
}

export enum SuiDestCoin {
    USDT = 0,
    USDC = 1,
}

export enum AptosChains {
    Aptos = config.aptos.chain,
}

export enum SuiChains {
    Sui = config.sui.chain as unknown as number,
}

export enum WalletTypes {
    All = 'all',
    Aptos = 'aptos',
    Sui = 'sui',
    Evm = 'evm',
}

export enum FetchStatus {
    FETCHING = 'FETCHING',
    FETCHED = 'FETCHED',
    ERROR = 'ERROR',
    INITIAL = 'INITIAL',
}
