import { fetchMoverBalanceRequest } from 'api/user';
import { useCallback, useEffect, useState } from 'react';
import { useThrottle } from './useThrottle';

export const useMoverBalance = (address: string) => {
    const [balance, setBalance] = useState<number | null>(null);

    const fetchMoverBalance = useCallback(async (address: string) => {
        try {
            await fetchMoverBalanceRequest(address).then((res) =>
                setBalance(Number(res)),
            );
        } catch (err) {
            console.log('MOVER BALANCE ERROR', err);
        }
    }, []);

    const throttledFetchMoverBalance = useThrottle(fetchMoverBalance, 1000);

    useEffect(() => {
        if (address) {
            fetchMoverBalance(address);
        }
    }, [address, fetchMoverBalance]);

    return { balance, refetch: throttledFetchMoverBalance };
};
