import { cn } from '@bem-react/classname';
import { FC } from 'react';
import {
    MAX_STAKING_TIME,
    MONTH_TIME,
    YEAR_TIME,
} from '../../StakingForm.constants';

import './Duration.scss';

const CnDuration = cn('duration');

const durationItems = [
    {
        label: '1 month',
        value: MONTH_TIME,
    },
    {
        label: '3 months',
        value: MONTH_TIME * 3,
    },
    {
        label: '6 months',
        value: MONTH_TIME * 6,
    },
    {
        label: '1 year',
        value: YEAR_TIME,
    },
    {
        label: '2 years',
        value: YEAR_TIME * 2,
    },
    {
        label: '4 years',
        value: YEAR_TIME * 4,
    },
];

export const Duration: FC<{
    value: number | null;
    onChange?: (value: number) => void;
}> = ({ value, onChange }) => {
    return (
        <div className={CnDuration()}>
            {durationItems.map((duration) => {
                return (
                    <DurationItem
                        selected={value === duration.value}
                        onClick={() => onChange && onChange(duration.value)}
                        key={duration.value}
                        label={duration.label}
                    />
                );
            })}
        </div>
    );
};

const CnDurationItem = cn('durationItem');

interface IDurationItem {
    label: string;
    selected?: boolean;
    onClick: () => void;
}

const DurationItem: FC<IDurationItem> = ({ label, selected, onClick }) => {
    return (
        <div onClick={onClick} className={CnDurationItem({ selected })}>
            {label}
        </div>
    );
};
