import {
    createContext,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from 'react';
import { ISuiWalletContext } from './SuiWalletProvider.types';
import { useAppDispatch, useAppSelector, useThrottle } from 'hooks';
import { useNavigate } from 'react-router';
import { useWalletKit } from '@mysten/wallet-kit';
import {
    clearTransferForm,
    fetchTransferLiquidity,
    setBalance,
    transferFormSelectors,
    transferStatusAddItem,
    transferStatusRemoveItem,
    transferStatusUpdateItem,
    userSelectors,
} from 'store';
import { TransactionBlock } from '@mysten/sui.js';
import { Networks, SuiDestCoin, Tokens, TransactionStatus } from 'types';
import { fetchLiquidity, fetchSuiFees, fetchTransactionQueue } from 'api';
import { config } from 'config';
import { suiProvider } from 'services';
import { formatAddress, fromDecimals, toDecimals } from 'utils';
import { mixpanelEvents } from 'analytic';
import { liquidityKeyFromNetwork, supportedSuiNetworks } from 'consts';
import { fetchOwnedCoins } from 'api';

var suiPoolInterval: null | NodeJS.Timer = null;

export const SuiWalletContext = createContext<ISuiWalletContext>({
    address: null,
    formattedAddress: null,
    connectors: [],
    connect: null,
    disconnect: null,
    adapter: null,
    uniqueKey: '',
    relayerFee: null,
    transfer: null,
    fetchBalance: null,
    poolBalance: null,
    chain: null,
});

interface ISuiWalletProviderContextProps {
    children: React.ReactNode;
}

export const SuiWalletProviderContext: React.FC<
    ISuiWalletProviderContextProps
> = ({ children }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    let {
        disconnect: suiDisconnect,
        signAndExecuteTransactionBlock,
        connect: select,
        wallets,
        currentWallet: adapter,
        currentAccount: account,
    } = useWalletKit();

    const chain = useMemo(
        () => (account ? account.chains[0] : null),
        [account],
    );

    const selectedToken = useAppSelector(userSelectors.selectedToken);
    const to = useAppSelector(transferFormSelectors.to);

    const address = useMemo(() => {
        if (!account) return null;

        return account.address;
    }, [account]);

    const formattedAddress = useMemo(
        () => (address ? formatAddress(address) : null),
        [address],
    );

    const [relayerFee, setRelayerFee] = useState(0);

    const fetchSuiFeesCallback = useCallback((selectedToken: Tokens) => {
        fetchSuiFees(selectedToken).then((response) => {
            if (response) {
                setRelayerFee(response);
            }
        });
    }, []);

    const fetchSuiFeesDebounced = useThrottle(fetchSuiFeesCallback, 5000);

    useEffect(() => {
        if (selectedToken) {
            if (supportedSuiNetworks.includes(to)) {
                fetchSuiFeesDebounced(selectedToken);
            }
        }
    }, [selectedToken, to]);

    const currConfig = useMemo(() => {
        return config.sui[selectedToken];
    }, [selectedToken]);

    const uniqueKey = useMemo(() => {
        return `${currConfig.chain}::${address}::${currConfig.token}`;
    }, [currConfig, address]);

    const connectors = useMemo(
        () => [...wallets.filter((wallet) => wallet.name !== 'GlassWallet')],
        [wallets],
    );

    const from = useAppSelector(transferFormSelectors.from);

    useEffect(() => {
        if (
            chain &&
            supportedSuiNetworks.includes(from) &&
            !chain.includes(currConfig.suiChain as `${string}:${string}`)
        ) {
            navigate('?modal=changeSuiNetwork');
        }
    }, [chain, from]);

    const connect = useCallback(
        async (walletName: string) => {
            try {
                await select(walletName);
            } catch {}
        },
        [select],
    );

    const disconnect = useCallback(async () => {
        try {
            await suiDisconnect();
        } catch (err) {}
    }, [suiDisconnect]);

    const fetchBalance = useCallback(() => {
        if (address) {
            try {
                suiProvider
                    .getBalance({
                        owner: address,
                        coinType: currConfig.token,
                    })
                    .then((response) => {
                        const balance = response.totalBalance;

                        const value = fromDecimals(
                            Number(balance),
                            currConfig.decimals,
                        ).toString();

                        dispatch(
                            setBalance({
                                token: uniqueKey,
                                value,
                            }),
                        );
                    });
            } catch {
                dispatch(
                    setBalance({
                        token: uniqueKey,
                        value: '0.00',
                    }),
                );
            }
        }
    }, [dispatch, uniqueKey, address, currConfig.decimals, currConfig.token]);

    const debouncedFetchBlanace = useThrottle(fetchBalance, 2000);

    const poolBalance = useCallback(() => {
        if (suiPoolInterval) {
            clearInterval(suiPoolInterval);
        }

        let iSui = 0;

        suiPoolInterval = setInterval(() => {
            fetchBalance();

            if (iSui === 15) {
                clearInterval(suiPoolInterval as NodeJS.Timer);
            }

            iSui = iSui + 1;
        }, 3000);
    }, [fetchBalance]);

    useEffect(() => {
        fetchBalance();
    }, [uniqueKey]);

    const transfer = useCallback(
        async (
            from: Networks,
            to: Networks,
            amount: string,
            receiveAddress: string,
            chainId: string | number,
            destCoin: SuiDestCoin,
            fromChainId: string | number,
        ) => {
            let hash;
            let currTokenLiquidity;

            mixpanelEvents.tokenSend({
                from,
                to,
                recipient: receiveAddress,
                amount,
                tokenEnum: currConfig.symbol,
            });

            try {
                const liquidityNetworkType = liquidityKeyFromNetwork[to];

                dispatch(
                    fetchTransferLiquidity({
                        chainId: chainId as number,
                        networkType: liquidityNetworkType,
                    }),
                );

                const liquidity = await fetchLiquidity(
                    chainId as number,
                    liquidityNetworkType,
                );

                const token = to === Networks.Sui ? 'moverUSD' : selectedToken;

                currTokenLiquidity = liquidity.tokens.find(
                    (item: any) =>
                        item.name.toLowerCase() === token.toLowerCase(),
                ).balance;

                if (Number(amount) > currTokenLiquidity) {
                    throw Error('Do not enough liquidity');
                }

                const tx = new TransactionBlock();

                const amountBN = toDecimals(
                    Number(amount),
                    currConfig.decimals,
                );

                const txAmount = tx.pure(amountBN);

                const coins = await fetchOwnedCoins(
                    address as string,
                    currConfig.token,
                );

                const [primaryCoin, ...mergeCoins] = coins.filter(
                    (coin) => coin.type === currConfig.token,
                );

                const primaryCoinInput = tx.object(primaryCoin.objectId);

                if (mergeCoins.length) {
                    // TODO: This could just merge a subset of coins that meet the balance requirements instead of all of them.
                    tx.mergeCoins(
                        primaryCoinInput,
                        mergeCoins.map((coin) => tx.object(coin.objectId)),
                    );
                }

                const coin = tx.splitCoins(primaryCoinInput, [txAmount]);

                tx.moveCall({
                    typeArguments: [
                        currConfig.token,
                        (config.sui.chain_types as any)[to],
                    ],
                    target: `${currConfig.bridge}::${currConfig.class}::${currConfig.function}`,
                    arguments: [
                        coin,
                        txAmount,
                        tx.pure(receiveAddress),
                        tx.pure(chainId),
                        tx.pure(destCoin),
                        tx.pure(currConfig.liquidityPool),
                        tx.pure(currConfig.metadata),
                        tx.pure((config.sui.settings as any)[to]),
                    ],
                });

                tx.transferObjects([coin], tx.pure(address));

                tx.setGasBudget(20000000);

                const { digest } = await signAndExecuteTransactionBlock({
                    transactionBlock: tx as any,
                    options: {
                        showBalanceChanges: false,
                        showEffects: false,
                        showEvents: false,
                        showInput: false,
                        showObjectChanges: false,
                    },
                    chain: currConfig.suiChain as `${string}:${string}`,
                });

                hash = digest;

                const { timeWait } = await fetchTransactionQueue(
                    from,
                    fromChainId,
                    chainId,
                );

                dispatch(
                    transferStatusAddItem({
                        from,
                        fromHash: hash,
                        fromTransactionStatus: TransactionStatus.NEW,
                        fromChainId,
                        to,
                        toChaindId: chainId,
                        amount,
                        token: currConfig?.symbol,
                        deadline: new Date().getTime() + (timeWait + 15) * 1000,
                        timeWait: timeWait + 15,
                        createdAt: new Date().getTime(),
                    }),
                );

                navigate('?modal=transferStatus', {
                    state: { hash: hash },
                });

                await suiProvider.waitForTransactionBlock({
                    digest,
                });

                mixpanelEvents.tokenSendSuccess({
                    from,
                    to,
                    recipient: receiveAddress,
                    amount,
                    tokenEnum: currConfig.symbol,
                    expectedTime: `${timeWait + 15}`,
                });

                dispatch(clearTransferForm());

                dispatch(
                    transferStatusUpdateItem({
                        hash: hash,
                        payload: {
                            fromTransactionStatus: TransactionStatus.COMPLETE,
                        },
                    }),
                );
            } catch (err: any) {
                if (hash) {
                    dispatch(transferStatusRemoveItem(hash));
                }

                if (err.message === 'Do not enough liquidity') {
                    mixpanelEvents.tokenSendError(
                        'token.send.error.no_liquidity',
                        {
                            from,
                            to,
                            recipient: receiveAddress,
                            amount,
                            tokenEnum: currConfig.symbol,
                            error: 'Do not enough liquidity',
                            availableLiquidity: currTokenLiquidity,
                        },
                    );

                    throw Error('Do not enough liquidity');
                } else {
                    mixpanelEvents.tokenSendError('token.send.error.other', {
                        from,
                        to,
                        recipient: receiveAddress,
                        amount,
                        tokenEnum: currConfig.symbol,
                        error: JSON.stringify(err),
                    });
                }
            }
        },
        [
            dispatch,
            navigate,
            currConfig,
            address,
            signAndExecuteTransactionBlock,
            selectedToken,
        ],
    );

    return (
        <SuiWalletContext.Provider
            value={{
                chain,
                address,
                formattedAddress,
                connect,
                connectors,
                adapter,
                uniqueKey,
                relayerFee,
                disconnect,
                transfer,
                fetchBalance: debouncedFetchBlanace,
                poolBalance,
            }}
        >
            {children}
        </SuiWalletContext.Provider>
    );
};
