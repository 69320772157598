import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchLiquidity } from 'api';
import { NetworkType } from 'types';

export const fetchTransferLiquidity = createAsyncThunk(
    '@transferLiquidity/fetch',
    async ({
        chainId,
        networkType,
    }: {
        chainId: number;
        networkType: NetworkType;
    }) => {
        return await fetchLiquidity(chainId, networkType);
    },
);
