import { createRoot } from 'react-dom/client';
import { Providers } from './Providers';
import { App } from 'app';

import Gleap from 'gleap';

import './index.scss';

Gleap.initialize(process.env.REACT_APP_GLEAP as string);

const root = createRoot(document.getElementById('root') as HTMLElement);

root.render(
    <Providers>
        <App />
    </Providers>,
);
