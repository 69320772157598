import axios, { AxiosResponse } from 'axios';
import { watchdogUrl } from 'consts';
import { TransactionHistory } from 'types';

export const fetchTransactionsHistoryRequest = async (
    networkType: string,
    address: string,
) => {
    return await axios
        .get<
            never,
            AxiosResponse<{
                deposits: TransactionHistory[];
            }>
        >(`${watchdogUrl}/public/deposit/history`, {
            params: {
                networkType,
                addressHex: address,
                limit: 100,
                offset: 0,
            },
        })
        .then((res) => res.data.deposits);
};
