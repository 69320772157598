export { watchdogUrl, coingeckoUrl, aptosRpcUrl } from './api';

export const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';

export const environment =
    (process.env.REACT_APP_ENV as 'dev' | 'stage' | 'prod') ?? 'production';

export const currentAptosNetwork =
    environment === 'dev' ? 'testnet' : 'mainnet';

export { stableCoinAbi } from './stableCoinAbi';
export { bobaBnbMainnet, bobaBnbTestnet, bobaEthTestnet } from './customChains';

export {
    supportedAptosNetworks,
    supportedEvmChains,
    supportedEvmNetworks,
    supportedSuiNetworks,
} from './networks';
export {
    nativeFromNetwork,
    chainTypeFromNetwork,
    currencyByToken,
    liquidityKeyFromNetwork,
    iconByToken,
    iconFromNetwork,
    chainFromNetwork,
    networkFromChain,
    labelFromNetwork,
    iconByAtposWalletName,
    iconByEvmWalletName,
    linkFromChainId,
    linkFromNetwork,
} from './mappers';
