import mixpanel from 'mixpanel-browser';
import { Networks } from 'types';
import { LocalStorage } from 'services';

export const getDistinctId = (): string => {
    let distinctId = LocalStorage.get('distinctId');

    if (!distinctId) {
        const newDistinctId = mixpanel.get_distinct_id();

        LocalStorage.set('distinctId', newDistinctId);

        distinctId = newDistinctId as string;
    }

    return distinctId;
};

export const initMixpanel = () => {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN as string);

    const distinctId = getDistinctId();

    mixpanel.alias(distinctId);
};

const setPeople = (data: any) => {
    mixpanel.people.set(data);
};

const networkSwitch = (from: Networks, to: Networks) => {
    mixpanel.track('network.switched', {
        from,
        to,
    });
};

const connectWallet = (walletType: string) => {
    mixpanel.track('wallet.connect.request', {
        walletType,
    });
};

const connectWalletSuccess = (walletType: string, network: Networks) => {
    mixpanel.track('wallet.connect.success', {
        walletType,
        network,
    });
};

const tokenSelected = (token: string) => {
    mixpanel.track('token.selected', {
        tokenEnum: token,
    });
};

const approveToken = ({
    tokenEnum,
    network,
}: {
    tokenEnum: string;
    network: Networks;
}) => {
    mixpanel.track('token.approve.request', {
        tokenEnum,
        network,
    });
};

const approveTokenSuccess = ({
    tokenEnum,
    network,
    walletType,
    amount,
}: {
    tokenEnum: string;
    network: Networks;
    walletType: string;
    amount: string;
}) => {
    mixpanel.track('token.approve.success', {
        tokenEnum,
        network,
        walletType,
        amount,
    });
};

const approveTokenError = ({
    tokenEnum,
    network,
    walletType,
    error,
}: {
    tokenEnum: string;
    network: string;
    walletType: string;
    error: string;
}) => {
    mixpanel.track('token.approve.error', {
        tokenEnum,
        network,
        walletType,
        error,
    });
};

const tokenSend = (data: {
    from: Networks;
    to: Networks;
    recipient: string;
    tokenEnum: string;
    amount: string;
}) => {
    mixpanel.track('token.send.request', data);
};

const tokenSendSuccess = (data: {
    from: Networks;
    to: Networks;
    recipient: string;
    tokenEnum: string;
    amount: string;
    expectedTime: string;
}) => {
    mixpanel.track('token.send.request.success', data);
};

const tokenSendError = (
    type: string,
    data: {
        from: Networks;
        to: Networks;
        recipient: string;
        tokenEnum: string;
        amount: string;
        availableLiquidity?: string;
        error?: string;
    },
) => {
    mixpanel.track(type, data);
};

const tokenBridgedSuccess = (data: {
    from: Networks;
    to: Networks;
    tokenEnum: string;
    amount: string;
    expectedTime: string;
    actualTime: string;
}) => {
    mixpanel.track('token.bridged.success', data);
};

const tokenBridgedError = (data: {
    from: Networks;
    to: Networks;
    tokenEnum: string;
    amount: string;
    expectedTime: string;
    actualTime: string;
    error: string;
}) => {
    mixpanel.track('token.bridged.error', data);
};

export const mixpanelEvents = {
    connectWallet,
    connectWalletSuccess,
    setPeople,
    networkSwitch,
    tokenSelected,
    approveToken,
    approveTokenSuccess,
    approveTokenError,
    tokenSend,
    tokenSendError,
    tokenSendSuccess,
    tokenBridgedSuccess,
    tokenBridgedError,
};
