import { stakeConfig } from 'config';
import { aptosClient } from 'contexts/AptosWalletProvider/AptosWalletProvider.constants';
import { useCallback, useEffect, useState } from 'react';
import { useThrottle } from './useThrottle';

export const useVeMoverBalance = (address: string | null) => {
    const [balance, setBalance] = useState<number | null>(null);

    const fetchVeMoverBalance = useCallback((address: string) => {
        aptosClient
            .view({
                function: stakeConfig.balanceFunction,
                arguments: [address],
                type_arguments: [],
            })
            .then((res) => setBalance(Number(res)));
    }, []);

    const throttledFetch = useThrottle(fetchVeMoverBalance, 1000);

    useEffect(() => {
        if (address) {
            throttledFetch(address);
        }
    }, [address]);

    return { balance, refetch: throttledFetch };
};
