import { cn } from '@bem-react/classname';

import './Input.scss';

const CnInput = cn('input');

interface IInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {}

export const Input: React.FC<IInputProps> = ({ ...props }) => {
    return <input className={CnInput()} {...props} />;
};
