import { memo } from 'react';
import { useMaintenance, useMixpanel } from './App.hooks';
import { Home, Staking } from 'pages';
import { Route, Routes } from 'react-router';

import './App.scss';
import { Pool } from 'pages/Pool';

export const App: React.FC = memo(() => {
    useMaintenance();
    useMixpanel();

    return (
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/stake" element={<Staking />} />
            <Route path="/pool" element={<Pool />} />
        </Routes>
    );
});
