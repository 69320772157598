import { prodStakeConfig as prod } from './prod';
import { devStakeConfig as dev } from './dev';
import { stageStakeConfig as stage } from './stage';

const environment =
    (process.env.REACT_APP_ENV as 'dev' | 'stage' | 'prod') ?? 'production';

const configMapper = {
    dev,
    stage,
    prod,
};

export const stakeConfig = configMapper[environment];
