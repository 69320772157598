import { cn } from '@bem-react/classname';
import { Button, Progress } from 'components';
import { iconFromNetwork, labelFromNetwork, linkFromNetwork } from 'consts';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { useAppSelector } from 'hooks';
import { transferStatusRemoveItem } from 'store';
import { Networks, TransactionStatus } from 'types';
import { timerFromTimestamp } from 'utils';

import './TransferStatusModal.scss';

var transferModalTimerIntervals: Record<string, any> = {};

const CnTransferStatusModal = cn('transferStatusModal');

export const TransferStatusModal: React.FC = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const hash = useMemo(() => location.state?.hash, [location.state]);

    const transfers = useAppSelector((store) => store.transferStatus.transfers);

    const currTransfer = useMemo(() => transfers[hash], [hash, transfers]);

    const isFailed = useMemo(
        () => currTransfer?.toTransactionStatus === TransactionStatus.FAIL,
        [currTransfer?.toTransactionStatus],
    );

    const isComplete = useMemo(
        () => currTransfer?.toTransactionStatus === TransactionStatus.COMPLETE,
        [currTransfer?.toTransactionStatus],
    );

    useEffect(() => {
        if (!hash || !currTransfer) {
            navigate(location.pathname);
        }
    }, [hash, currTransfer, navigate]);

    const transferDescriptionContent = useMemo(
        () => (
            <>
                <div>
                    Transferring{' '}
                    <b>
                        {currTransfer?.amount} {currTransfer?.token}
                    </b>
                </div>
                <div>
                    from{' '}
                    <b>{labelFromNetwork[currTransfer?.from as Networks]}</b> to{' '}
                    <b>{labelFromNetwork[currTransfer?.to as Networks]}</b>
                </div>
            </>
        ),
        [currTransfer],
    );

    const fromIconClickCallback = useCallback(() => {
        if (currTransfer?.fromHash) {
            const link =
                currTransfer?.from && currTransfer?.fromHash
                    ? linkFromNetwork[currTransfer?.from](
                          currTransfer?.fromHash,
                      )
                    : null;
            if (link) {
                window.open(link, '_blank');
            }
        }
    }, [currTransfer]);

    const transferFromIconContent = useMemo(() => {
        return (
            <div
                onClick={fromIconClickCallback}
                className={CnTransferStatusModal('item', {
                    disabled: !currTransfer?.fromHash,
                })}
            >
                {iconFromNetwork[currTransfer?.from as Networks]}
            </div>
        );
    }, [currTransfer, fromIconClickCallback]);

    const toIconClickCallback = useCallback(() => {
        if (currTransfer?.toHash) {
            const link =
                currTransfer?.to && currTransfer?.toHash
                    ? linkFromNetwork[currTransfer?.to](currTransfer?.toHash)
                    : null;

            if (link) {
                window.open(link, '_blank');
            }
        }
    }, [currTransfer]);

    const transferToIconContent = useMemo(() => {
        return (
            <div
                onClick={toIconClickCallback}
                className={CnTransferStatusModal('item', {
                    disabled: !currTransfer?.toHash,
                })}
            >
                {iconFromNetwork[currTransfer?.to as Networks]}
            </div>
        );
    }, [currTransfer, toIconClickCallback]);

    const [secondsLeft, setSecondsLeft] = useState(
        (new Date().getTime() - Number(currTransfer?.createdAt) ??
            new Date().getTime()) / 1000,
    );

    const progressContent = useMemo(() => {
        if (isComplete) {
            return <Progress percent={`100%`} />;
        }

        if (isFailed) {
            return <Progress percent={`100%`} />;
        }

        const percent = currTransfer?.timeWait
            ? (secondsLeft / currTransfer?.timeWait) * 100
            : 0;

        return <Progress percent={`${percent > 100 ? 100 : percent}%`} />;
    }, [currTransfer?.timeWait, secondsLeft, isComplete, isFailed]);

    const [timer, setTimer] = useState<string | null>(
        currTransfer?.deadline
            ? `${timerFromTimestamp(currTransfer?.deadline).m}m ${
                  timerFromTimestamp(currTransfer?.deadline).s
              }s`
            : null,
    );

    const isTimerExpired = useMemo(() => timer?.includes('0m 0s'), [timer]);

    useEffect(() => {
        const {
            deadline = null,
            fromHash = null,
            createdAt = null,
        } = currTransfer;

        if (isComplete && fromHash) {
            if (transferModalTimerIntervals[fromHash]) {
                clearInterval(transferModalTimerIntervals[fromHash]);
                transferModalTimerIntervals[fromHash] = null;
            }
        }

        if (!isFailed && !isComplete && deadline && fromHash && createdAt) {
            if (transferModalTimerIntervals[fromHash]) {
                clearInterval(transferModalTimerIntervals[fromHash]);
                transferModalTimerIntervals[fromHash] = null;
            }

            transferModalTimerIntervals[fromHash] = setInterval(() => {
                const { s, m } = timerFromTimestamp(deadline);

                setSecondsLeft((new Date().getTime() - createdAt) / 1000);
                setTimer(`${m}m ${s}s`);
            }, 1000);

            return () => {
                if (transferModalTimerIntervals[fromHash]) {
                    clearInterval(transferModalTimerIntervals[fromHash]);
                    transferModalTimerIntervals[fromHash] = null;
                }
            };
        }
    }, [currTransfer, isFailed, isComplete]);

    const removeTransferStatusItem = useCallback(() => {
        navigate(location.pathname);
        dispatch(transferStatusRemoveItem(hash));
    }, [dispatch, navigate, hash]);

    const transferStatusModalTitleContent = useMemo(() => {
        if (isComplete) {
            return 'Transaction successful';
        }

        return 'Transaction in progress';
    }, [isComplete]);

    const transferStatusModalTimerContent = useMemo(() => {
        if (isComplete) {
            return <Button onClick={removeTransferStatusItem}>Close</Button>;
        }

        if (isFailed) {
            return 'Your transaction takes longer than expected. Please contact our customer support';
        }

        if (isTimerExpired) {
            return 'Completing, please wait...';
        }

        return (
            <>
                Until the end: <b>{timer}</b>
            </>
        );
    }, [isComplete, removeTransferStatusItem, isFailed, timer, isTimerExpired]);

    return (
        <div className={CnTransferStatusModal()}>
            <div className={CnTransferStatusModal('title')}>
                {transferStatusModalTitleContent}
            </div>

            <div className={CnTransferStatusModal('description')}>
                {transferDescriptionContent}
            </div>

            <div className={CnTransferStatusModal('content')}>
                {transferFromIconContent}
                {progressContent}
                {transferToIconContent}
            </div>

            <div className={CnTransferStatusModal('timer')}>
                {transferStatusModalTimerContent}
            </div>
        </div>
    );
};
