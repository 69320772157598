import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    clearTransferForm,
    setTransferAmount,
    setTransferFees,
    setTransferFetchStatus,
    setTransferFrom,
    setTransferTo,
} from './transferForm.actions';
import { Networks } from 'types';
import { FetchStatus } from 'types';

export interface TransferFormState {
    from: Networks;
    to: Networks;
    amount: string;
    fees: string;
    baseFeeToShow: string;
    baseFee: string;
    feesLabel: string;
    amountToReceive: string;
    fetchStatus: FetchStatus;
}

const initialState: TransferFormState = {
    from: Networks.Arbitrum,
    to: Networks.Aptos,
    amount: '',
    fees: '',
    baseFee: '',
    baseFeeToShow: '',
    feesLabel: '',
    amountToReceive: '',
    fetchStatus: FetchStatus.INITIAL,
};

export const transferFormSlice = createSlice<
    TransferFormState,
    SliceCaseReducers<TransferFormState>
>({
    name: 'transferForm',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setTransferFrom, (state, { payload }) => {
            if (payload === state.to) {
                state.to = state.from;
            }

            state.from = payload;
        });

        builder.addCase(setTransferTo, (state, { payload }) => {
            state.to = payload;
        });

        builder.addCase(setTransferAmount, (state, { payload }) => {
            state.amount = payload;
        });

        builder.addCase(setTransferFees, (state, { payload }) => {
            state.fees = payload.fees;
            state.amountToReceive = payload.amountToReceive.length
                ? Number(payload.amountToReceive).toString()
                : '';
            state.feesLabel = payload.feesToShow;
            state.baseFee = payload.baseFee;
            state.baseFeeToShow = payload.baseFeeToShow;
        });

        builder.addCase(setTransferFetchStatus, (state, { payload }) => {
            state.fetchStatus = payload;
        });

        builder.addCase(clearTransferForm, (state, { payload }) => {
            state.amount = '';
            state.fees = '';
            state.feesLabel = '';
            state.amountToReceive = '';
            state.fetchStatus = FetchStatus.INITIAL;
        });
    },
});

export const transferFormReducer = transferFormSlice.reducer;
