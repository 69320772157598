import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchTransactionsHistoryRequest } from 'api';
import { NetworkType } from 'types';

export const fetchTransactionsHistory = createAsyncThunk(
    '@history/fetchTransactionsHistory',
    async ({ address, key }: { address: string; key: NetworkType }) => {
        const history = await fetchTransactionsHistoryRequest(key, address);

        return {
            history,
            key,
        };
    },
);
