import { useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { compareNetwork } from 'utils';
import { useLocation, useNavigate } from 'react-router';
import { useAptosWalletContext, useAppSelector } from 'hooks';

import './ChangeNetworkModal.css';

const CnChangeNetworkModal = cn('changeNetworkModal');

export const ChangeNetworkModal: React.FC = () => {
    const { network } = useAptosWalletContext();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const from = useAppSelector((store) => store.transferForm.from);

    useEffect(() => {
        if (network && network.name) {
            const isNeededNetwork = compareNetwork(network.name);

            if (isNeededNetwork) {
                navigate(pathname);
            }
        }
    }, [network, navigate, from]);

    return (
        <div className={CnChangeNetworkModal()}>
            <div className={CnChangeNetworkModal('title')}>
                Change Network to Aptos Mainnet
            </div>

            <div className={CnChangeNetworkModal('text')}>
                Right now we only support the Mainnet network of the Aptos
                blockchain. Please change it using your wallet and refresh page.
            </div>
        </div>
    );
};
