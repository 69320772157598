import { useCallback, useRef } from 'react';

export const useDebouncedFunction = (cb: any, delay: number) => {
    const ref = useRef<any>(null);

    return useCallback(
        (...args: any) => {
            if (ref.current) {
                clearTimeout(ref.current);
            }

            ref.current = setTimeout(() => cb(...args), delay);
        },
        [cb, delay],
    );
};
