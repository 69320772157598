import { prodConfig as prod } from './prod';
import { devConfig as dev } from './dev';
import { stageConfig as stage } from './stage';

const environment =
    (process.env.REACT_APP_ENV as 'dev' | 'stage' | 'prod') ?? 'production';

const configMapper = {
    dev,
    stage,
    prod,
};

export const poolConfig = configMapper[environment];
