import { createAction } from '@reduxjs/toolkit';
import { Transfer } from 'types';

export const transferStatusAddItem = createAction<Partial<Transfer>>(
    '@transferStatus/add',
);

export const transferStatusUpdateItem = createAction<{
    hash: string;
    payload: Partial<Transfer>;
}>('@transferStatus/update');

export const transferStatusRemoveItem = createAction<string>(
    '@transferStatus/remove',
);

export const transferStatusClearCompleted = createAction(
    '@transferStatus/clearCompleted',
);
