import React, { memo } from 'react';
import { useLocation } from 'react-router';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './StakingLoaderModal.scss';

const CnStakingLoaderModal = cn('stakingLoaderModal');

export const StakingLoaderModal: React.FC = memo(() => {
    const { state } = useLocation();

    return (
        <div className={CnStakingLoaderModal()}>
            <div className={CnStakingLoaderModal('icon')}>
                <Icons.Loader />
            </div>
            <div className={CnStakingLoaderModal('title')}>
                Transaction in progress
            </div>
            {state?.amount && (
                <div className={CnStakingLoaderModal('text')}>
                    Transferring <b>{state?.amount} MOVER</b>
                    <br />
                    to staking
                </div>
            )}
        </div>
    );
});
