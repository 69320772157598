import { suiProvider } from 'services';
import { Coin as CoinAPI } from '@mysten/sui.js';
import { CoinObject } from 'types';

export const fetchOwnedCoins = async (
    address: string,
    coinType: string,
): Promise<CoinObject[]> => {
    const coins: CoinObject[] = [];
    let hasNextPage = true;
    let nextCursor = null;

    while (hasNextPage) {
        const response: any = await suiProvider.getCoins({
            owner: address,
            coinType,
            cursor: nextCursor,
        });

        for (let item of response.data) {
            coins.push({
                type: item.coinType,
                objectId: item.coinObjectId,
                symbol: CoinAPI.getCoinSymbol(item.coinType),
                balance: BigInt(item.balance),
                previousTransaction: item.previousTransaction,
            });
        }

        hasNextPage = response.hasNextPage;
        nextCursor = response.nextCursor;
    }

    return coins;
};
