import { Icons } from 'assets';
import { Networks } from 'types';

export const networkList = [
    {
        name: 'Aptos',
        icon: <Icons.Aptos />,
        network: Networks.Aptos,
    },
    {
        name: 'Arbitrum',
        icon: <Icons.Arbitrum />,
        network: Networks.Arbitrum,
    },
    {
        name: 'BNB',
        icon: <Icons.BscNetwork />,
        network: Networks.Bsc,
    },
    {
        name: 'Optimism',
        icon: <Icons.OptimismNetwork />,
        network: Networks.Optimism,
    },
    {
        name: 'Polygon',
        icon: <Icons.PolygonNetwork />,
        network: Networks.Polygon,
    },
    {
        name: 'zkSync Era',
        icon: <Icons.ZkSync />,
        network: Networks.ZkSync,
    },
    {
        name: 'Polygon zkEVM',
        icon: <Icons.PolygonZkEvm />,
        network: Networks.ZkPolygon,
    },
    // {
    //     name: 'Boba Ethereum',
    //     icon: <Icons.BobaEth />,
    //     network: Networks.BobaEth,
    // },
    // {
    //     name: 'Boba BNB',
    //     icon: <Icons.BobaBnb />,
    //     network: Networks.BobaBnb,
    // },
    {
        name: 'Sui',
        icon: <Icons.Sui />,
        network: Networks.Sui,
    },
];
