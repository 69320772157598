import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import React from 'react';

import './MobileMenu.scss';
import { Navigation } from 'components';

const CnMobileMenu = cn('mobileMenu');

export const MobileMenu: React.FC<{
    show: boolean;
    showChangeCallback: any;
}> = ({ show, showChangeCallback }) => {
    return (
        <div className={CnMobileMenu({ show })}>
            <div className={CnMobileMenu('background')}></div>
            <div onClick={showChangeCallback} className={CnMobileMenu('close')}>
                <Icons.CloseBlack />
            </div>
            <div className={CnMobileMenu('navigation')}>
                <Navigation />
            </div>
            <div className={CnMobileMenu('row')}>
                <a
                    href="https://discord.gg/45us2C3NBw"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={CnMobileMenu('item')}
                >
                    <Icons.Discord />
                </a>
                <a
                    href="https://twitter.com/moverxyz"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={CnMobileMenu('item')}
                >
                    <Icons.Twitter />
                </a>
                <a
                    href="https://github.com/mov3r"
                    target="_blank"
                    rel="noreferrer noopener"
                    className={CnMobileMenu('item')}
                >
                    <Icons.Github />
                </a>
                <a
                    href="https://moverxyz.medium.com/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={CnMobileMenu('item')}
                >
                    <Icons.Medium />
                </a>
            </div>
        </div>
    );
};
