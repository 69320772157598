import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { TransactionHistory, FetchStatus } from 'types';
import { fetchTransactionsHistory } from './history.actions';

export interface HistoryState {
    fetchStatus: FetchStatus;
    aptos: null | TransactionHistory[];
    evm: null | TransactionHistory[];
    sui: null | TransactionHistory[];
    error: unknown;
}

const initialState: HistoryState = {
    fetchStatus: FetchStatus.INITIAL,
    aptos: null,
    evm: null,
    sui: null,
    error: null,
};

export const historySlice = createSlice<
    HistoryState,
    SliceCaseReducers<HistoryState>
>({
    name: 'history',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(fetchTransactionsHistory.pending, (state) => {
            state.fetchStatus = FetchStatus.FETCHING;
            state.error = null;
        });

        builder.addCase(
            fetchTransactionsHistory.fulfilled,
            (state, { payload: { key, history } }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state[key] = history;
            },
        );

        builder.addCase(
            fetchTransactionsHistory.rejected,
            (state, { payload }) => {
                state.fetchStatus = FetchStatus.ERROR;
                state.error = payload;
            },
        );
    },
});

export const historyReducer = historySlice.reducer;
