import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import './MaintenanceModal.scss';

const CnMaintenanceModal = cn('maintenanceModal');

export const MaintenanceModal: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (process.env.REACT_APP_MAINTENANCE !== 'true') {
            navigate(pathname);
        }
    }, []);

    return (
        <div className={CnMaintenanceModal()}>
            <div className={CnMaintenanceModal('title')}>
                Mover's taking a short break!
            </div>

            <div className={CnMaintenanceModal('image')}>
                <Icons.MoverMaintenance />
            </div>

            <div className={CnMaintenanceModal('text')}>
                <b>We are busy updating Mover.</b>
                <br />
                It's getting a little tune up and some love ❤️
                <br />
                Everything is SAFU. Stay tuned and follow us on
                <a
                    href="https://twitter.com/moverxyz"
                    target="_blank"
                    rel="noreferrer noopener"
                >
                    <Icons.Twitter />
                </a>
            </div>
        </div>
    );
};
