import { cn } from '@bem-react/classname';
import { Icons } from 'assets';
import { memo, useMemo } from 'react';

import './Progress.scss';

const CnProgress = cn('progress');

interface IProgressProps {
    percent: string;
    isFailed?: boolean;
    isFailedIconShow?: boolean;
}

export const Progress: React.FC<IProgressProps> = memo(
    ({ percent, isFailed, isFailedIconShow = true }) => {
        const filledContent = useMemo(
            () => (
                <div
                    style={{ width: percent }}
                    className={CnProgress('filled')}
                ></div>
            ),
            [percent],
        );

        return (
            <div className={CnProgress()}>
                {isFailed ? (
                    <>
                        {isFailedIconShow ? (
                            <>
                                <div
                                    className={CnProgress('content', {
                                        failed: true,
                                    })}
                                >
                                    <div
                                        style={{ width: '100%' }}
                                        className={CnProgress('filled')}
                                    ></div>
                                </div>
                                <div className={CnProgress('close')}>
                                    <Icons.CloseBlack />
                                </div>
                                <div
                                    className={CnProgress('content', {
                                        failed: true,
                                    })}
                                >
                                    <div
                                        style={{ width: '100%' }}
                                        className={CnProgress('filled')}
                                    ></div>
                                </div>
                            </>
                        ) : (
                            <div
                                className={CnProgress('content', {
                                    failed: true,
                                })}
                            >
                                <div
                                    style={{ width: '100%' }}
                                    className={CnProgress('filled')}
                                ></div>
                            </div>
                        )}
                    </>
                ) : (
                    <div className={CnProgress('content')}>{filledContent}</div>
                )}
            </div>
        );
    },
);
