import { createContext, memo, useCallback, useEffect, useMemo } from 'react';
import { IAptosWalletContext } from './AptosWalletProvider.types';
import { WalletName, useWallet } from '@mov3r/aptos-wallet-adapter';
import { useLocation, useNavigate } from 'react-router';
import { useAppDispatch, useAppSelector, useThrottle } from 'hooks';
import { config, poolConfig, stakeConfig } from 'config';
import { mixpanelEvents } from 'analytic';
import {
    fetchIsAptosTokenApprovedRequest,
    fetchLiquidity,
    fetchTransactionQueue,
} from 'api';
import { Allowance, Networks, Tokens, TransactionStatus } from 'types';
import {
    compareNetwork,
    formatAddress,
    fromDecimals,
    multipliedByDecimals,
    toDecimals,
} from 'utils';
import {
    clearTransferForm,
    fetchAptosBalance,
    fetchTransferLiquidity,
    transferFormSelectors,
    transferStatusAddItem,
    transferStatusRemoveItem,
    transferStatusUpdateItem,
    userSelectors,
} from 'store';
import { chainTypeFromNetwork, liquidityKeyFromNetwork } from 'consts';
import BigNumber from 'bignumber.js';
import { aptosClient } from './AptosWalletProvider.constants';
import { useAptosRelayerFee } from 'hooks';
import { useAptosReduceFee } from 'hooks/useAptosReduceFee';
import { useMoverBalance } from 'hooks/useMoverBalance';
import { useVeMoverBalance } from 'hooks/useVeMoverBalance';
import { useStakingData } from 'hooks/useStakingData';

var aptosPoolInterval: null | NodeJS.Timer = null;

export const AptosWalletContext = createContext<IAptosWalletContext>({
    connect: null,
    address: null,
    disconnect: null,
    network: null,
    formattedAddress: '',
    adapter: null,
    connected: false,
    transfer: null,
    approveToken: null,
    poolBalance: null,
    fetchBalance: null,
    relayerFee: null,
    uniqueKey: '',
    isTokenApproved: null,
    reduceFeePercent: null,
    moverBalance: null,
    stake: null,
    veMoverBalance: null,
    fetchVeMoverBalance: null,
    stakingData: null,
    fetchStakingData: null,
    withdraw: null,
    provideLiquidity: null,
    removeLiquidity: null,
});

interface IAptosWalletProviderContextProps {
    children: React.ReactNode;
}

export const AptosWalletProviderContext: React.FC<IAptosWalletProviderContextProps> =
    memo(({ children }) => {
        const wallet = useWallet();
        const navigate = useNavigate();
        const dispatch = useAppDispatch();
        const location = useLocation();

        const userBalance = useAppSelector(userSelectors.balance);
        const selectedToken = useAppSelector(userSelectors.selectedToken);

        const to = useAppSelector(transferFormSelectors.to);

        const connectorName = useMemo(
            () => wallet.wallet?.adapter.name,
            [wallet?.wallet],
        );

        const currConfig = useMemo(
            () => config.aptos[selectedToken],
            [selectedToken],
        );

        const address = useMemo(
            () => wallet.account?.address as string,
            [wallet.account],
        );

        const uniqueKey = useMemo(
            () => `${address}::${currConfig.unique}`,
            [address, currConfig.unique],
        );

        const balance = useMemo(
            () => userBalance[uniqueKey],
            [userBalance, uniqueKey],
        );

        useEffect(() => {
            if (address) {
                mixpanelEvents.setPeople({
                    aptosAddress: address,
                });
            }
        }, [address]);

        const relayerFee = useAptosRelayerFee(selectedToken, to);

        const reduceFeePercent = useAptosReduceFee(aptosClient, to, address);

        useEffect(() => {
            if (wallet.network?.name && !compareNetwork(wallet.network?.name)) {
                navigate('?modal=changeNetwork');
            }
        }, [wallet.network?.chainId]);

        const formattedAddress = useMemo(() => {
            if (!address) return '';

            return formatAddress(address);
        }, [address]);

        const connect = useCallback(
            async (walletName: WalletName<string>) => {
                mixpanelEvents.connectWallet(walletName);

                await wallet.connect(walletName);

                mixpanelEvents.setPeople({
                    aptosType: walletName,
                });

                mixpanelEvents.connectWalletSuccess(walletName, Networks.Aptos);
            },
            [wallet],
        );

        const disconnect = useCallback(async () => {
            await wallet.disconnect();
        }, [wallet]);

        useEffect(() => {
            if (aptosPoolInterval) {
                clearInterval(aptosPoolInterval);
            }
        }, [balance]);

        const fetchBalance = useCallback(() => {
            if (address) {
                dispatch(
                    fetchAptosBalance({
                        address,
                        token: selectedToken,
                        unique: uniqueKey,
                    }),
                );
            }
        }, [dispatch, address, selectedToken, uniqueKey]);

        const debouncedFetchBlanace = useThrottle(fetchBalance, 2000);

        const poolBalance = useCallback(() => {
            if (aptosPoolInterval) {
                clearInterval(aptosPoolInterval);
            }

            let iApt = 0;

            aptosPoolInterval = setInterval(() => {
                dispatch(
                    fetchAptosBalance({
                        address,
                        token: selectedToken,
                        unique: uniqueKey,
                    }),
                );

                if (iApt === 15) {
                    clearInterval(aptosPoolInterval as NodeJS.Timer);
                }

                iApt = iApt + 1;
            }, 3000);
        }, [dispatch, address, selectedToken, uniqueKey]);

        useEffect(() => {
            if (address) {
                dispatch(
                    fetchAptosBalance({
                        address,
                        token: selectedToken,
                        unique: uniqueKey,
                    }),
                );
            }
        }, [dispatch, address, selectedToken, uniqueKey]);

        const checkIsTokenApproved = useCallback(async () => {
            const isApprovedResult = await fetchIsAptosTokenApprovedRequest(
                address,
                selectedToken,
                uniqueKey,
            );

            return isApprovedResult.value === Allowance.Allowed;
        }, [address, uniqueKey, selectedToken]);

        const approveToken = useCallback(async () => {
            try {
                mixpanelEvents.approveToken({
                    tokenEnum: currConfig.symbol,
                    network: Networks.Aptos,
                });

                const payload = {
                    function: `${currConfig.bridge}::bridge::register`,
                    type_arguments: [currConfig.token],
                    arguments: [],
                };

                const { hash } = await wallet.signAndSubmitTransaction(
                    payload as any,
                );

                await aptosClient.waitForTransaction(hash, {
                    checkSuccess: true,
                });

                mixpanelEvents.approveTokenSuccess({
                    tokenEnum: currConfig.symbol,
                    network: Networks.Aptos,
                    walletType: connectorName as string,
                    amount: 'null',
                });
            } catch (err: any) {
                mixpanelEvents.approveTokenError({
                    tokenEnum: currConfig.symbol,
                    network: Networks.Aptos,
                    walletType: connectorName as string,
                    error: JSON.stringify(err),
                });

                if (err.error_code === 'account_not_found') {
                    throw Error('Not enough APT');
                } else if (err.code === 1002) {
                    throw Error('User rejected request');
                } else {
                    throw Error('Transaction failed');
                }
            }
        }, [
            currConfig.bridge,
            currConfig.token,
            wallet,
            connectorName,
            currConfig.symbol,
        ]);

        const transfer = useCallback(
            async (
                from: Networks,
                to: Networks,
                amount: string,
                receiveAddress: string,
                chainId: string | number,
                fromChainId: string | number,
            ) => {
                let currTokenLiquidity;
                const liquidityNetworkType = liquidityKeyFromNetwork[to];

                dispatch(
                    fetchTransferLiquidity({
                        chainId: chainId as number,
                        networkType: liquidityNetworkType,
                    }),
                );

                mixpanelEvents.tokenSend({
                    from,
                    to,
                    recipient: receiveAddress,
                    amount,
                    tokenEnum: currConfig.symbol,
                });

                let transferHash = '';

                try {
                    const sendToEvmPayload = {
                        function: `${currConfig.bridge}::bridge::swap_out`,
                        type_arguments: [
                            currConfig.token,
                            `${currConfig.bridge}::chains::${chainTypeFromNetwork[to]}`,
                        ],
                        arguments: [
                            BigNumber(amount)
                                .multipliedBy(10 ** currConfig.decimals)
                                .toString(),
                            receiveAddress,
                            chainId,
                        ],
                    };

                    const liquidity = await fetchLiquidity(
                        chainId as number,
                        liquidityNetworkType,
                    );

                    const token =
                        to === Networks.Sui ? 'moverUSD' : selectedToken;

                    currTokenLiquidity = liquidity.tokens.find(
                        (item: any) =>
                            item.name.toLowerCase() === token.toLowerCase(),
                    ).balance;

                    if (Number(amount) > currTokenLiquidity) {
                        throw Error('Do not enough liquidity');
                    }

                    const { hash } = await wallet.signAndSubmitTransaction(
                        sendToEvmPayload as any,
                    );

                    transferHash = hash;

                    const { timeWait } = await fetchTransactionQueue(
                        from,
                        fromChainId,
                        chainId,
                    );

                    dispatch(
                        transferStatusAddItem({
                            from,
                            fromHash: hash,
                            fromTransactionStatus: TransactionStatus.NEW,
                            fromChainId,
                            to,
                            toChaindId: chainId,
                            amount,
                            token: currConfig?.symbol,
                            deadline:
                                new Date().getTime() + (timeWait + 15) * 1000,
                            timeWait: timeWait + 15,
                            createdAt: new Date().getTime(),
                        }),
                    );

                    navigate('?modal=transferStatus', {
                        state: { hash: hash },
                    });

                    await aptosClient.waitForTransaction(hash, {
                        checkSuccess: true,
                    });

                    mixpanelEvents.tokenSendSuccess({
                        from,
                        to,
                        recipient: receiveAddress,
                        amount,
                        tokenEnum: currConfig.symbol,
                        expectedTime: `${timeWait + 15}`,
                    });

                    dispatch(clearTransferForm());

                    dispatch(
                        transferStatusUpdateItem({
                            hash: hash,
                            payload: {
                                fromTransactionStatus:
                                    TransactionStatus.COMPLETE,
                            },
                        }),
                    );
                } catch (err: any) {
                    if (transferHash) {
                        dispatch(transferStatusRemoveItem(transferHash));
                    }

                    if (err.message === 'Do not enough liquidity') {
                        mixpanelEvents.tokenSendError(
                            'token.send.error.no_liquidity',
                            {
                                from,
                                to,
                                recipient: receiveAddress,
                                amount,
                                tokenEnum: currConfig.symbol,
                                error: 'Do not enough liquidity',
                                availableLiquidity: currTokenLiquidity,
                            },
                        );

                        throw Error('Do not enough liquidity');
                    } else {
                        mixpanelEvents.tokenSendError(
                            'token.send.error.other',
                            {
                                from,
                                to,
                                recipient: receiveAddress,
                                amount,
                                tokenEnum: currConfig.symbol,
                                error: JSON.stringify(err),
                            },
                        );
                    }

                    if (
                        typeof err === 'string' &&
                        err?.includes('account_not_found')
                    ) {
                        throw Error('Not enough APT');
                    } else if (
                        typeof err === 'string' &&
                        err.includes('Rejected')
                    ) {
                        throw Error('User rejected request');
                    } else if (err.code === 1002) {
                        throw Error('User rejected request');
                    } else if (err.code === 4001) {
                        throw Error('User rejected request');
                    } else if (
                        wallet.wallet?.adapter?.name === 'Martian' &&
                        String(err).includes('failure')
                    ) {
                        throw Error("Martian can't connect, please try again");
                    } else {
                        throw Error('Transaction failed');
                    }
                }
            },
            [currConfig, wallet, dispatch, navigate, selectedToken],
        );

        const { balance: moverBalance, refetch: fetchMoverBalance } =
            useMoverBalance(address);

        const { balance: veMoverBalance, refetch: fetchVeMoverBalance } =
            useVeMoverBalance(address);

        const { refetch: fetchStakingData, ...stakingData } =
            useStakingData(address);

        const stake = useCallback(
            async (amount: number, timestamp: number) => {
                try {
                    navigate('?modal=stakingLoader', {
                        state: {
                            amount,
                        },
                    });

                    const func = stakingData.amount
                        ? stakeConfig.increaseAmount
                        : stakeConfig.stakeFunction;
                    const args = [
                        Number(
                            multipliedByDecimals(amount, stakeConfig.decimals),
                        ),
                    ];

                    if (!stakingData.amount) {
                        args.push(timestamp);
                    }

                    const stakePayload: any = {
                        function: func,
                        type_arguments: [],
                        arguments: args,
                    };

                    const { hash } = await wallet.signAndSubmitTransaction(
                        stakePayload,
                    );

                    await aptosClient.waitForTransaction(hash, {
                        checkSuccess: true,
                    });

                    await fetchVeMoverBalance(address);
                    await fetchStakingData(address);
                    await fetchMoverBalance(address);

                    navigate('?modal=stakingSuccess');
                } catch (err) {
                    navigate(location.pathname);

                    throw err;
                }
            },
            [
                wallet,
                address,
                stakingData,
                fetchVeMoverBalance,
                fetchStakingData,
                fetchMoverBalance,
                navigate,
                location,
            ],
        );

        const withdraw = useCallback(async () => {
            try {
                navigate('?modal=stakingLoader');

                const stakePayload: any = {
                    function: stakeConfig.withdraw,
                    type_arguments: [],
                    arguments: [],
                };

                const { hash } = await wallet.signAndSubmitTransaction(
                    stakePayload,
                );

                await aptosClient.waitForTransaction(hash, {
                    checkSuccess: true,
                });

                await fetchVeMoverBalance(address);
                await fetchStakingData(address);
                await fetchMoverBalance(address);

                navigate('?modal=stakingSuccess');
            } catch (err) {
                navigate(location.pathname);
            }
        }, [
            address,
            fetchStakingData,
            fetchVeMoverBalance,
            fetchMoverBalance,
            navigate,
            wallet,
            location,
        ]);

        const provideLiquidity = useCallback(
            async (amount: string, token: Tokens) => {
                try {
                    navigate('?modal=stakingLoader');

                    const poolPayload: any = {
                        function: poolConfig.provideLiquidity,
                        type_arguments: [poolConfig.tokens[token]],
                        arguments: [
                            toDecimals(Number(amount), poolConfig.decimals),
                        ],
                    };

                    const { hash } = await wallet.signAndSubmitTransaction(
                        poolPayload,
                    );

                    await aptosClient.waitForTransaction(hash, {
                        checkSuccess: true,
                    });

                    navigate('?modal=stakingSuccess');
                } catch (err) {
                    navigate(location.pathname);
                }
            },
            [location.pathname, navigate, wallet],
        );

        const removeLiquidity = useCallback(
            async (amount: string, token: Tokens) => {
                try {
                    navigate('?modal=stakingLoader');

                    const poolPayload: any = {
                        function: poolConfig.removeLiquidity,
                        type_arguments: [poolConfig.tokens[token]],
                        arguments: [
                            toDecimals(Number(amount), poolConfig.decimals),
                        ],
                    };

                    const { hash } = await wallet.signAndSubmitTransaction(
                        poolPayload,
                    );

                    await aptosClient.waitForTransaction(hash, {
                        checkSuccess: true,
                    });

                    navigate('?modal=stakingSuccess');
                } catch (err) {
                    navigate(location.pathname);
                }
            },
            [location.pathname, navigate, wallet],
        );

        return (
            <AptosWalletContext.Provider
                value={{
                    connect,
                    address,
                    disconnect,
                    network: wallet.network,
                    formattedAddress,
                    adapter: wallet.wallet?.adapter,
                    connected: wallet.connected,
                    isTokenApproved: checkIsTokenApproved,
                    transfer,
                    approveToken,
                    poolBalance,
                    fetchBalance: debouncedFetchBlanace,
                    relayerFee,
                    uniqueKey,
                    reduceFeePercent,
                    moverBalance,
                    stake,
                    veMoverBalance,
                    fetchVeMoverBalance,
                    stakingData,
                    fetchStakingData,
                    withdraw,
                    provideLiquidity,
                    removeLiquidity,
                }}
            >
                {children}
            </AptosWalletContext.Provider>
        );
    });
