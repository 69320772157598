import React, { memo, useMemo } from 'react';
import { cn } from '@bem-react/classname';

import './TransferInput.scss';

const CnTransferInput = cn('transferInput');

interface ITransferInputProps
    extends React.DetailedHTMLProps<
        React.InputHTMLAttributes<HTMLInputElement>,
        HTMLInputElement
    > {
    leftContent?: JSX.Element;
    tooltips?: JSX.Element[];
    token?: string;
    isInvalid?: boolean;
}

export const TransferInput: React.FC<ITransferInputProps> = memo(
    ({
        leftContent = null,
        token = null,
        tooltips = [],
        isInvalid,
        ...props
    }) => {
        const tooltipsContnet = useMemo(() => {
            if (tooltips.length) {
                return (
                    <div className={CnTransferInput('tooltips')}>
                        {tooltips.map((tooltip, index) => (
                            <React.Fragment key={index}>
                                {tooltip}
                            </React.Fragment>
                        ))}
                    </div>
                );
            } else {
                return null;
            }
        }, [tooltips]);

        return (
            <div className={CnTransferInput()}>
                {leftContent && (
                    <div className={CnTransferInput('left')}>{leftContent}</div>
                )}
                <div className={CnTransferInput('content')}>
                    {tooltipsContnet}
                    <div className={CnTransferInput('right')}>
                        <input
                            className={CnTransferInput('field', {
                                error: isInvalid,
                            })}
                            {...props}
                        />
                        {token && (
                            <div className={CnTransferInput('token')}>
                                {token}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        );
    },
);
