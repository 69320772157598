export const stageStakeConfig = {
    moverToken:
        '0x14b0ef0ec69f346bea3dfa0c5a8c3942fb05c08760059948f9f24c02cd0d4fd8::mover_token::Mover',
    contract:
        '0xaca43f72e040cd702a30f041790e2664bcc7d2b29b7c13b21ed2d103fdfe7bfb',
    decimals: 8,
    get balanceFunction() {
        return `${this.contract}::mover_staking::balance_of`;
    },
    get stakeFunction() {
        return `${this.contract}::mover_staking::create_lock`;
    },
    get estimatedVeAmount() {
        return `${this.contract}::mover_staking::estimated_ve_amount`;
    },
    get lockAmountFunction() {
        return `${this.contract}::mover_staking::lock_amount`;
    },
    get lockEndFunction() {
        return `${this.contract}::mover_staking::lock_end`;
    },
    get increaseAmount() {
        return `${this.contract}::mover_staking::increase_amount`;
    },
    get withdraw() {
        return `${this.contract}::mover_staking::withdraw`;
    },
};
