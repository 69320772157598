import { memo } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import {
    AptosWalletProvider,
    EvmWalletProvider,
    SuiWalletProvider,
} from 'contexts';
import { HotToast, Modal } from 'components';
import { PersistGate } from 'redux-persist/integration/react';
import { PageLayout } from 'layouts';

interface IProvidersProps {
    children: any;
}

export const Providers: React.FC<IProvidersProps> = memo(({ children }) => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <BrowserRouter>
                    <AptosWalletProvider>
                        <EvmWalletProvider>
                            <SuiWalletProvider>
                                <Modal />
                                <HotToast />
                                <PageLayout>{children}</PageLayout>
                            </SuiWalletProvider>
                        </EvmWalletProvider>
                    </AptosWalletProvider>
                </BrowserRouter>
            </PersistGate>
        </Provider>
    );
});
