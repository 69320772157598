import React from 'react';
import { ConnectWalletModal } from '../ConnectWalletModal';
import { TransferSuccessModal } from '../TransferSuccessModal';
import { ChangeNetworkModal } from '../ChangeNetworkModal';
import { TransferStatusModal } from '../TransferStatusModal';
import { ChangeEvmNetworkModal } from '../ChangeEvmNetworkModal';
import { TransferLoaderModal } from '../TransferLoaderModal';
import { MaintenanceModal } from '../MaintenanceModal';
import { ChangeSuiNetworkModal } from '../ChangeSuiNetworkModal';
import { SpecialOfferModal } from '../SpecialOfferModal';
import { StakingLoaderModal } from 'components/StakingLoaderModal';
import { StakingSuccessModal } from 'components/StakingSuccessModal';

export const modalTypes: Record<string, JSX.Element> = {
    connectWallet: <ConnectWalletModal />,
    transferSuccess: <TransferSuccessModal />,
    changeNetwork: <ChangeNetworkModal />,
    changeEthereumNetwork: <ChangeEvmNetworkModal />,
    transferStatus: <TransferStatusModal />,
    transferLoader: <TransferLoaderModal />,
    maintenance: <MaintenanceModal />,
    changeSuiNetwork: <ChangeSuiNetworkModal />,
    specialOffer: <SpecialOfferModal />,
    stakingLoader: <StakingLoaderModal />,
    stakingSuccess: <StakingSuccessModal />,
};
