import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    fetchAptosBalance,
    setBalance,
    setSelectedToken,
} from './user.actions';
import { Tokens } from 'types';

export interface UserState {
    balance: Record<string, string>;
    selectedToken: Tokens;
}

const initialState: UserState = {
    balance: {},
    selectedToken: Tokens.USDC,
};

export const userSlice = createSlice<UserState, SliceCaseReducers<UserState>>({
    name: 'user',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(setSelectedToken, (state, { payload }) => {
            state.selectedToken = payload;
        });

        builder.addCase(
            fetchAptosBalance.fulfilled,
            (state, { payload: { token, value } }) => {
                state.balance[token] = value;
            },
        );

        builder.addCase(setBalance, (state, { payload: { token, value } }) => {
            state.balance[token] = value;
        });
    },
});

export const userReducer = userSlice.reducer;
