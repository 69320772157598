import { cn } from '@bem-react/classname';
import { SelectToken, TransferForm } from 'components';
import { FC } from 'react';

import './Home.scss';

const CnHome = cn('home');

export const Home: FC = () => {
    return (
        <div className={CnHome('transfer')}>
            <div className={CnHome('row')}>
                <SelectToken />
            </div>

            <div className={CnHome('row')}>
                <TransferForm />
            </div>
        </div>
    );
};
