import { Icons } from 'assets';
import {
    AptosChains,
    Currency,
    EvmChains,
    NetworkType,
    Networks,
    SuiChains,
    Tokens,
} from 'types';

import {
    AptosWalletName,
    FewchaWalletName,
    MartianWalletName,
    NightlyWalletName,
    PontemWalletName,
    RiseWalletName,
} from '@mov3r/aptos-wallet-adapter';

export const iconByAtposWalletName = {
    [PontemWalletName]: <Icons.Pontem />,
    [MartianWalletName]: <Icons.MartianAlt />,
    [FewchaWalletName]: <Icons.Fewcha />,
    [NightlyWalletName]: <Icons.Nightly />,
    [RiseWalletName]: <Icons.Rise />,
    [AptosWalletName]: <Icons.Petra />,
};

export const iconByEvmWalletName: Record<string, any> = {
    MetaMask: <Icons.Metamask />,
    'OKX Wallet': <Icons.Okx />,
    WalletConnect: <Icons.WalletConnect />,
    'Trust Wallet': <Icons.TrustWallet />,
};

export const currencyByToken = {
    [Tokens.USDT]: Currency.USD,
    [Tokens.USDC]: Currency.USD,
};

export const iconByToken = {
    [Tokens.USDC]: <Icons.Usdc />,
    [Tokens.USDT]: <Icons.Usdt />,
};

export const nativeFromNetwork = {
    [Networks.Aptos]: 'aptos',
    [Networks.Arbitrum]: 'ethereum',
    [Networks.Bsc]: 'binancecoin',
    [Networks.Polygon]: 'matic-network',
    [Networks.Optimism]: 'ethereum',
    [Networks.ZkSync]: 'ethereum',
    [Networks.ZkPolygon]: 'ethereum',
    [Networks.BobaEth]: 'ethereum',
    [Networks.BobaBnb]: 'boba-network',
    [Networks.Sui]: 'sui',
};

export const chainTypeFromNetwork = {
    [Networks.Aptos]: 'Aptos',
    [Networks.Arbitrum]: 'Arbitrum',
    [Networks.BobaBnb]: 'BobaBnb',
    [Networks.BobaEth]: 'BobaEth',
    [Networks.Bsc]: 'BNB',
    [Networks.Optimism]: 'Optimism',
    [Networks.Polygon]: 'Polygon',
    [Networks.Sui]: 'Sui',
    [Networks.ZkPolygon]: 'ZkPolygon',
    [Networks.ZkSync]: 'ZkSync',
};

export const liquidityKeyFromNetwork = {
    [Networks.Aptos]: NetworkType.Aptos,
    [Networks.Arbitrum]: NetworkType.Evm,
    [Networks.BobaBnb]: NetworkType.Evm,
    [Networks.BobaEth]: NetworkType.Evm,
    [Networks.Bsc]: NetworkType.Evm,
    [Networks.Optimism]: NetworkType.Evm,
    [Networks.Polygon]: NetworkType.Evm,
    [Networks.Sui]: NetworkType.Sui,
    [Networks.ZkPolygon]: NetworkType.Evm,
    [Networks.ZkSync]: NetworkType.Evm,
};

export const iconFromNetwork = {
    [Networks.Arbitrum]: <Icons.Arbitrum />,
    [Networks.Aptos]: <Icons.Aptos />,
    [Networks.Bsc]: <Icons.BscNetwork />,
    [Networks.Optimism]: <Icons.OptimismNetwork />,
    [Networks.Polygon]: <Icons.PolygonNetwork />,
    [Networks.ZkSync]: <Icons.ZkSync />,
    [Networks.ZkPolygon]: <Icons.PolygonZkEvm />,
    [Networks.BobaEth]: <Icons.BobaEth />,
    [Networks.BobaBnb]: <Icons.BobaBnb />,
    [Networks.Sui]: <Icons.Sui />,
};

export const chainFromNetwork = {
    [Networks.Arbitrum]: EvmChains.Arbitrum,
    [Networks.Bsc]: EvmChains.Bsc,
    [Networks.Polygon]: EvmChains.Polygon,
    [Networks.Optimism]: EvmChains.Optimism,
    [Networks.Aptos]: AptosChains.Aptos,
    [Networks.ZkSync]: EvmChains.ZkSync,
    [Networks.ZkPolygon]: EvmChains.ZkPolygon,
    [Networks.BobaEth]: EvmChains.BobaEth,
    [Networks.BobaBnb]: EvmChains.BobaBnb,
    [Networks.Sui]: SuiChains.Sui,
};

export const networkFromChain = {
    [EvmChains.Arbitrum]: Networks.Arbitrum,
    [EvmChains.Bsc]: Networks.Bsc,
    [EvmChains.Optimism]: Networks.Optimism,
    [EvmChains.Polygon]: Networks.Polygon,
    [EvmChains.ZkSync]: Networks.ZkSync,
    [AptosChains.Aptos]: Networks.Aptos,
    [EvmChains.ZkPolygon]: Networks.ZkPolygon,
    [EvmChains.BobaEth]: Networks.BobaEth,
    [EvmChains.BobaBnb]: Networks.BobaBnb,
    [SuiChains.Sui]: Networks.Sui,
};

export const labelFromNetwork = {
    [Networks.Aptos]: 'Aptos',
    [Networks.Arbitrum]: 'Arbitrum',
    [Networks.Bsc]: 'BNB',
    [Networks.Polygon]: 'Polygon',
    [Networks.Optimism]: 'Optimism',
    [Networks.ZkSync]: 'zkSync Era',
    [Networks.ZkPolygon]: 'Polygon zkEVM',
    [Networks.BobaEth]: 'Boba Ethereum',
    [Networks.BobaBnb]: 'Boba BNB',
    [Networks.Sui]: 'Sui',
};

export const linkFromNetwork = {
    [Networks.Aptos]: (hash: string) =>
        `https://explorer.aptoslabs.com/txn/${hash}`,
    [Networks.Arbitrum]: (hash: string) => `https://arbiscan.io/tx/${hash}`,
    [Networks.Bsc]: (hash: string) => `https://bscscan.com/tx/${hash}`,
    [Networks.Optimism]: (hash: string) =>
        `https://optimistic.etherscan.io/tx/${hash}`,
    [Networks.Polygon]: (hash: string) => `https://polygonscan.com/tx/${hash}`,
    [Networks.ZkSync]: (hash: string) =>
        `https://explorer.zksync.io/tx/${hash}`,
    [Networks.ZkPolygon]: (hash: string) =>
        `https://zkevm.polygonscan.com/tx/${hash}`,
    [Networks.BobaEth]: (hash: string) =>
        `https://testnet.bobascan.com/tx/${hash}`,
    [Networks.BobaBnb]: (hash: string) =>
        `https://blockexplorer.testnet.bnb.boba.network/tx/${hash}`,
    [Networks.Sui]: (hash: string) => `https://explorer.sui.io/txblock/${hash}`,
};

export const linkFromChainId = {
    [EvmChains.Arbitrum]: (hash: string) => `https://arbiscan.io/tx/${hash}`,
    [EvmChains.Bsc]: (hash: string) => `https://bscscan.com/tx/${hash}`,
    [EvmChains.Optimism]: (hash: string) =>
        `https://optimistic.etherscan.io/tx/${hash}`,
    [EvmChains.Polygon]: (hash: string) => `https://polygonscan.com/tx/${hash}`,
    [AptosChains.Aptos]: (hash: string) =>
        `https://explorer.aptoslabs.com/txn/${hash}`,
    [EvmChains.ZkPolygon]: (hash: string) =>
        `https://zkevm.polygonscan.com/tx/${hash}`,
    [EvmChains.BobaEth]: (hash: string) =>
        `https://testnet.bobascan.com/tx/${hash}`,
    [EvmChains.BobaBnb]: (hash: string) =>
        `https://blockexplorer.bnb.boba.network/tx/${hash}`,
    [SuiChains.Sui]: (hash: string) =>
        `https://explorer.sui.io/txblock/${hash}`,
};
