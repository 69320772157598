import { cn } from '@bem-react/classname';
import { Duration } from './components';
import { FC, useCallback, useMemo, useState } from 'react';
import { InputTokenSelect } from 'components/InputTokenSelect';
import { Icons } from 'assets';
import { Networks, WalletTypes } from 'types';
import { Button, Input, TransferInput } from 'components';
import { useAptosWalletContext } from 'hooks';
import { useNavigate } from 'react-router';
import { MAX_STAKING_TIME } from './StakingForm.constants';
import { getVeMoverCount, replaceDecimals } from './StakingForm.utils';
import moment from 'moment';
import { fromDecimals, toDecimals } from 'utils';
import { stakeConfig } from 'config';

import './StakingForm.scss';
import { useVeMoverEstimateAmount } from 'hooks/useVeMoverEstimateAmount';

const CnStakingForm = cn('stakingForm');

export const StakingForm: FC<{
    amount?: number | null;
    end?: number | null;
}> = ({ amount: alreadyStakingAmount, end: endOfStaking }) => {
    const navigate = useNavigate();
    const { address, moverBalance, stake } = useAptosWalletContext();
    const [amount, setAmount] = useState('');
    const [duration, setDuration] = useState<number | null>(MAX_STAKING_TIME);
    const lockDate = useMemo(() => {
        const now = moment();

        now.add(duration, 's');

        return now;
    }, [duration]);

    const durationChangeCallback = useCallback(
        (value: number) => {
            if (!endOfStaking) {
                setDuration(value);
            }
        },
        [endOfStaking],
    );

    const amountChangeCallback = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const value = Number(e.target.value);
            if (!isNaN(value)) {
                if (!moverBalance) {
                    setAmount(
                        replaceDecimals(
                            e.target.value.replace('-', ''),
                            stakeConfig.decimals,
                        ),
                    );
                } else {
                    if (moverBalance > value)
                        setAmount(
                            replaceDecimals(
                                e.target.value.replace('-', ''),
                                stakeConfig.decimals,
                            ),
                        );
                    else setAmount(String(moverBalance));
                }
            }
        },
        [moverBalance],
    );

    const submit = useCallback(() => {
        if (stake) {
            stake(Number(amount), lockDate.unix())
                .then(() => setAmount(''))
                .catch(() => {});
        }
    }, [amount, lockDate, stake]);

    const actionContent = useMemo(() => {
        if (!address) {
            return (
                <Button
                    onClick={() =>
                        navigate('?modal=connectWallet', {
                            state: {
                                walletType: WalletTypes.Aptos,
                            },
                        })
                    }
                >
                    Connect wallet
                </Button>
            );
        }

        if (Number(moverBalance) < Number(amount)) {
            return <Button disabled>Not enough balance</Button>;
        }

        if (Number(amount) < 100) {
            return <Button disabled>Enter amount</Button>;
        }

        if (!duration) {
            return <Button disabled>Choose duration period</Button>;
        }

        return <Button onClick={submit}>Stake</Button>;
    }, [address, amount, duration, moverBalance, navigate, submit]);

    const estimatedVeAmount = useVeMoverEstimateAmount(
        toDecimals(Number(amount), stakeConfig.decimals),
        lockDate.unix(),
    );

    const veMoverContent = useMemo(() => {
        if (!estimatedVeAmount) return '0 veMOVER';

        return `${fromDecimals(estimatedVeAmount, stakeConfig.decimals).toFixed(
            2,
        )} veMOVER`;
    }, [estimatedVeAmount]);

    return (
        <div className={CnStakingForm()}>
            <div className={CnStakingForm('title')}>
                {alreadyStakingAmount ? 'Add tokens to staking' : 'Staking'}
            </div>

            <div className={CnStakingForm('content')}>
                {!endOfStaking && (
                    <div className={CnStakingForm('item')}>
                        <div className={CnStakingForm('item-row')}>
                            <div className={CnStakingForm('item-label')}>
                                Choose duration:
                            </div>
                        </div>
                        <div className={CnStakingForm('item-content')}>
                            <Duration
                                value={duration}
                                onChange={durationChangeCallback}
                            />
                        </div>
                    </div>
                )}

                <div className={CnStakingForm('item')}>
                    <div className={CnStakingForm('item-row')}>
                        <div className={CnStakingForm('item-label')}>
                            Amount:
                        </div>

                        <div className={CnStakingForm('item-balance')}>
                            {moverBalance?.toFixed(2)} MOVER
                        </div>
                    </div>
                    <div className={CnStakingForm('item-content')}>
                        <TransferInput
                            leftContent={
                                <InputTokenSelect
                                    selectedItem={{
                                        icon: <Icons.Mover />,
                                        title: 'Mover',
                                        network: Networks.Aptos,
                                    }}
                                    disabled
                                />
                            }
                            token={'MOVER'}
                            placeholder="0.00"
                            value={amount}
                            onChange={amountChangeCallback}
                        />
                    </div>
                </div>

                <div className={CnStakingForm('info')}>
                    <div className={CnStakingForm('info-item')}>
                        <div className={CnStakingForm('info-label')}>
                            {!alreadyStakingAmount
                                ? 'You will stake:'
                                : 'You will add to stake:'}
                        </div>
                        <div className={CnStakingForm('info-value')}>
                            {Number(amount).toFixed(2)} MOVER
                        </div>
                    </div>
                    {!alreadyStakingAmount && (
                    <div className={CnStakingForm('info-item')}>
                        <div className={CnStakingForm('info-label')}>
                            {!alreadyStakingAmount
                                ? 'You will get:'
                                : 'You will receive additional:'}
                        </div>
                        <div className={CnStakingForm('info-value')}>
                            {veMoverContent}
                        </div>
                    </div>
                    )}
                    <div className={CnStakingForm('info-item')}>
                        <div className={CnStakingForm('info-label')}>
                            End of period:
                        </div>
                        <div className={CnStakingForm('info-value')}>
                            {endOfStaking
                                ? moment
                                      .unix(endOfStaking)
                                      .format('DD MMMM YYYY')
                                : lockDate.format('DD MMMM YYYY')}
                        </div>
                    </div>
                </div>

                {endOfStaking ? (
                    <div className={CnStakingForm('durationInfo')}>
                        The duration of your stake cannot be changed when you
                        add more tokens. The end of period will remain the same.
                    </div>
                ) : null}

                <div className={CnStakingForm('action')}>{actionContent}</div>
            </div>
        </div>
    );
};
