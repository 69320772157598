import { stakeConfig } from 'config';
import { aptosClient } from 'contexts/AptosWalletProvider/AptosWalletProvider.constants';
import { useThrottle } from 'hooks';
import { useCallback, useEffect, useState } from 'react';

export const useStakingData = (address: string | null) => {
    const [amount, setAmount] = useState<number | null>(null);
    const [end, setEnd] = useState<number | null>(null);

    const fetchStakingData = useCallback(async (address: string) => {
        const [amount, end] = await Promise.all([
            aptosClient
                .view({
                    function: stakeConfig.lockAmountFunction,
                    arguments: [address],
                    type_arguments: [],
                })
                .then((res) => Number(res)),
            aptosClient
                .view({
                    function: stakeConfig.lockEndFunction,
                    arguments: [address],
                    type_arguments: [],
                })
                .then((res) => Number(res)),
        ]);

        setAmount(amount);
        setEnd(end);
    }, []);

    const refetch = useThrottle(fetchStakingData, 1000);

    useEffect(() => {
        if (address) {
            fetchStakingData(address);
        }
    }, [address]);

    return { amount, end, refetch };
};
