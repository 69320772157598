export const devStakeConfig = {
    moverToken:
        '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::mover_token::Mover',
    contract:
        '0x21aedbbaf19ac6231c8d158183b89d2e0d8a676138276c52c6b85c0a57a6ff8f',
    decimals: 8,
    get balanceFunction() {
        return `${this.contract}::mover_staking::balance_of`;
    },
    get stakeFunction() {
        return `${this.contract}::mover_staking::create_lock`;
    },
    get estimatedVeAmount() {
        return `${this.contract}::mover_staking::estimated_ve_amount`;
    },
    get lockAmountFunction() {
        return `${this.contract}::mover_staking::lock_amount`;
    },
    get lockEndFunction() {
        return `${this.contract}::mover_staking::lock_end`;
    },
    get increaseAmount() {
        return `${this.contract}::mover_staking::increase_amount`;
    },
    get withdraw() {
        return `${this.contract}::mover_staking::withdraw`;
    },
};
