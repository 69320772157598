import { memo, useCallback, useMemo, useState, FC } from 'react';
import { Icons } from 'assets';
import { cn } from '@bem-react/classname';
import { config } from 'config';
import { supportedAptosNetworks, supportedEvmNetworks } from 'consts';
import {
    useAppSelector,
    useAptosWalletContext,
    useEvmWalletContext,
    useSuiWalletContext,
} from 'hooks';
import { Networks } from 'types';

import './TransferFormFee.scss';

const CnTransferFormFee = cn('transferFormFee');

const destinationFromTo = {
    [Networks.Aptos]: 'Aptos',
    [Networks.Arbitrum]: 'Arbitrum',
    [Networks.Bsc]: 'BNB',
    [Networks.Optimism]: 'Optimism',
    [Networks.Polygon]: 'Polygon',
    [Networks.ZkSync]: 'zkSync Era',
    [Networks.ZkPolygon]: 'Polygon zkEVM',
    [Networks.BobaEth]: 'Boba Ethereum',
    [Networks.BobaBnb]: 'Boba BNB',
    [Networks.Sui]: 'Sui',
};

export const TransferFormFee: FC = memo(() => {
    const [isDropdownShow, setIsDropdownShow] = useState(false);

    const to = useAppSelector((store) => store.transferForm.to);
    const feesLabel = useAppSelector((store) => store.transferForm.feesLabel);
    const selectedToken = useAppSelector((store) => store.user.selectedToken);
    const baseFeeToShow = useAppSelector(
        (store) => store.transferForm.baseFeeToShow,
    );

    const from = useAppSelector((store) => store.transferForm.from);
    const aptosWallet = useAptosWalletContext();
    const evmWallet = useEvmWalletContext();
    const suiWallet = useSuiWalletContext();

    const { relayerFee }: any = useMemo(() => {
        if (supportedAptosNetworks.includes(to)) {
            return aptosWallet;
        } else if (supportedEvmNetworks.includes(to)) {
            return evmWallet;
        } else {
            return suiWallet;
        }
    }, [aptosWallet, evmWallet, suiWallet, to]);

    const { reduceFeePercent = null }: any = useMemo(() => {
        if (supportedAptosNetworks.includes(from)) {
            return aptosWallet;
        } else if (supportedEvmNetworks.includes(from)) {
            return evmWallet;
        } else {
            return suiWallet;
        }
    }, [aptosWallet, evmWallet, suiWallet, from]);

    const currConfig = useMemo(
        () => config[from][selectedToken],
        [from, selectedToken],
    );

    const destination = useMemo(() => {
        return destinationFromTo[to];
    }, [to]);

    const currTokenSymbol = useMemo(() => currConfig.symbol, [currConfig]);

    const isDropdownShowChangeCallback = useCallback(() => {
        setIsDropdownShow((prev) => !prev);
    }, []);

    const feeContnet = useMemo(() => {
        if (!feesLabel) return <div>-</div>;
        return (
            <div className={CnTransferFormFee('fee')}>
                {reduceFeePercent !== null &&
                    reduceFeePercent !== 1 &&
                    from === Networks.Aptos && (
                        <div className={CnTransferFormFee('line')}>
                            {baseFeeToShow} {currTokenSymbol}
                        </div>
                    )}
                {feesLabel} {currTokenSymbol}
                {reduceFeePercent !== null &&
                    reduceFeePercent !== 1 &&
                    from === Networks.Aptos && (
                        <div className={CnTransferFormFee('discount')}>
                            -{(1 - reduceFeePercent) * 100} %
                        </div>
                    )}
            </div>
        );
    }, [feesLabel, baseFeeToShow, reduceFeePercent, currTokenSymbol, from]);

    const relayerFeeContent = useMemo(() => {
        return (
            <>
                {relayerFee?.toFixed(4)} {currConfig.symbol}
            </>
        );
    }, [currConfig, relayerFee]);

    const transferFormFeeDropdownContent = useMemo(
        () => (
            <div
                className={CnTransferFormFee('dropdown', {
                    show: isDropdownShow,
                })}
            >
                <div className={CnTransferFormFee('content')}>
                    <div className={CnTransferFormFee('text')}>
                        <div>{destination} gas fee:</div>
                        <div>{relayerFeeContent}</div>
                    </div>
                    <div className={CnTransferFormFee('text')}>
                        <div>Bridge fee:</div>
                        {feeContnet}
                    </div>
                </div>
                {reduceFeePercent !== null &&
                    reduceFeePercent !== 1 &&
                    from === Networks.Aptos && (
                        <div className={CnTransferFormFee('holder')}>
                            You're a veMOVER holder - bridging fee is reduced
                        </div>
                    )}
            </div>
        ),
        [isDropdownShow, relayerFeeContent, destination, feeContnet],
    );

    const transferFormFeeHeader = useMemo(
        () => (
            <div
                onClick={isDropdownShowChangeCallback}
                className={CnTransferFormFee('label')}
            >
                {isDropdownShow ? 'Less details' : 'More details'}{' '}
                <Icons.Angle
                    className={CnTransferFormFee('icon', {
                        show: isDropdownShow,
                    })}
                />
            </div>
        ),
        [isDropdownShow, isDropdownShowChangeCallback],
    );

    return (
        <div className={CnTransferFormFee()}>
            {transferFormFeeHeader}
            {transferFormFeeDropdownContent}
        </div>
    );
});
