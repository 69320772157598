import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './TransferSuccessModal.css';

const CnTransferSuccessModal = cn('transferSuccessModal');

export const TransferSuccessModal: React.FC = () => {
    return (
        <div className={CnTransferSuccessModal()}>
            <Icons.Success className={CnTransferSuccessModal('icon')} />

            <div className={CnTransferSuccessModal('title')}>
                Transaction sent
            </div>
        </div>
    );
};
