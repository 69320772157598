import { stakeConfig } from 'config';
import { aptosClient } from 'contexts/AptosWalletProvider/AptosWalletProvider.constants';
import { useCallback, useEffect, useState } from 'react';
import { useDebouncedFunction } from './useDebounce';

export const useVeMoverEstimateAmount = (
    amount: number | null,
    timestamp: number | null,
) => {
    const [estimatedVeAmount, setEstimatedVeAmount] = useState<number | null>(
        null,
    );

    const fetchEstimatedVeAmount = useCallback(
        (amount: number, timestamp: number) => {
            aptosClient
                .view({
                    function: stakeConfig.estimatedVeAmount,
                    arguments: [String(amount), String(timestamp)],
                    type_arguments: [],
                })
                .then((res) => setEstimatedVeAmount(Number(res)));
        },
        [],
    );

    const debouncedFetch = useDebouncedFunction(fetchEstimatedVeAmount, 1000);

    useEffect(() => {
        if (amount !== null && timestamp !== null) {
            debouncedFetch(amount, timestamp);
        }
    }, [amount, timestamp, debouncedFetch]);

    return estimatedVeAmount;
};
