import { Icons } from 'assets';
import { NetworkType, Tokens } from 'types';

export const pools = [
    {
        token: Tokens.USDT,
        network: NetworkType.Aptos,
        icon: <Icons.Usdt />,
        networkIcon: <Icons.Aptos />,
    },
    {
        token: Tokens.USDC,
        network: NetworkType.Aptos,
        icon: <Icons.Usdc />,
        networkIcon: <Icons.Aptos />,
    },
];
