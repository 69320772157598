export const devConfig = {
    contract:
        '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77',
    get provideLiquidity() {
        return `${this.contract}::liquidity_pool::provide_liquidity`;
    },
    get removeLiquidity() {
        return `${this.contract}::liquidity_pool::withdraw_liquidity`;
    },
    tokens: {
        usdc: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDC',
        usdt: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDT',
    },
    types: {
        usdc: '0x1::coin::CoinStore<0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDC>',
        usdt: '0x1::coin::CoinStore<0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDT>',
    },
    lpTokensMap: {
        usdc: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::liquidity_pool::LPCoin<0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDС>',
        usdt: '0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::liquidity_pool::LPCoin<0x284002b29ca637a1546af1843bd31c587fdec52ecafa458be11a198c3ce38a77::test_coins::LzUSDT>',
    },
    decimals: 6,
};
