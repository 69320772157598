import { createSelector } from '@reduxjs/toolkit';
import { State } from '../store';

const transferFormSelector = (state: State) => state.transferForm;

const from = createSelector(
    transferFormSelector,
    (transferForm) => transferForm.from,
);

const to = createSelector(
    transferFormSelector,
    (transferForm) => transferForm.to,
);

export const transferFormSelectors = {
    from,
    to,
};
