import { memo, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { SpecialOffer, Swap } from 'components';
import { TransferFormFrom } from './TransferFormFrom/TransferFormFrom';
import { TransferFormTo } from './TransferFormTo/TransferFormTo';
import { TransferFormButton } from './TranferFormButton/TransferFormButton';
import { TransferFormRecipient } from './TransferFormRecipient/TransferFormRecipient';
import { TransferFormFee } from './TransferFormFee';
import { transferStatusClearCompleted, fetchTransferLiquidity } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks';
import { chainFromNetwork, liquidityKeyFromNetwork } from 'consts';

import './TransferForm.scss';

var fetchLiquidityPoolTimer: null | NodeJS.Timer = null;

const CnTransferForm = cn('transferForm');

export const TransferForm: React.FC = memo(() => {
    const dispatch = useAppDispatch();

    const to = useAppSelector((store) => store.transferForm.to);

    useEffect(() => {
        dispatch(transferStatusClearCompleted());

        const networkType = liquidityKeyFromNetwork[to];

        dispatch(
            fetchTransferLiquidity({
                chainId: chainFromNetwork[to],
                networkType,
            }),
        );
    }, [to]);

    useEffect(() => {
        if (fetchLiquidityPoolTimer) {
            clearInterval(fetchLiquidityPoolTimer);
        }

        fetchLiquidityPoolTimer = setInterval(() => {
            const networkType = liquidityKeyFromNetwork[to];

            dispatch(
                fetchTransferLiquidity({
                    chainId: chainFromNetwork[to],
                    networkType,
                }),
            );
        }, 10000);

        return () => {
            if (fetchLiquidityPoolTimer) {
                clearInterval(fetchLiquidityPoolTimer);
            }
        };
    }, [dispatch, to]);

    return (
        <div className={CnTransferForm()}>
            <TransferFormFrom />

            <div className={CnTransferForm('swap')}>
                <Swap />
            </div>

            <TransferFormTo />

            <TransferFormRecipient />

            <TransferFormFee />

            {/* <TransferFormWarning /> */}
            <SpecialOffer />

            <TransferFormButton />
        </div>
    );
});
