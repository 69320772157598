import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import {
    transferStatusAddItem,
    transferStatusClearCompleted,
    transferStatusRemoveItem,
    transferStatusUpdateItem,
} from './transferStatus.actions';
import { TransactionStatus, Transfer } from 'types';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface TransferStatusState {
    transfers: Record<string, Transfer>;
}

const initialState: TransferStatusState = {
    transfers: {},
};

export const transferStatusSlice = createSlice<
    TransferStatusState,
    SliceCaseReducers<TransferStatusState>
>({
    name: 'transferStatus',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(transferStatusAddItem, (state, { payload }) => {
            state.transfers[payload.fromHash as string] = payload;

            Object.keys(state.transfers).map((key) => {
                const transfer = state.transfers[key];

                if (
                    transfer.toTransactionStatus === TransactionStatus.COMPLETE
                ) {
                    delete state.transfers[key];
                }
            });
        });

        builder.addCase(
            transferStatusUpdateItem,
            (state, { payload: { hash, payload } }) => {
                const transfer = state.transfers[hash];

                if (transfer) {
                    state.transfers[hash] = {
                        ...transfer,
                        ...payload,
                    };
                }
            },
        );

        builder.addCase(transferStatusRemoveItem, (state, { payload }) => {
            delete state.transfers[payload];
        });

        builder.addCase(transferStatusClearCompleted, (state) => {
            Object.keys(state.transfers).map((key) => {
                const transfer = state.transfers[key];

                if (
                    transfer.toTransactionStatus === TransactionStatus.COMPLETE
                ) {
                    delete state.transfers[key];
                }
            });
        });
    },
});

export const transferStatusReducer = persistReducer(
    {
        key: 'transferStatus',
        storage,
    },
    transferStatusSlice.reducer,
);
