import { EvmChains, Networks } from 'types';

// TO DO: think about where romve it

export const supportedEvmNetworks = [
    Networks.Arbitrum,
    Networks.Bsc,
    Networks.Polygon,
    Networks.Optimism,
    Networks.ZkSync,
    // Networks.BobaEth,
    // Networks.BobaBnb,
];

export const supportedSuiNetworks = [Networks.Sui];

export const supportedAptosNetworks = [Networks.Aptos];

export const supportedEvmChains = [
    EvmChains.Arbitrum,
    EvmChains.Bsc,
    EvmChains.Polygon,
    EvmChains.Optimism,
    EvmChains.ZkSync,
    // EvmChains.BobaEth,
    // EvmChains.BobaBnb,
];
