import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { TransferInput, InputTokenSelect } from 'components';
import { networkMap } from '../TransferForm.constants';
import { useAppSelector } from 'hooks';
import { config } from 'config';
import { sliceDecimals } from 'utils';

const CnTransferForm = cn('transferForm');

export const TransferFormTo: React.FC = () => {
    const amountToReceive = useAppSelector(
        (store) => store.transferForm.amountToReceive,
    );
    const to = useAppSelector((store) => store.transferForm.to);
    const selectedToken = useAppSelector((store) => store.user.selectedToken);

    const amountToReceiveSliced = useMemo(() => {
        return sliceDecimals(amountToReceive, 6);
    }, [amountToReceive]);

    const receiveConfig = useMemo(
        () => config[to][selectedToken],
        [to, selectedToken],
    );

    const currTokenSymbol = useMemo(
        () => receiveConfig.symbol,
        [receiveConfig],
    );

    const tranferFormToInputLeftContent = useMemo(
        () => <InputTokenSelect type="to" selectedItem={networkMap[to]} />,
        [to],
    );

    const transferFormToInput = useMemo(
        () => (
            <TransferInput
                leftContent={tranferFormToInputLeftContent}
                token={currTokenSymbol}
                placeholder="0.0"
                disabled
                value={amountToReceiveSliced}
            />
        ),
        [tranferFormToInputLeftContent, currTokenSymbol, amountToReceiveSliced],
    );

    return (
        <div className={CnTransferForm('field')}>
            <div className={CnTransferForm('to')}>
                <div className={CnTransferForm('label')}>To</div>
            </div>
            {transferFormToInput}
        </div>
    );
};
