import axios, { AxiosResponse } from 'axios';
import { chainFromNetwork, currencyByToken, nativeFromNetwork } from 'consts';
import { Networks, Tokens } from 'types';
import { fetchTokenPrice } from 'api';
import { watchdogUrl } from 'consts';

type FetchFeeResponse = AxiosResponse<{
    feeInNative: string;
}>;

const fetchFees = async (
    blockhain: string,
    params?: { isNativeToken: boolean; toChainId: number },
) => {
    const { feeInNative } = await axios
        .get<never, FetchFeeResponse>(
            `${watchdogUrl}/public/fee/${blockhain}`,
            {
                params,
            },
        )
        .then((res) => res.data);

    return feeInNative;
};

const calcTokenFee = (feeInNative: string, tokenPrice: number) =>
    Number(feeInNative) * tokenPrice;

export const fetchEvmFees = async (tokenEnum: Tokens, network: Networks) => {
    const toChainId = chainFromNetwork[network];
    const params = {
        isNativeToken: false,
        toChainId,
    };

    const feeInNative = await fetchFees('evm', params);

    const native = nativeFromNetwork[network];
    const currency = currencyByToken[tokenEnum];

    const tokenPrice = await fetchTokenPrice(currency, native);

    if (!tokenPrice) return null;

    return calcTokenFee(feeInNative, tokenPrice);
};

export const fetchAptosFees = async (tokenEnum: Tokens) => {
    const feeInNative = await fetchFees('aptos');

    const currency = currencyByToken[tokenEnum];

    const tokenPrice = await fetchTokenPrice(currency, 'aptos');

    if (!tokenPrice) return null;

    return calcTokenFee(feeInNative, tokenPrice);
};

export const fetchSuiFees = async (tokenEnum: Tokens) => {
    const feeInNative = await fetchFees('sui');

    const currency = currencyByToken[tokenEnum];

    const tokenPrice = await fetchTokenPrice(currency, 'sui');

    if (!tokenPrice) return null;

    return calcTokenFee(feeInNative, tokenPrice);
};
