import { isMaintenance } from 'consts';
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { initMixpanel } from 'analytic';

export const useMaintenance = () => {
    const navigate = useNavigate();

    useEffect(() => {
        if (isMaintenance) {
            navigate('?modal=maintenance');
        }
    }, [navigate]);
};

export const useMixpanel = () => {
    useEffect(() => {
        initMixpanel();
    }, []);
};
