import { cn } from '@bem-react/classname';
import { useLocation, useNavigate } from 'react-router';
import { supportedEvmChains } from 'consts';
import { useEvmWalletContext } from 'hooks';
import { evmNetworks } from './ChangeEvmNetworkModal.constants';
import { NetworkItem } from 'components';
import { useEffect } from 'react';

import './ChangeEvmNetworkModal.css';

const CnChangeEvmNetworkModal = cn('changeEvmNetworkModal');

export const ChangeEvmNetworkModal = () => {
    const { chainId, address: ethAddress } = useEvmWalletContext();
    const navigate = useNavigate();
    const { pathname } = useLocation();

    useEffect(() => {
        if (!ethAddress) {
            navigate(pathname);
        }
    }, [ethAddress]);

    useEffect(() => {
        if (chainId && supportedEvmChains.includes(chainId)) {
            navigate(pathname);
        }
    }, [chainId]);

    return (
        <div className={CnChangeEvmNetworkModal()}>
            <div className={CnChangeEvmNetworkModal('title')}>
                Change Network
            </div>

            <div className={CnChangeEvmNetworkModal('text')}>
                Please choose one of the supported networks:
            </div>

            <div className={CnChangeEvmNetworkModal('networks')}>
                {evmNetworks.map((network) => (
                    <NetworkItem key={network.chainId} {...network} />
                ))}
            </div>
        </div>
    );
};
