import { cn } from '@bem-react/classname';
import { PoolItem } from '../PoolItem';
import { FC, useMemo } from 'react';

import './PoolList.scss';

const CnPoolList = cn('poolList');

interface IPoolList {
    pools: any[];
    poolClickHandler: (pool: any) => void;
}

export const PoolList: FC<IPoolList> = ({ pools, poolClickHandler }) => {
    const poolsContent = useMemo(
        () =>
            pools.map((pool) => (
                <PoolItem
                    poolClickHandler={poolClickHandler}
                    key={pool.token}
                    {...pool}
                />
            )),
        [pools, poolClickHandler],
    );

    return (
        <>
            <div className={CnPoolList('title')}>
                Add liquidity to Mover’s pools
            </div>
            <div className={CnPoolList('subtitle')}>
                Add your liquidity and earn stablecoin rewards on every Mover
                transfer. Make sure to stake 100 $MOVER!
            </div>

            <div className={CnPoolList('content')}>{poolsContent}</div>
        </>
    );
};
