import { useCallback, useState } from 'react';
import { cn } from '@bem-react/classname';
import {
    Logo,
    Navigation,
    Settings,
    MobileMenu,
    TransferStatus,
} from 'components';
import { Icons } from 'assets';

import './Header.scss';
import { VeMoverBalance } from './VeMoverBalance';
import { Link } from 'react-router-dom';

const CnHeader = cn('header');

export const Header: React.FC = () => {
    const [isShow, setIsShow] = useState(false);

    const isShowChangeCallback = useCallback(() => {
        setIsShow((prev) => !prev);
    }, []);

    return (
        <>
            <div className={CnHeader()}>
                <div className={CnHeader('logo')}>
                    <Link to="/">
                        <Logo />
                    </Link>
                </div>

                <div className={CnHeader('navigation')}>
                    <Navigation />
                </div>

                <div className={CnHeader('settings')}>
                    <VeMoverBalance />
                    <TransferStatus />
                    <Settings />

                    <div
                        onClick={isShowChangeCallback}
                        className={CnHeader('button')}
                    >
                        <Icons.Menu />
                    </div>
                </div>
            </div>
            <MobileMenu
                show={isShow}
                showChangeCallback={isShowChangeCallback}
            />
        </>
    );
};
