import { useCallback, useRef } from 'react';

export function useThrottle(func: any, delay: number) {
    const ref = useRef<any>(null);

    return useCallback(
        (...args: any): any => {
            if (ref.current) return;

            func(...args);
            ref.current = setTimeout(() => (ref.current = null), delay);
        },
        [delay, func],
    );
}
