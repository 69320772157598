import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { fetchAptosBalanceRequest } from 'api';
import { Tokens } from 'types';

export const setBalance = createAction<{
    token: string;
    value: string;
}>('@user/setBalance');

export const fetchAptosBalance = createAsyncThunk(
    '@user/fetchAptosBalance',
    async ({
        address,
        token,
        unique,
    }: {
        address: string;
        unique: string;
        token: Tokens;
    }) => {
        return await fetchAptosBalanceRequest(address, token, unique);
    },
);

export const setSelectedToken = createAction<Tokens>('@user/setSelectedToken');
