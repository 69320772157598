import axios from 'axios';
import { aptosRpcUrl } from 'consts';
import { Allowance, Tokens } from 'types';
import { fromDecimals } from 'utils';
import { config, stakeConfig } from 'config';

export const getAccountResources = async (address: string) => {
    return await axios
        .get(`${aptosRpcUrl}/accounts/${address}/resources`)
        .then((response) => response.data);
};

const findTokenByAddress = (tokens: any, tokenAddress: string) => {
    return tokens.find((item: { type: string }) =>
        item.type.includes(tokenAddress),
    );
};

export const fetchMoverBalanceRequest = async (address: string) => {
    try {
        const resources = await getAccountResources(address as string);

        const currToken = findTokenByAddress(resources, stakeConfig.moverToken);

        const balance = fromDecimals(
            Number(currToken.data.coin.value),
            stakeConfig.decimals,
        ).toString();

        return balance;
    } catch {
        return '0.00';
    }
};

export const fetchAptosBalanceRequest = async (
    address: string,
    token: Tokens,
    unique: string,
) => {
    try {
        const { decimals: tokenDecimals, aptosType } = config.aptos[token];

        const resources = await getAccountResources(address as string);

        const currToken = findTokenByAddress(resources, aptosType);

        const balance = fromDecimals(
            Number(currToken.data.coin.value),
            tokenDecimals,
        ).toString();

        return {
            token: unique,
            value: balance,
        };
    } catch {
        return {
            token: unique,
            value: '0.00',
        };
    }
};

export const fetchCustomAptosBalanceRequest = async (
    address: string,
    token: string,
) => {
    try {
        const resources = await getAccountResources(address as string);

        const currToken = findTokenByAddress(resources, token);

        return fromDecimals(Number(currToken.data.coin.value), 6).toString();
    } catch {
        return '0.0';
    }
};

export const fetchIsAptosTokenApprovedRequest = async (
    address: string,
    token: Tokens,
    unique: string,
) => {
    try {
        const { aptosType } = config.aptos[token];

        const resources = await getAccountResources(address);

        const isTokenExist = findTokenByAddress(resources, aptosType);

        return {
            token: unique,
            value: isTokenExist ? Allowance.Allowed : Allowance.NotAllowed,
        };
    } catch {
        return {
            token: unique,
            value: Allowance.NotAllowed,
        };
    }
};
