import React from 'react';
import { cn } from '@bem-react/classname';
import { Icons } from 'assets';

import './Socials.scss';

const CnSocials = cn('socials');

export const Socials: React.FC = () => {
    return (
        <div className={CnSocials()}>
            <a
                href="https://discord.gg/45us2C3NBw"
                target="_blank"
                rel="noreferrer noopener"
                className={CnSocials('item')}
            >
                <Icons.Discord />
            </a>
            <a
                href="https://twitter.com/moverxyz"
                target="_blank"
                rel="noreferrer noopener"
                className={CnSocials('item')}
            >
                <Icons.Twitter />
            </a>
            <a
                href="https://github.com/mov3r"
                target="_blank"
                rel="noreferrer noopener"
                className={CnSocials('item')}
            >
                <Icons.Github />
            </a>
            <a
                href="https://moverxyz.medium.com/"
                target="_blank"
                rel="noopener noreferrer"
                className={CnSocials('item')}
            >
                <Icons.Medium />
            </a>
        </div>
    );
};
