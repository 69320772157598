import { MAX_STAKING_TIME } from './StakingForm.constants';

export const getVeMoverCount = (mover: number, duration: number) => {
    return Math.floor(mover / MAX_STAKING_TIME) * duration;
};

export const replaceDecimals = (amount: string, decimals: number): string => {
    const [base, dec] = amount.split('.');
    if (dec) {
        return `${base}.${dec.slice(0, decimals)}`;
    } else {
        return amount;
    }
};
