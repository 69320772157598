import { createSlice, SliceCaseReducers } from '@reduxjs/toolkit';
import { FetchStatus } from 'types';
import { fetchTransferLiquidity } from './transferLiquidity.actions';

export interface TransferLiquidityState {
    fetchStatus: FetchStatus;
    liquidity: any;
}

const initialState: TransferLiquidityState = {
    fetchStatus: FetchStatus.INITIAL,
    liquidity: {},
};

export const transferLiquiditySlice = createSlice<
    TransferLiquidityState,
    SliceCaseReducers<TransferLiquidityState>
>({
    name: 'transferLiquidity',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(
            fetchTransferLiquidity.fulfilled,
            (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHED;
                state.liquidity = payload;
            },
        );

        builder.addCase(
            fetchTransferLiquidity.pending,
            (state, { payload }) => {
                state.fetchStatus = FetchStatus.FETCHING;
            },
        );
    },
});

export const transferLiquidityReducer = transferLiquiditySlice.reducer;
